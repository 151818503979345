import axios from "axios";
import * as Sentry from "@sentry/vue";
import { useDialogStore } from "../stores/dialog";


export function useDataAPI() {
    const dialogStore = useDialogStore();
    const VITE_EUDR_BACKEND = import.meta.env.VITE_EUDR_BACKEND;

    // Create base Axios instance for general API calls
    const axiosInstance = axios.create({
        baseURL: VITE_EUDR_BACKEND,
    });

    const axiosInstanceWithoutSessionExpired = axios.create({
        baseURL: VITE_EUDR_BACKEND,
    });

    axiosInstanceWithoutSessionExpired.interceptors.response.use(response => {
        // Handle the response data normally
        return response;
    }, error => {
        return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use(response => {
        // Handle the response data normally
        return response;
    }, error => {
        if (error.response) {
            if (error.response.status === 401) {
                dialogStore.isSessionExpired = true;
            }
        }
        return Promise.reject(error);
    });

    // GET

    async function getImpersonateToken(adminUid, userUid) {
        const response = await axiosInstance.get(`/auth/generate/${adminUid}/${userUid}`);
        return response.data
    }

    async function getUserGuide() {
        const response = await axiosInstance.get(`/files/user-guide`, {
            responseType: 'blob', // Important for binary files like zip
            withCredentials: true,
        });

        // Create a URL for the blob
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        // Create a temporary anchor tag to download the file
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'Nadar_EUDR_User_Guide.zip'); // Set the file name for the download
        document.body.appendChild(fileLink);

        fileLink.click(); // Trigger the download

        // Cleanup
        fileLink.remove();
        window.URL.revokeObjectURL(fileURL);
    }

    async function getCurrentUser() {
        const response = await axiosInstance.get(`/users/me`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getAllUsers() {
        const response = await axiosInstance.get(`/users`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getCurrentOrganization() {
        const response = await axiosInstance.get(`/organization`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getDownloadStatement(stoid) {
        const response = await axiosInstance.get(`/duediligence/${stoid}`, {
            withCredentials: true,
            responseType: "blob"
        });
        return response.data
    }

    async function getAllPlotsOfSupplier(soid) {
        const response = await axiosInstance.get(`/plots/supplier/${soid}`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getMappingPlots() {
        const response = await axiosInstance.get(`/plots/mapping`, {
            withCredentials: true,
        });
        return response.data;
    }

    //POST

    async function postUser(body) {
        const response = await axiosInstance.post(`/users`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function postLoginUser(idToken) {
        const response = await axiosInstance.post(`/auth/login`, {}, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        });
        return response.data
    }

    async function postLoginAdmin(idToken) {
        const response = await axiosInstance.post(`/auth/login-admin`, {}, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        });
        return response.data
    }

    async function postLogoutUser() {
        const response = await axiosInstanceWithoutSessionExpired.post(`/auth/logout`, {}, {
            withCredentials: true,
        });
        return response.data
    }

    async function postVerifyUser() {
        const response = await axiosInstanceWithoutSessionExpired.post(`/auth/verify`, {}, {
            withCredentials: true,
        });
        return response
    }

    // PATCH

    async function patchCurrentUser(body) {
        const response = await axiosInstance.patch(`/users/me`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }

    async function patchUser(uoid, body) {
        const response = await axiosInstance.patch(`/users/${uoid}`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }

    async function patchCurrentOrganization(body) {
        const response = await axiosInstance.patch(`/organization`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }

    async function deleteUser(uoid) {
        const response = await axiosInstance.delete(`/users/${uoid}`, {
            withCredentials: true,
        });
        return response.data
    }

    return {
        getImpersonateToken,
        getCurrentOrganization,
        getAllUsers,
        getCurrentUser,
        getUserGuide,
        getMappingPlots,
        getDownloadStatement,
        getAllPlotsOfSupplier,
        postLoginUser,
        postUser,
        postLoginAdmin,
        postLogoutUser,
        postVerifyUser,
        patchCurrentOrganization,
        patchCurrentUser,
        patchUser,
        deleteUser,
    }
}

