<template>

    <span class="font-medium text-2xl">Profile</span>

    <Card class="mt-3">
        <template #title>
            <span class="text-xl font-medium">Personal Info</span>
        </template>
        <template #content>
            <div class="grid">
                <div class="col-6">
                    <div class="flex flex-column gap-2">
                        <label>First name</label>
                        <InputText v-model="usersStore.currentUser.firstName" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="flex flex-column gap-2">
                        <label>Last name</label>
                        <InputText v-model="usersStore.currentUser.lastName" />
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="flex justify-content-end">
                <Button label="Save changes" @click="changeProfile" :loading="isUpdateProfileLoading" />
            </div>
        </template>
    </Card>

    <Card class="mt-3">
        <template #title>
            <span class="text-xl font-medium">Email</span>
        </template>
        <template #content>
            <InputText v-model="usersStore.currentUser.email" class="w-full" />
        </template>
        <template #footer>
            <div class="flex justify-content-end">
                <Button label="Save changes" @click="changeEmail" :loading="isEmailChangeLoading" />
            </div>
        </template>
    </Card>

    <Card class="mt-3">
        <template #title>
            <span class="text-xl font-medium">Password</span>
        </template>
        <template #content>
            <div class="flex flex-column gap-2">
                <label>New password</label>
                <InputText type="password" v-model="newPassword" />
                <small class="p-error" id="dd-error">{{ errorPassword || '&nbsp;'
                    }}</small>
            </div>
            <div class="flex flex-column gap-2">
                <label>Confirm password</label>
                <InputText type="password" v-model="repeatedNewPassword" />
                <small class="p-error" id="dd-error">{{ errorPassword || '&nbsp;'
                    }}</small>
            </div>
        </template>
        <template #footer>
            <div class="flex justify-content-end">
                <Button label="Save changes" @click="changePassword" :loading="isPasswordChangeLoading" />
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useUsersStore } from '../stores/users';
import { useDataAPI } from '../composables/useDataAPI';
import { getAuth, updatePassword, updateEmail } from "firebase/auth";
import * as Sentry from "@sentry/vue";


const { patchCurrentUser, postLoginUser } = useDataAPI();
const toast = useToast();
const usersStore = useUsersStore();
const newPassword = ref("");
const repeatedNewPassword = ref("");
const selectedCountry = ref();
const errorPassword = ref();
const isUpdateProfileLoading = ref(false);
const isEmailChangeLoading = ref(false);
const isPasswordChangeLoading = ref(false);


async function getCurrentUser() {
    if (Object.keys(usersStore.currentUser).length === 0) {
        await usersStore.fetchCurrentUser();
    }

    if (usersStore.currentUserError) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Could not fetch user data.', life: 5000 });
    }
}

async function changeEmail() {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
        toast.add({
            severity: 'info',
            summary: 'Re-authentication Required',
            detail: 'For your security, please log out and log in again to update your email or password.',
            life: 5000
        });
        return;
    }

    try {
        isEmailChangeLoading.value = true;
        const patchData = {
            email: usersStore.currentUser.email,
        }

        await updateEmail(user, usersStore.currentUser.email);

        // Refresh the user's ID token
        const idToken = await user.getIdToken(true);
        const responseLogin = await postLoginUser(idToken)

        const response = await patchCurrentUser(patchData);

        toast.add({ severity: 'success', summary: 'Success', detail: 'Email successfully changed.', life: 3000 });
    } catch (error) {
        let errorMessage = 'Failed to update email';
        switch (error.code) {
            case 'auth/invalid-email':
                errorMessage = 'The email address is not valid.';
                break;
            case 'auth/email-already-in-use':
                errorMessage = 'The email address is already in use by another account.';
                break;
            case 'auth/requires-recent-login':
                errorMessage = 'Please log out and log in again to change your password.';
                break;
            case 'auth/network-request-failed':
                errorMessage = 'Network error - Please try again later.';
                break;
            // Add more specific cases as needed
            default:
                Sentry.captureException(error);
                errorMessage = 'An unexpected error occurred. Please try again.';
        }
        console.log(error);
        toast.add({ severity: 'error', summary: 'Error', detail: errorMessage, life: 3000 });
    } finally {
        isEmailChangeLoading.value = false;
    }
};

async function changePassword() {
    errorPassword.value = null;

    if (newPassword.value !== repeatedNewPassword.value) {
        errorPassword.value = "Passwords do not match";
        return;
    }

    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
        toast.add({
            severity: 'info',
            summary: 'Re-authentication Required',
            detail: 'For your security, please log out and log in again to update your email or password.',
            life: 5000
        });
        return;
    }
    try {
        isPasswordChangeLoading.value = true;
        await updatePassword(user, newPassword.value);

        // Refresh the user's ID token
        const idToken = await user.getIdToken(true);
        const responseLogin = await postLoginUser(idToken);

        toast.add({ severity: 'success', summary: 'Success', detail: "Password successfully changed.", life: 3000 });
    } catch (error) {
        // Customize error messages based on the error code
        let errorMessage = 'Failed to change password';
        switch (error.code) {
            case 'auth/weak-password':
                errorMessage = 'The provided password is too weak';
                break;
            case 'auth/requires-recent-login':
                errorMessage = 'Please log out and log in again to change your password';
                break;
            case 'auth/network-request-failed':
                errorMessage = 'Network error - Please try again later';
                break;
            default:
                Sentry.captureException(error);
                errorMessage = 'An unexpected error occurred. Please log in again.';
        }

        console.log(error)
        toast.add({ severity: 'error', summary: 'Error', detail: errorMessage, life: 3000 });
    } finally {
        isPasswordChangeLoading.value = false;
    }
}


async function changeProfile() {
    isUpdateProfileLoading.value = true;
    const dataToUpdate = {
    }

    if (usersStore.currentUser && usersStore.currentUser.firstName) {
        dataToUpdate.firstName = usersStore.currentUser.firstName;
    }
    if (usersStore.currentUser && usersStore.currentUser.lastName) {
        dataToUpdate.lastName = usersStore.currentUser.lastName;
    }
    if (selectedCountry.value && selectedCountry.value) {
        dataToUpdate.country = selectedCountry.value;
    }
    if (usersStore.currentUser && usersStore.currentUser.eori) {
        dataToUpdate.eori = usersStore.currentUser.eori;
    }

    try {
        const response = await patchCurrentUser(dataToUpdate);
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        isUpdateProfileLoading.value = false;
        toast.add({ severity: 'error', summary: 'Update', detail: "Profile could not be updated.", life: 3000 });
        return;
    }

    toast.add({ severity: 'success', summary: 'Update', detail: "Profile successfully updated.", life: 3000 });
    isUpdateProfileLoading.value = false;
}

getCurrentUser();
</script>

<style scoped>
.page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    /* Full viewport height */
    overflow: auto;
    /* Prevents scroll outside the containers */
    padding: 1rem;
}
</style>