<template>

    <div class="navbar-container">
        <div class="line"></div>
        <div class="navbar">
            <div class="nav-tabs">
                <div class="nav-item" @click="pushMap" :class="{ active: isActive('/onboarding/map') }">
                    <div class="icon">
                        <i class="pi pi-map"></i>
                    </div>
                    <div class="text">Map</div>
                </div>
                <div class="nav-item" @click="pushPlots" :class="{ active: isActive('/onboarding/plots') }">
                    <div class="icon">
                        <i class="pi pi-list"></i>
                    </div>
                    <div class="text">Plots</div>
                </div>
                <div class="nav-item" @click="pushProfile" :class="{ active: isActive('/onboarding/profile') }">
                    <div class="icon">
                        <i class="pi pi-user"></i>
                    </div>
                    <div class="text">Profile</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router";


const router = useRouter();


function pushMap() {
    router.push({ path: '/onboarding/map' });
}

function pushPlots() {
    router.push({ path: '/onboarding/plots' });
}

function pushProfile() {
    router.push({ path: '/onboarding/profile' });
}

function isActive(route) {
    return router.currentRoute.value.path === route;
}
</script>


<style scoped>
.navbar-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    /* Ensure the navbar spans the entire width of the viewport */
    background-color: #ffffff;
    /* Set the background color as needed */
    z-index: 999;
    /* Ensure the navbar is above other content */
}

.navbar {
    background-color: #ffffff;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.nav-tabs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
}

.nav-item {
    width: calc(100% / 3);
    /* Each tab takes up 1/3 of the width */
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
}

.icon {
    font-size: 20px;
    margin-bottom: 4px;
}

.text {
    font-size: 12px;
    text-align: center;
}

.nav-item:hover {
    opacity: 0.8;
}

.nav-item.active {
    color: rgb(16, 185, 129);
    /* Change this color to your primary color */
}

.line {
    border-bottom: 1px solid #ccc;
    /* Add a 1px solid border at the bottom */
}
</style>