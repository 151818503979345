import { defineStore } from 'pinia'
import { useDataAPI } from '../composables/useDataAPI';

export const useOrganizationStore = defineStore('organization', {
    state: () => ({
        organization: {},
        isLoading: false
    }),
    actions: {
        async fetchCurrentOrganization() {
            this.isLoading = true;
            const { getCurrentOrganization } = useDataAPI();
            try {
                const organization = await getCurrentOrganization();
                this.organization = organization;
            } catch (error) {
                console.error(error);
                throw error
            } finally {
                this.isLoading = false;
            }
        }
    }
});