<template>
    <Dialog v-model:visible="dueDiligenceStore.isCreateStatementDialogShown" modal header="Statement preparation"
        :style="{ width: '90rem' }">
        <Card>
            <template #content>
                <Stepper :key="stepperKey" linear v-model:activeStep="dueDiligenceStore.stepOfStepper">
                    <StepperPanel header="Operator/Trader">
                        <template #content="{ prevCallback, nextCallback }">
                            <OperatorStep :nextCallback="nextCallback" :prevCallback="prevCallback" />
                        </template>
                    </StepperPanel>
                    <StepperPanel header="Activity & Comments">
                        <template #content="{ prevCallback, nextCallback }">
                            <ActivityStep :nextCallback="nextCallback" :prevCallback="prevCallback" />
                        </template>
                    </StepperPanel>
                    <StepperPanel header="Batch">
                        <template #content="{ prevCallback }">
                            <BatchStep :prevCallback="prevCallback" />
                        </template>
                    </StepperPanel>
                </Stepper>
            </template>
        </Card>
    </Dialog>
</template>


<script setup>
import { ref, onUpdated } from 'vue';
import { useDueDiligenceStore } from '../../stores/duediligence';
import OperatorStep from './OperatorStep.vue';
import ActivityStep from './ActivityStep.vue';
import BatchStep from './BatchStep.vue';

const dueDiligenceStore = useDueDiligenceStore();
const stepperKey = ref(0);

onUpdated(() => {
    console.log("onUpdated")
    stepperKey.value += 1;
})
</script>