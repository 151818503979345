import { useDataImportStore } from '../../../stores/dataImport';
import { useDataFormatUtils } from '../../../composables/useDataFormatUtils';
import { ImportMode } from '../../../utils/constants';


export function useDataImportUtils() {
    const dataImportStore = useDataImportStore();
    const { generateRandom10DigitNumber } = useDataFormatUtils();


    async function loadPlotsFromGeoJSON(geojsonData) {
        for (const feature of geojsonData.features) {
            const featureId = generateRandom10DigitNumber().toString();

            let plotData = {
                creationType: "IMPORT",
                properties: feature.properties,
                geometry: feature.geometry,
                featureId: featureId,
            };

            dataImportStore.plotsWithUserDetails.push(plotData);
        }
        dataImportStore.currentImportMode = ImportMode.IMPORT;
    }

    return {
        loadPlotsFromGeoJSON,
    };
}
