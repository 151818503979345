<template>
    <div v-if="mappingStore.isEditPlotsCardShown">
        <div style="background-color:white" class="save-edit-plots-card border-round p-2">
            <span class="text-base font-medium">Save changes?</span>
            <div class="flex justify-content-between pt-2">
                <Button label="No" class="mr-3 save-button" severity="secondary" @click="cancelChanges" />
                <Button label="Yes" class="save-button" @click="handleSaveChanges" />
            </div>
        </div>
    </div>

    <Dialog v-model:visible="isReplacePolygonDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column px-5 py-5 gap-4">
                <span class="text-lg font-medium">Replace geometry?</span>
                <span>This will replace the previous geometry.</span>
                <div class="flex justify-content-end">
                    <Button class="mr-3" severity="secondary" label="Cancel"
                        @click="closeReplacePolygonDialog"></Button>
                    <Button label="Yes" class="light-green-nadar" @click="replaceOldPlot"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { ref, inject } from 'vue';
import { useMappingStore } from '../../stores/mapping';
import { GeometryMode } from '../../utils/constants';
import * as Sentry from "@sentry/vue";
import { useToast } from 'primevue/usetoast';
import { useDataAPI } from '../../composables/useDataAPI';
import { useMappingUtils } from './composables/useMappingUtils';
import { useDataFormatUtils } from "../../composables/useDataFormatUtils"
import * as turf from "@turf/turf";


const draw = inject('draw');
const toast = useToast();
const { patchPlot } = useDataAPI();
const { refreshData } = useMappingUtils();
const { calculateAreaInHectares } = useDataFormatUtils();
const mappingStore = useMappingStore();
const isReplacePolygonDialogShown = ref(false);


function closeReplacePolygonDialog() {
    isReplacePolygonDialogShown.value = false;
}

function handleSaveChanges() {
    if (mappingStore.lastDrawnFeature && Object.keys(mappingStore.lastDrawnFeature).length) {
        isReplacePolygonDialogShown.value = true;
    } else {
        saveChanges();
        isReplacePolygonDialogShown.value = false;
    }
}

async function replaceOldPlot() {
    const area = calculateAreaInHectares(mappingStore.lastDrawnFeature)
    const plotObjectId = mappingStore.editingPlotObjectId;

    const payload = {
        geometry: mappingStore.lastDrawnFeature.geometry,
        area: area
    }

    try {
        const response = await patchPlot(plotObjectId, payload);
        toast.add({ severity: 'success', summary: 'Success', detail: 'Plot updated.', life: 3000 });
        await refreshData();
        isReplacePolygonDialogShown.value = false;
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Could not save plot.', life: 3000 });
    }
}

async function saveChanges() {
    const featureCollection = draw.getAll();
    const onlyFeature = featureCollection.features[0];
    const area = calculateAreaInHectares(onlyFeature)
    const plotObjectId = onlyFeature.id;

    const payload = {
        geometry: onlyFeature.geometry,
        area: area
    }

    try {
        const response = await patchPlot(plotObjectId, payload);
        toast.add({ severity: 'success', summary: 'Success', detail: 'Plot updated.', life: 3000 });
        await refreshData();
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Could not save plot.', life: 3000 });
    }
}

function cancelChanges() {
    mappingStore.isEditPlotsCardShown = false;
    draw.changeMode('simple_select');
    draw.deleteAll();
    mappingStore.currentGeometryMode = GeometryMode.NONE;
}
</script>

<style scoped>
.save-edit-plots-card {
    position: absolute;
    top: 190px;
    right: 10px;
    z-index: 1000;
    background-color: white;
    border-style: solid;
    border-color: rgb(219, 219, 219);
    border-width: 1px;
}

.save-button {
    height: 25px;
}
</style>