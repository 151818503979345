<template>
    <div class="grid">
        <div class="col-12">
            <span class="font-medium text-lg">Batch <span style="color:red">*</span></span>
        </div>
        <div class="col-12">
            <Dropdown v-model="dueDiligenceStore.value" :options="batches" optionLabel="label" class="w-full">
            </Dropdown>
        </div>
        <div class="col-12">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Internal reference number</label>
                <InputText v-model="dueDiligenceStore.value.internalReferenceNumber" readonly />
            </div>
        </div>
        <div class="col-12">
            <div class="flex flex-column gap-2">
                <label class="font-medium">HS code <span style="color:red">*</span></label>
                <InputText v-model="dueDiligenceStore.value.hsHeading" readonly />
            </div>
        </div>
        <div class="col-12">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Description of goods <span style="color:red">*</span></label>
                <InputText v-model="dueDiligenceStore.value.descriptionOfGoods" readonly />
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Net weight <span style="color:red">*</span></label>
                <InputText v-model="dueDiligenceStore.value.netWeight" readonly />
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Volume</label>
                <InputText v-model="dueDiligenceStore.value.volume" readonly />
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Number of units</label>
                <InputText v-model="dueDiligenceStore.value.numberOfUnits" readonly />
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Universal unit</label>
                <InputText v-model="dueDiligenceStore.value.universalUnit" readonly />
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Scientific name</label>
                <InputText v-model="dueDiligenceStore.value.scientificName" readonly />
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Common name</label>
                <InputText v-model="dueDiligenceStore.value.commonName" readonly />
            </div>
        </div>
    </div>
    <div class="flex pt-4 justify-content-between">
        <Button label="Back" severity="secondary" @click="prevCallback" />
        <Button label="Submit" @click="handleSubmitStatement()" :loading="dueDiligenceStore.isStatementsLoading" />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import { useDueDiligenceStore } from '../../stores/duediligence';
import { useOrganizationStore } from '../../stores/organization';
import { countryCodes } from '../../utils/constants';
import { useToast } from 'primevue/usetoast';
import { useDialogStore } from '../../stores/dialog';
import * as Sentry from "@sentry/vue";


const props = defineProps({
    prevCallback: {
        type: Function,
        required: true
    }
});


const { createDueDiligenceStatement, getAllBatches } = useAnalyticsAPI();
const dueDiligenceStore = useDueDiligenceStore();
const organizationStore = useOrganizationStore();
const dialogStore = useDialogStore();
const toast = useToast();
const batches = ref([]);


function checkRequiredFields() {
    if (!dueDiligenceStore.value) {
        toast.add({ severity: 'info', summary: 'Missing field', detail: "Please select all required fields.", life: 3000 });
        return false
    }

    if (!dueDiligenceStore.value.netWeight) {
        toast.add({ severity: 'info', summary: 'Missing field', detail: "Please select all required fields.", life: 3000 });
        return false
    }

    if (!dueDiligenceStore.value.descriptionOfGoods) {
        toast.add({ severity: 'info', summary: 'Missing field', detail: "Please select all required fields.", life: 3000 });
        return false
    }

    return true
}

function sanitizeFields() {
    const submissionData = {
        email: organizationStore.organization?.email.trim(),
        phone: organizationStore.organization?.phone.trim(),
        identifierValue: organizationStore.organization?.eori.trim(),
        name: organizationStore.organization?.organizationName.trim(),
        address: `${organizationStore.organization?.street.trim()}, ${organizationStore.organization?.postalCode.trim()}, ${organizationStore.organization?.city.trim()}`,
        eori: organizationStore.organization?.eori.trim(),
        activityType: dueDiligenceStore.selectedActivityType?.toUpperCase(),
        supplementaryUnitQualifier: dueDiligenceStore.value?.universalUnit,
        batchObjectId: dueDiligenceStore.value?._id,
        descriptionOfGoods: dueDiligenceStore.value?.descriptionOfGoods.trim(),
        internalReferenceNumber: dueDiligenceStore.value?.internalReferenceNumber,
        netWeight: dueDiligenceStore.value?.netWeight,
        volume: dueDiligenceStore.value?.volume,
        hsHeading: dueDiligenceStore.value?.hsHeading,
        commonName: dueDiligenceStore.value?.commonName,
        scientificName: dueDiligenceStore.value?.scientificName,
        additionalInformation: dueDiligenceStore.additionalInformation.trim(),
    };

    // Replace borderCrossCountry with ISO 3166-1 alpha-2 code if found
    console.log(dueDiligenceStore.borderCrossCountry)
    if (dueDiligenceStore.borderCrossCountry) {
        const isoCode = countryCodes[dueDiligenceStore.borderCrossCountry];
        submissionData.borderCrossCountry = isoCode;
    }

    // Replace borderCrossCountry with ISO 3166-1 alpha-2 code if found
    if (dueDiligenceStore.countryOfActivity) {
        const isoCode = countryCodes[dueDiligenceStore.countryOfActivity];
        submissionData.countryOfActivity = isoCode;
    }

    const isoCode = countryCodes[organizationStore.organization.country];
    if (isoCode) {
        submissionData.country = isoCode;
    } else {
        throw new Error(`No ISO code found for: ${organizationStore.organization.country}`)
    }

    console.log(submissionData)

    return submissionData
}

async function handleSubmitStatement() {
    if (!checkRequiredFields()) {
        return
    }

    const submissionData = sanitizeFields();
    dueDiligenceStore.isStatementsLoading = true;

    try {
        const response = await createDueDiligenceStatement(submissionData);
        dueDiligenceStore.isCreateStatementDialogShown = false;
        toast.add({ severity: 'success', summary: 'Success', detail: 'Submission successful.', life: 3000 });
        await dueDiligenceStore.fetchDueDiligenceStatements();
        dueDiligenceStore.resetStepper();
    } catch (error) {
        Sentry.captureException(error);
        console.log(error)
        dialogStore.errorTemplateTitle = error.response.data.detail.error;
        dialogStore.errorTemplateMessage = error.response.data.detail.message;
        dialogStore.isErrorTemplateDialogShown = true;
    } finally {
        dueDiligenceStore.isStatementsLoading = false;
    }
}

async function fetchData() {
    batches.value = await getAllBatches();
}


fetchData();
</script>