import axios from "axios";
import * as Sentry from "@sentry/vue";
import { useDialogStore } from "../stores/dialog"


export function useAnalyticsAPI() {
    const VITE_ANALYSIS_BACKEND = import.meta.env.VITE_ANALYSIS_BACKEND;
    const dialogStore = useDialogStore();

    // Create base Axios instance for general API calls
    const axiosInstance = axios.create({
        baseURL: VITE_ANALYSIS_BACKEND,
    });

    const axiosInstanceWithoutSessionExpired = axios.create({
        baseURL: VITE_ANALYSIS_BACKEND,
    });

    axiosInstanceWithoutSessionExpired.interceptors.response.use(response => {
        // Handle the response data normally
        return response;
    }, error => {
        return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use(response => {
        // Handle the response data normally
        return response;
    }, error => {
        if (error.response) {
            if (error.response.status === 401) {
                dialogStore.isSessionExpired = true;
            }
        }
        return Promise.reject(error);
    });

    // GET

    async function getPlot(plotObjectId) {
        const response = await axiosInstance.get(`/plots/${plotObjectId}`, {
            withCredentials: true
        });
        return response.data
    }

    async function getAllTokens() {
        const response = await axiosInstance.get(`/tokens`, {
            withCredentials: true
        });
        return response.data
    }

    async function getAllFiles() {
        const response = await axiosInstance.get(`/files`, {
            withCredentials: true
        });
        return response.data
    }

    async function getDownloadFile(fileObjectId) {
        const response = await axiosInstance.get(`/files/${fileObjectId}/download`, {
            responseType: 'blob',
            withCredentials: true
        })
        return response
    }

    async function getFilesOfBatch(batchObjectId) {
        const response = await axiosInstance.get(`/files/batch/${batchObjectId}`, {
            withCredentials: true,
        });
        return response.data;
    }

    async function getPollStatus(jobId) {
        const response = await axiosInstance.get(`/status/${jobId}`, {
            withCredentials: true
        })
        return response.data
    }

    async function getAllBatches() {
        const response = await axiosInstance.get(`/batches`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getAllDueDiligenceStatements() {
        const response = await axiosInstance.get(`/due-diligences`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getBatchByBatchObjectId(batchObjectId) {
        const response = await axiosInstance.get(`/batches/${batchObjectId}`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getDueDiligenceStatementByDdsIdentifier(ddsIdentifier) {
        const response = await axiosInstance.get(`/due-diligences/${ddsIdentifier}`, {
            withCredentials: true,
        });
        return response.data;
    }

    async function getSupplier(soid) {
        const response = await axiosInstance.get(`/suppliers/${soid}`, {
            withCredentials: true,
        });
        return response.data;
    }

    async function getAllSuppliers() {
        const response = await axiosInstance.get(`/suppliers`, {
            withCredentials: true,
        });
        return response.data
    }

    async function patchSupplier(soid, body) {
        const response = await axiosInstance.patch(`/suppliers/${soid}`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function updateBatch(batchObjectId, body) {
        const response = await axiosInstance.patch(`/batches/${batchObjectId}`, body,
            {
                withCredentials: true,
            });

        return response.data;
    }

    async function updatePlot(plotObjectId, body) {
        const response = await axiosInstance.patch(`/plots/${plotObjectId}`, body,
            {
                withCredentials: true,
            });

        return response.data;
    }


    //POST

    async function postSupplier(body) {
        const response = await axiosInstance.post(`/suppliers`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function startRiskDetectionFromMapping(body) {
        const response = await axiosInstance.post(`/plots/risk/mapping`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function createBatchInDataImport(body) {
        const response = await axiosInstance.post(`/batches`, body, {
            withCredentials: true,
        });
        return response.data;
    }

    async function createDueDiligenceStatement(body) {
        const response = await axiosInstance.post(`/due-diligences`, body, {
            withCredentials: true,
        });
        return response.data;
    }

    async function replaceGeometryAndRestartRiskDetection(body) {
        const response = await axiosInstance.post(`/plots/risk/restart/geometry`, body, {
            withCredentials: true,
        });
        return response.data;
    }

    async function restartRiskDetection(body) {
        const response = await axiosInstance.post(`/plots/risk/restart`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function getPaginationPlots(body) {
        const response = await axiosInstance.post(`/plots/pagination`, body, {
            withCredentials: true,
        });
        return response.data;
    }

    async function createNewApiToken() {
        const response = await axiosInstance.post(`/tokens`, null, {  // Body is null
            withCredentials: true,
        });
        return response.data;
    }

    async function createNewPlotFromDesktop(upload_data) {
        const response = await axiosInstance.post(`/plots`, upload_data, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response;
    }

    async function createNewPlotFromMobile(upload_data) {
        const response = await axiosInstanceWithoutSessionExpired.post(`/plots`, upload_data, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response;
    }

    async function exportPlotsGeojson(body) {
        const response = await axiosInstance.post(`/plots/export`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function exportPlotsExcel(body) {
        const response = await axiosInstance.post(`/plots/export`, body, {
            responseType: 'blob',
            withCredentials: true
        });
        return response.data
    }

    async function postRetractDueDiligenceStatement(body) {
        const response = await axiosInstance.post(`/due-diligences/retract`, body, {
            withCredentials: true,
        });
        return response.data
    }

    // DELETE

    async function deleteToken(token) {
        const response = await axiosInstance.delete(`/tokens`, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            },
            data: token
        });

        return response.data;
    }

    async function deletePaginationPlots(plotData) {
        const response = await axiosInstance.delete(`/plots/pagination`, {
            withCredentials: true,
            data: plotData
        });

        return response;
    }

    async function deletePlots(plotData) {
        const response = await axiosInstance.delete(`/plots`, {
            withCredentials: true,
            data: plotData
        });

        return response;
    }

    async function deleteBatch(body) {
        const response = await axiosInstance.delete(`/batches`, {
            withCredentials: true,
            data: body
        });

        return response.data;
    }

    async function deleteFile(fileObjectId) {
        const response = await axiosInstance.delete(`/files/${fileObjectId}`, {
            withCredentials: true,
        });

        return response.data;
    }

    async function deleteSupplier(soid) {
        const response = await axiosInstance.delete(`/suppliers/${soid}`, {
            withCredentials: true,
        });
        return response.data
    }


    return {
        getPlot,
        getDueDiligenceStatementByDdsIdentifier,
        getDownloadFile,
        getBatchByBatchObjectId,
        getPollStatus,
        getFilesOfBatch,
        getAllFiles,
        getAllBatches,
        patchSupplier,
        deleteSupplier,
        postSupplier,
        getAllSuppliers,
        getSupplier,
        exportPlotsExcel,
        exportPlotsGeojson,
        updateBatch,
        updatePlot,
        createBatchInDataImport,
        replaceGeometryAndRestartRiskDetection,
        restartRiskDetection,
        getPaginationPlots,
        getAllTokens,
        createNewApiToken,
        getAllDueDiligenceStatements,
        startRiskDetectionFromMapping,
        createNewPlotFromMobile,
        createNewPlotFromDesktop,
        createDueDiligenceStatement,
        postRetractDueDiligenceStatement,
        deleteToken,
        deletePaginationPlots,
        deletePlots,
        deleteBatch,
        deleteFile,
    }
}
