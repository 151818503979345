<template>
  <router-view></router-view>
  <SessionExpiredDialog />
  <ErrorTemplateDialog />
  <Toast />
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import SessionExpiredDialog from './components/common/SessionExpiredDialog.vue';
import ErrorTemplateDialog from './components/common/ErrorTemplateDialog.vue';


const toast = useToast();
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

.p-rowgroup-header {
  background-color: #efefef;
  font-weight: bold;
}

/* Custom styles for PrimeVue DataTable selected row */
.p-datatable .p-datatable-tbody>tr.p-highlight {
  background-color: #ebfdee !important;
  /* Change this color to your desired background color */
  color: #232323 !important;
}

.p-card {
  border: 1px solid #d8d8d8;
  box-shadow: none !important;
  /* Darker border color */
}

.light-green-nadar {
  background-color: #A1E888;
  border-color: #A1E888;
  color: #151615;
}

.light-green-nadar:hover {
  background-color: #89e269;
}

.light-green-nadar-color {
  color: #A1E888;
}

/*
.p-dropdown {
  border-radius: 20px;
}

.p-multiselect {
  border-radius: 20px;
}

.p-inputtext {
  border-radius: 20px;

}
*/
</style>