<template>
    <Button label="Start Risk Detection" @click="showRiskDetectionDialog" :loading="mappingStore.isTableLoading"
        class="mr-1" />

    <Dialog v-model:visible="isRiskDetectionDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column px-5 py-5 gap-4">
                <span class="text-lg font-medium">Start risk detection?</span>
                <span>This will start risk detection for selected plots.</span>
                <div class="flex justify-content-end">
                    <Button class="mr-3" severity="secondary" label="Cancel" @click="closeCallback"></Button>
                    <Button label="Yes" class="light-green-nadar" @click="startRiskDetection"
                        :loading="isRiskDetectionLoading"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useMappingStore } from '../../stores/mapping';
import { useMappingUtils } from './composables/useMappingUtils';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import * as Sentry from "@sentry/vue";


const { startRiskDetectionFromMapping, getPollStatus } = useAnalyticsAPI();
const { refreshData } = useMappingUtils();
const mappingStore = useMappingStore();
const isRiskDetectionDialogShown = ref(false);
const isRiskDetectionLoading = ref(false);
const toast = useToast();


function showRiskDetectionDialog() {
    if (!mappingStore.selectedCheckboxes.length) {
        toast.add({ severity: 'info', summary: 'Plot Selection', detail: 'Select at least one plot.', life: 3000 });
        return;
    }
    const isCommoditySetEverywhere = checkIfSelectedPlotsHaveCommoditySet();
    if (!isCommoditySetEverywhere) {
        return
    }

    isRiskDetectionDialogShown.value = true;
}

function closeRiskDetectionDialog() {
    isRiskDetectionDialogShown.value = false;
}

function checkIfSelectedPlotsHaveCommoditySet() {
    const foundPlots = mappingStore.mappingPlots.filter(plot => mappingStore.selectedCheckboxes.includes(plot._id));
    for (const plot of foundPlots) {
        if (!plot.commodity) {
            toast.add({ severity: 'info', summary: 'Missing Fields', detail: 'Please fill out all fields.', life: 3000 });
            return false
        }
    }

    return true
}

async function pollJobStatus(jobId) {
    const pollInterval = 3000;

    return new Promise((resolve, reject) => {
        const checkStatus = async () => {
            const response = await getPollStatus(jobId);
            console.log(response)
            if (response.job_status !== "complete" && response.job_status !== "error") {
                console.log("Job still in progress...");
                setTimeout(checkStatus, pollInterval);
            } else if (response.job_status === "complete" || response.job_status === "error") {
                console.log("Job completed:", response.result);
                resolve(response);
            }
        };

        checkStatus();
    });
}

async function startRiskDetection() {
    const payload = {
        plotObjectIds: mappingStore.selectedCheckboxes
    }

    try {
        isRiskDetectionLoading.value = true;
        const response = await startRiskDetectionFromMapping(payload);
        const job = await pollJobStatus(response.job_id);

        if (job.result === "success") {
            mappingStore.showRiskDetectionSuccessDialog();
            closeRiskDetectionDialog();
            await refreshData();
        } else {
            mappingStore.showVRiskDetectionFailureDialog();
        }
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        mappingStore.showVRiskDetectionFailureDialog();
    } finally {
        isRiskDetectionLoading.value = false;
    }
}
</script>