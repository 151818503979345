<template>
    <div class="batches-container">
        <div class="flex justify-content-between">
            <span class="font-medium text-3xl">Batches</span>
            <Button icon="pi pi-sync" class="mr-1" @click="batchStore.fetchBatches"
                :loading="batchStore.isBatchesLoading" />
        </div>
        <div class="mt-3">
            <BatchesTable />
        </div>
    </div>
</template>

<script setup>
import BatchesTable from "../components/batches/BatchesTable.vue";
import { useBatchStore } from "../stores/batch";

const batchStore = useBatchStore();
</script>

<style scoped>
.batches-container {
    flex: 1;
    overflow: auto;
    padding: 1rem;
    box-sizing: border-box;
}
</style>