<template>
    <Dialog v-model:visible="supplierStore.isCreateSupplierDialogShown" modal header="Create a new supplier"
        :style="{ width: '50rem' }" @update:visible="(visible) => !visible && closeAndClearCreateSupplierDialog()">
        <div class="grid">
            <div class="col-6">
                <div class="flex flex-column gap-2">
                    <label class="font-medium">Name <span class="text-red-500">*</span></label>
                    <InputText v-model="name" />
                </div>
                <div class="flex flex-column gap-2 mt-3">
                    <label class="font-medium">Commodities</label>
                    <MultiSelect v-model="selectedCommodities" :options="commodities" :maxSelectedLabels="5" />
                </div>
                <div class="flex flex-column gap-2 mt-3">
                    <label class="font-medium">Email</label>
                    <InputText v-model="email" />
                </div>
            </div>
            <div class="col-6">
                <div class="flex flex-column gap-2">
                    <label class="font-medium">Country <span class="text-red-500">*</span></label>
                    <Dropdown v-model="selectedCountry" :options="countries" filter />
                </div>
                <div class="flex flex-column gap-2 mt-3">
                    <label class="font-medium">Contact name</label>
                    <InputText v-model="contact" />
                </div>
                <div class="flex flex-column gap-2 mt-3">
                    <label class="font-medium">Phone</label>
                    <InputText v-model="phone" />
                </div>
            </div>
            <div class="col-12">
                <div class="flex flex-column gap-2">
                    <label class="font-medium">Address</label>
                    <InputText v-model="address" />
                </div>
            </div>
        </div>
        <div class="flex justify-content-end mt-5">
            <Button label="Cancel" severity="secondary" class="mr-2" @click="closeAndClearCreateSupplierDialog" />
            <Button label="Create supplier" class="light-green-nadar" @click="createSupplier" :loading="isLoading" />
        </div>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import { commodities, countries } from '../../utils/constants';
import { useSupplierStore } from '../../stores/supplier';
import * as Sentry from "@sentry/vue";


const { postSupplier } = useAnalyticsAPI();
const supplierStore = useSupplierStore();
const toast = useToast();
const selectedCountry = ref();
const name = ref();
const email = ref();
const contact = ref();
const phone = ref();
const address = ref();
const isLoading = ref(false);
const selectedCommodities = ref();


async function createSupplier() {
    let isSomethingMissing = false;

    if (!name.value || !selectedCountry.value) {
        isSomethingMissing = true;
        toast.add({ severity: 'info', summary: 'Missing fields', detail: "Please fill out all required fields.", life: 3000 });
        return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.value && !emailPattern.test(email.value.trim())) {
        toast.add({ severity: 'error', summary: 'Invalid Email', detail: "Please enter a valid email address.", life: 3000 });
        return;
    }

    try {
        isLoading.value = true;
        const supplierData = {
            name: name.value?.trim() || undefined,
            phone: phone.value?.trim() || undefined,
            contact: contact.value?.trim() || undefined,
            email: email.value?.trim().toLowerCase() || undefined,
            country: selectedCountry.value || undefined,
            address: address.value?.trim() || undefined
        };

        if (selectedCommodities.value && selectedCommodities.value.length > 0) {
            supplierData.commodities = selectedCommodities.value.map(commodity => ({ "commodity": commodity }));
        }

        console.log(supplierData)
        const response = await postSupplier(supplierData);
        closeAndClearCreateSupplierDialog();
        toast.add({ severity: 'success', summary: 'Success', detail: 'Supplier successfully created.', life: 3000 });
        await supplierStore.fetchAllSuppliers();
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Could not create supplier.', life: 3000 });
    } finally {
        isLoading.value = false;
    }
}

function closeAndClearCreateSupplierDialog() {
    supplierStore.isCreateSupplierDialogShown = false;
    selectedCommodities.value = null;
    selectedCountry.value = null;
    name.value = null;
    phone.value = null;
    contact.value = null;
    email.value = null;
    address.value = null;
}
</script>