<template>
    <Card>
        <template #content>
            <DataTable :value="batchStore.batches" v-model:selection="selectedBatch" selectionMode="single"
                dataKey="_id" size="small" scrollable scrollHeight="calc(100vh - 160px)" @rowSelect="selectBatch">
                <Column field="label" header="Batch label"></Column>
                <Column field="_id" header="Batch ID"></Column>
                <Column field="createdAt" header="Created at">
                    <template #body="{ data }">
                        {{ formatDate(data.createdAt) }}
                    </template>
                </Column>
                <Column header="Actions">
                    <template #body="{ data }">
                        <Button icon="pi pi-trash" outlined size="small" @click="showDeleteBatchDialog(data)" />
                    </template>
                </Column>
                <template #empty>
                    <div v-if="!batchStore.isBatchesLoading"
                        class="flex flex-column align-items-center justify-content-center select-none">
                        <img :src="placeholder" class="mb-2" width="150" />
                        <span>No batches available for display.</span>
                        <small>Please import a file in Data Import Page to create a batch.</small>
                    </div>
                    <div v-else>
                        <ProgressBar mode="indeterminate" style="height:2px"></ProgressBar>
                    </div>
                </template>
            </DataTable>
        </template>
    </Card>

    <Dialog v-model:visible="isDeleteBatchDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column px-5 py-5 gap-4">
                <span class="text-lg font-medium">Delete batch?</span>
                <span>Selected batch will be deleted. You can't undo this action.</span>
                <div class="flex justify-content-end">
                    <Button label="Cancel" severity="secondary" class="mr-3" @click="closeCallback"></Button>
                    <Button label="Delete" severity="danger" @click="handleDeletePlots"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>


<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import placeholder from "../../assets/placeholder_suppliers.svg"
import { useBatchStore } from "../../stores/batch"
import { useAnalyticsAPI } from "../../composables/useAnalyticsAPI";
import { useToast } from "primevue/usetoast";
import { useDataFormatUtils } from "../../composables/useDataFormatUtils";
import { useRoute } from "vue-router";
import * as Sentry from "@sentry/vue";


const { deleteBatch } = useAnalyticsAPI();
const { formatDate } = useDataFormatUtils();
const toast = useToast();
const route = useRoute();
const batchStore = useBatchStore();
const router = useRouter();
const batch = ref();
const selectedBatch = ref();
const isDeleteBatchDialogShown = ref(false);
const batchLabel = ref();


function showDeleteBatchDialog(data) {
    batch.value = data;
    isDeleteBatchDialogShown.value = true;
}

async function handleDeletePlots() {
    isDeleteBatchDialogShown.value = false;
    const body = {
        batchObjectId: batch.value._id
    }
    try {
        const response = await deleteBatch(body);
        await fetchBatches();
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to delete batch.',
            life: 3000
        });
    }
}

function selectBatch(event) {
    selectedBatch.value = event.data;
    const batchObjectId = event.data._id;

    for (const batch of batchStore.batches) {
        if (batch._id === batchObjectId) {
            router.push({ name: "batch", params: { batchObjectId } });
            break;
        }
    }
}

async function fetchBatches() {
    await batchStore.fetchBatches()
};

fetchBatches();
</script>