import { usePlotStore } from "../../../../stores/plot";

export function usePagination() {
    const plotStore = usePlotStore();

    function unselectAllCheckboxes() {
        plotStore.isEveryPageSelected = false;
        plotStore.selectedCheckboxes = [];
        plotStore.isCurrentPageSelected = false;
        plotStore.selectedPages = [];
    }

    function toggleAll() {
        if (plotStore.isEveryPageSelected) {
            unselectAllCheckboxes();
        } else {
            plotStore.isCurrentPageSelected = true;
            plotStore.isEveryPageSelected = true;
            togglePageCheckboxes();
        }
    }

    function toggleCheckbox(plotObjectId, event) {
        console.log("toggleCheckboxes")
        const isChecked = event.target.checked;
        plotStore.isEveryPageSelected = false;

        if (isChecked) {
            if (!plotStore.selectedCheckboxes.includes(plotObjectId)) {
                plotStore.selectedCheckboxes.push(plotObjectId);
            }
        } else {
            plotStore.selectedCheckboxes = plotStore.selectedCheckboxes.filter(_id => _id !== plotObjectId);
        }

        updatePageSelectionState();
    }

    function updatePageSelectionState() {
        const currentPage = plotStore.pageParams.page;
        const currentPagePlotIds = plotStore.plots.map(plot => plot._id);

        const allSelected = currentPagePlotIds.every(_id => plotStore.selectedCheckboxes.includes(_id));
        plotStore.isCurrentPageSelected = allSelected;

        plotStore.selectedPages[currentPage] = {
            selected: allSelected,
            plots: plotStore.selectedCheckboxes.filter(_id => currentPagePlotIds.includes(_id)),
        };
    }

    function togglePageCheckboxes() {
        console.log("toggleCheckboxForWholePage")
        const currentPage = plotStore.pageParams.page;

        if (plotStore.isCurrentPageSelected) {
            const selectedPlotObjectIds = plotStore.plots.map(plot => plot._id);
            const newSelections = selectedPlotObjectIds.filter(plotObjectId => !plotStore.selectedCheckboxes.includes(plotObjectId));
            plotStore.selectedPages[currentPage] = {
                selected: true,
                plots: selectedPlotObjectIds
            };
            plotStore.selectedCheckboxes.push(...newSelections)
        } else {
            const currentPagePlotObjectIds = plotStore.plots.map(plot => plot._id);
            plotStore.selectedCheckboxes = plotStore.selectedCheckboxes.filter(selectedPlotObjectId => !currentPagePlotObjectIds.includes(selectedPlotObjectId));
            plotStore.selectedPages[currentPage] = { selected: false, plots: [] };
            plotStore.isEveryPageSelected = false;
        }
    }

    return {
        toggleAll,
        toggleCheckbox,
        unselectAllCheckboxes,
        togglePageCheckboxes
    }
}
