<template>
    <div
        class="flex flex-column justify-content-center align-items-center h-14rem border-1 surface-border border-round gap-3">
        <img :src="placeholder_import" width="50px">
        <div v-if="dataImportStore.currentImportMode === ImportMode.IDLE" class="flex flex-column align-items-center"
            style="height: 50px;">
            <Button class="light-green-nadar" label="Choose GeoJSON, KML/KMZ, zipped Shapefile, CSV or XLSX"
                @click="triggerFileInput" />
        </div>
        <div v-if="dataImportStore.currentImportMode === ImportMode.IMPORT" style="height: 50px;">
            <span class="font-medium border-1 border-round p-1">{{ fileName }}</span>
        </div>
    </div>

    <input id="fileInput" type="file" ref="fileInput" style="display: none" accept=".geojson,.kml,.kmz,.zip,.csv,.xlsx"
        @change="startImportFileProcess" />
    <div class="flex pt-4 justify-content-between" v-if="dataImportStore.currentImportMode === ImportMode.IMPORT">
        <Button label="Cancel" severity="secondary" @click="resetStepper" />
        <Button label="Next" @click="nextCallback" />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useDataFormatUtils } from '../../composables/useDataFormatUtils';
import { useDataImportUtils } from './composables/useDataImportUtils';
import { useDataImportStore } from '../../stores/dataImport';
import { parse } from 'papaparse';
import * as XLSX from 'xlsx';
import placeholder_import from "../../assets/placeholder_import.svg";
import { io } from 'socket.io-client';
import pako from "pako";
import * as togeojson from '@mapbox/togeojson';
import { useCommonDialogs } from "../../composables/useCommonDialogs";
import * as Sentry from "@sentry/vue";
import { useToast } from "primevue/usetoast";
import JSZip from 'jszip';
import { ImportMode } from '../../utils/constants';


const props = defineProps({
    nextCallback: {
        type: Function,
        required: true
    },
    resetStepper: {
        type: Function,
        required: true
    }
});

const VITE_ANALYSIS_BACKEND = import.meta.env.VITE_ANALYSIS_BACKEND;
const { loadPlotsFromGeoJSON } = useDataImportUtils();
const { readFileAsText } = useDataFormatUtils();
const dataImportStore = useDataImportStore();
const toast = useToast();
const fileName = ref();
const fileInput = ref(null);


function triggerFileInput() {
    fileInput.value.click();
}

async function startImportFileProcess(event) {
    dataImportStore.showFileProcessingDialog();

    const file = event.target.files[0];
    dataImportStore.file = file;
    fileName.value = file.name;
    dataImportStore.fileType = fileName.value.split('.').pop().toLowerCase();

    try {
        await processDifferentFileType(file);
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        dataImportStore.closeFileProcessingDialog();
        toast.add({ severity: 'error', summary: 'Processing Error', detail: "Error processing the file" });
    }
}

async function processDifferentFileType(file) {
    switch (dataImportStore.fileType) {
        case 'csv':
            await processCSV(file);
            break;
        case 'xlsx':
            await processXLSX(file);
            break;
        case 'kml':
            await processKML(file);
            break;
        case 'kmz':
            await processKMZ(file);
            break;
        case 'zip':
        case 'geojson':
            await processGeoJSON(file);
            break;
        default:
            toast.add({ severity: 'error', summary: 'File Type Error', detail: 'Unsupported file type.' });
            break;
    }
}

// Function to detect the delimiter
function detectDelimiter(csvText) {
    const delimiters = [',', ';', '\t'];
    const firstLine = csvText.split('\n')[0]; // Check the first line (header)
    const delimiterCounts = {};

    delimiters.forEach(delimiter => {
        delimiterCounts[delimiter] = (firstLine.split(delimiter).length - 1);
    });

    return Object.keys(delimiterCounts).reduce((a, b) => delimiterCounts[a] > delimiterCounts[b] ? a : b);
}

async function convertGisFilePropertiesForDataTable() {
    dataImportStore.parsedData.data = [];

    const allPropertyKeys = new Set();
    for (const plot of dataImportStore.plotsWithUserDetails) {
        for (const key of Object.keys(plot.properties)) {
            allPropertyKeys.add(key);
        }
    }

    // Update properties, columns, and selectableProperties
    for (const key of allPropertyKeys) {
        if (!dataImportStore.columnNamesDataTableFormat.some(col => col.field === key)) {
            dataImportStore.columnNamesDataTableFormat.push({ field: key, header: key });
        }

        if (!dataImportStore.columnNames.includes(key)) {
            dataImportStore.columnNames.push(key);
        }
    }

    // Push plot properties
    for (const plot of dataImportStore.plotsWithUserDetails) {
        dataImportStore.parsedData.data.push(plot.properties);
    }
}

async function processCSV(file) {
    const text = await readFileAsText(file);
    const delimiter = detectDelimiter(text);
    dataImportStore.parsedData = parse(text, { header: true, delimiter: delimiter, dynamicTyping: false });
    if (dataImportStore.parsedData?.errors) {
        const indicesToRemove = [];
        for (const error of dataImportStore.parsedData.errors) {
            if (error.code === "TooFewFields" && (error.message.includes("but parsed 1") || (error.message.includes("but parsed 0")))) {
                indicesToRemove.push(error.row)
            }
        }
        // Remove the rows with matching indices
        dataImportStore.parsedData.data = dataImportStore.parsedData.data.filter((_, index) => !indicesToRemove.includes(index));
    }

    dataImportStore.columnNames = dataImportStore.parsedData.meta.fields;
    dataImportStore.columnNamesDataTableFormat = dataImportStore.columnNames.map(col => ({ field: col, header: col }));
    dataImportStore.currentImportMode = ImportMode.IMPORT;
    dataImportStore.closeFileProcessingDialog();
}

async function processXLSX(file) {
    const arrayBuffer = await file.arrayBuffer();
    const data = new Uint8Array(arrayBuffer);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const csvText = XLSX.utils.sheet_to_csv(worksheet);
    const delimiter = detectDelimiter(csvText);
    dataImportStore.parsedData = parse(csvText, { header: true, delimiter: delimiter, dynamicTyping: false });
    dataImportStore.columnNames = dataImportStore.parsedData.meta.fields;
    dataImportStore.columnNamesDataTableFormat = dataImportStore.columnNames.map(col => ({ field: col, header: col }));
    dataImportStore.currentImportMode = ImportMode.IMPORT;
    dataImportStore.closeFileProcessingDialog();
}

async function processKML(file) {
    const text = await file.text();
    const kml = new DOMParser().parseFromString(text, "text/xml");
    const geojsonData = togeojson.kml(kml);
    loadPlotsFromGeoJSON(geojsonData);
    convertGisFilePropertiesForDataTable();
    dataImportStore.currentImportMode = ImportMode.IMPORT;
    dataImportStore.closeFileProcessingDialog();
}

async function processKMZ(file) {
    const arrayBuffer = await file.arrayBuffer();
    const zip = new JSZip();
    const zipContents = await zip.loadAsync(arrayBuffer);
    const kmlFile = Object.keys(zipContents.files).find((filename) => filename.endsWith('.kml'));
    if (kmlFile) {
        const kmlText = await zipContents.files[kmlFile].async("string");
        const kml = new DOMParser().parseFromString(kmlText, "text/xml");
        const geojsonData = togeojson.kml(kml);
        loadPlotsFromGeoJSON(geojsonData);
        convertGisFilePropertiesForDataTable();
        dataImportStore.currentImportMode = ImportMode.IMPORT;
        dataImportStore.closeFileProcessingDialog();
    }
    else {
        throw new Error("No KML file found in KMZ.")
    }
}

async function processGeoJSON(file) {
    dataImportStore.showFileProcessingDialog();
    const socket = io(VITE_ANALYSIS_BACKEND, {
        transports: ['websocket'], // Ensure we use WebSocket
        path: '/sockets',  // Set the path if necessary
        timeout: 2000,             // Set timeout for connection attempt
        reconnectionAttempts: 5,   // Limit reconnection attempts
        reconnectionDelay: 1000,   // Delay between reconnection attempts
        reconnectionDelayMax: 5000 // Maximum delay between reconnection attempts
    });

    socket.on('connect', () => {
        console.log('Socket.IO connection opened');
        // Send metadata first
        socket.emit('convert_file_metadata', {
            file_type: dataImportStore.fileType
        });
    });

    socket.on('metadata_ack', async () => {
        console.log("Received metadata ack, sending file data...");
        try {
            const arrayBuffer = await file.arrayBuffer();
            const compressedData = pako.deflate(new Uint8Array(arrayBuffer));
            console.log("compressed and sending now data.")
            socket.emit('convert_file', compressedData);
        } catch (error) {
            Sentry.captureException(error);
            console.log(error);
        }
    });

    socket.on('task_completed', async (response) => {
        console.log(response)
        dataImportStore.closeFileProcessingDialog();
        if (response.status === "PASSED") {
            try {
                const geojsonData = JSON.parse(response.results)
                loadPlotsFromGeoJSON(geojsonData);
                convertGisFilePropertiesForDataTable();
                dataImportStore.currentImportMode = ImportMode.IMPORT;
            } catch (error) {
                Sentry.captureException(error);
                console.log(error)
            }
        } else {
            dataImportStore.showInvalidFileDialog("Invalid file. Please contact support.")
        }

        socket.disconnect();
    });

    socket.on('error', (reason) => {
        console.error('Socket.IO error:', reason);

        if (reason.status === "InvalidFileException") {
            dataImportStore.showInvalidFileDialog(reason.message)
        } else if (reason.status === "InvalidGeometryException") {
            console.log(reason.message)
            dataImportStore.showInvalidGeometryDialog(reason.message)
        } else {
            dataImportStore.showInvalidFileDialog("Invalid file. Please contact support.")
        }

        socket.disconnect();
    });

    socket.on('disconnect', (reason) => {
        console.log("disconnect")
        dataImportStore.closeFileProcessingDialog();
        socket.disconnect();
    });
}


</script>