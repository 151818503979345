<template>
    <div v-if="isLegendVisible" class="p-card p-2" style="width: 260px;">
        <div class="flex justify-content-between align-items-center pb-1">
            <span class="text-base font-bold">Legend</span>
            <i class="pi pi-times-circle cursor-pointer" @click="closeLegend"></i>
        </div>
        <!-- Group: EU Forest and JRC Deforestation -->
        <div class="p-2 border-round group-border">
            <!-- EU Forest 2020 -->
            <div class="flex align-items-center justify-content-between">
                <div class="flex align-items-center gap-2">
                    <span class="color-box bg-eu-forest"></span>
                    <span>EU Forest 2020 (GFC)</span>
                </div>
                <div class="flex align-items-center gap-2">
                    <i class="pi pi-info-circle text-gray-500 cursor-pointer"
                        v-tooltip.bottom="{ value: tooltipEuForest, autoHide: false }"></i>
                    <i :class="isLayerVisible['eu-forest-layer'] ? 'pi pi-eye' : 'pi pi-eye-slash'"
                        class="cursor-pointer" @click="toggleVisibility('eu-forest-layer')"></i>
                </div>
            </div>

            <!-- JRC Deforestation 2021 -->
            <div class="flex align-items-center justify-content-between">
                <div class="flex align-items-center gap-2">
                    <span class="color-box bg-jrc-red"></span>
                    <span>JRC Deforestation 2021</span>
                </div>
                <div class="flex align-items-center gap-2">
                    <i class="pi pi-info-circle text-gray-500 cursor-pointer"
                        v-tooltip.bottom="{ value: tooltipJrcDeforestation, autoHide: false }"></i>
                    <i :class="isLayerVisible['eu-deforestation-2021-layer'] ? 'pi pi-eye' : 'pi pi-eye-slash'"
                        class="cursor-pointer" @click="toggleVisibility('eu-deforestation-2021-layer')"></i>
                </div>
            </div>

            <!-- JRC Deforestation 2022 -->
            <div class="flex align-items-center justify-content-between">
                <div class="flex align-items-center gap-2">
                    <span class="color-box bg-jrc-red"></span>
                    <span>JRC Deforestation 2022</span>
                </div>
                <div class="flex align-items-center gap-2">
                    <i class="pi pi-info-circle text-gray-500 cursor-pointer"
                        v-tooltip.bottom="{ value: tooltipJrcDeforestation, autoHide: false }"></i>
                    <i :class="isLayerVisible['eu-deforestation-2022-layer'] ? 'pi pi-eye' : 'pi pi-eye-slash'"
                        class="cursor-pointer" @click="toggleVisibility('eu-deforestation-2022-layer')"></i>
                </div>
            </div>

            <!-- JRC Deforestation 2023 -->
            <div class="flex align-items-center justify-content-between">
                <div class="flex align-items-center gap-2">
                    <span class="color-box bg-jrc-red"></span>
                    <span>JRC Deforestation 2023</span>
                </div>
                <div class="flex align-items-center gap-2">
                    <i class="pi pi-info-circle text-gray-500 cursor-pointer"
                        v-tooltip.bottom="{ value: tooltipJrcDeforestation, autoHide: false }"></i>
                    <i :class="isLayerVisible['eu-deforestation-2023-layer'] ? 'pi pi-eye' : 'pi pi-eye-slash'"
                        class="cursor-pointer" @click="toggleVisibility('eu-deforestation-2023-layer')"></i>
                </div>
            </div>
        </div>

        <!-- Group: Nadar Forest and Nadar Deforestation -->
        <div class="p-2 rounded-border group-border mt-2">
            <!-- Nadar Forest 2020 -->
            <div class="flex align-items-center justify-content-between">
                <div class="flex align-items-center gap-2">
                    <span class="color-box bg-nadar-forest"></span>
                    <span>Nadar Forest 2020</span>
                </div>
                <div class="flex align-items-center gap-2">
                    <i class="pi pi-info-circle text-gray-500 cursor-pointer"
                        v-tooltip.bottom="{ value: tooltipNadarForest, autoHide: false }"></i>
                    <i :class="isLayerVisible['nadar-forest-layer'] ? 'pi pi-eye' : 'pi pi-eye-slash'"
                        class="cursor-pointer" @click="toggleVisibility('nadar-forest-layer')"></i>
                </div>
            </div>

            <!-- Nadar Deforestation 2021-24 -->
            <div class="flex align-items-center justify-content-between">
                <div class="flex align-items-center gap-2">
                    <span class="color-box bg-nadar-deforestation"></span>
                    <span>Nadar Deforestation 2021-24</span>
                </div>
                <div class="flex align-items-center gap-2">
                    <i class="pi pi-info-circle text-gray-500 cursor-pointer"
                        v-tooltip.bottom="{ value: tooltipNadarDeforestation, autoHide: false }"></i>
                    <i :class="isLayerVisible['nadar-deforestation-layer'] ? 'pi pi-eye' : 'pi pi-eye-slash'"
                        class="cursor-pointer" @click="toggleVisibility('nadar-deforestation-layer')"></i>
                </div>
            </div>
        </div>
        <!--
        <div class="p-2 rounded-border group-border mt-2">
            <div class="flex align-items-center justify-content-between">
                <div class="flex align-items-center gap-2">
                    <span class="color-box border-protected"></span>
                    <span>Protected Areas</span>
                </div>
                <div class="flex align-items-center gap-2">
                    <i class="pi pi-info-circle text-gray-500 cursor-pointer"
                        v-tooltip="'Information about Protected Areas'"></i>
                    <i :class="isLayerVisible['protected-areas-layer'] ? 'pi pi-eye' : 'pi pi-eye-slash'"
                        class="cursor-pointer" @click="toggleVisibility('protected-areas-layer')"></i>
                </div>
            </div>

            <div class="flex align-items-center justify-content-between">
                <div class="flex align-items-center gap-2">
                    <span class="color-box border-indigenous"></span>
                    <span>Indigenous Lands</span>
                </div>
                <div class="flex align-items-center gap-2">
                    <i class="pi pi-info-circle text-gray-500 cursor-pointer"
                        v-tooltip="'Information about Indigenous Lands'"></i>
                    <i :class="isLayerVisible['indigenous-lands-layer'] ? 'pi pi-eye' : 'pi pi-eye-slash'"
                        class="cursor-pointer" @click="toggleVisibility('indigenous-lands-layer')"></i>
                </div>
            </div>
        </div>
        -->
    </div>
    <div v-else class="p-card p-2 cursor-pointer" @click="openLegend">
        <img :src="layerIcon" width="20px">
    </div>
</template>


<script setup>
import { ref, inject } from 'vue';
import layerIcon from "../../../assets/icons/layer.png"


let batchMap = inject("batchMap");
const isLegendVisible = ref(true);
const tooltipEuForest = "The map on global forest cover (GFC 2020) by the Joint Research Centre was created by combining available global datasets on tree cover, tree height, land cover and land use into a single harmonized globally-consistent representation of where forests existed in 2020. Data source: \n https://forest-observatory.ec.europa.eu/forest \n https://forobs.jrc.ec.europa.eu/GFC"
const tooltipJrcDeforestation = "The Tropical Moist Forest dataset from the Joint Research Centre provides information on changes in forest cover from 1990 up to the year 2023 in the humid tropics. The wall-to-wall maps at 0.09 ha resolution (30m) depict the tropical moist forest extent and the related disturbances (deforestation and degradation). Deforestation refers to a change in land cover (from forest to non-forested land) when degradation refers to a short-duration disturbance in a forest remaining forested such as selective logging, fires and unusual weather events (hurricanes, droughts, blowdown). Data source: https://forest-observatory.ec.europa.eu/forest"
const tooltipNadarForest = "Nadar’s proprietary forest cover 2020 map is based on remote sensing and Machine Learning analysis using time series satellite imagery from the Copernicus Sentinel-2 mission of the European Space Agency (ESA) at a spatial resolution of 10 meters. Data source: https://www.esa.int/Applications/Observing_the_Earth/Copernicus/Sentinel-2 Nadar’s forest map follows the forest definition of the Regulation (EU) 2023/1115 Article 2 (4)."
const tooltipNadarDeforestation = "Nadar’s proprietary deforestation map is based on remote sensing and Machine Learning analysis using time series satellite imagery from the Copernicus Sentinel-2 mission of the European Space Agency (ESA) at a spatial resolution of 10 meters. Data source: https://www.esa.int/Applications/Observing_the_Earth/Copernicus/Sentinel-2 Nadar’s deforestation map follows the definition of the Regulation (EU) 2023/1115 Article 2 (3)."
const tooltipProtectedAreas = "Nadar’s dataset on protected areas combines geospatial data from Open Street Map (OSM) and various national databases on protected areas; protected or conserved areas implemented for biodiversity conservation such as national parks, wilderness areas, community conserved areas, and nature reserves. Data sources: https://www.openstreetmap.org"
const tooltipIndigenousLands = "Nadar’s dataset on indigenous lands combines geospatial data from Open Street Map (OSM), LandMark, RAISG and national databases on indigenous lands. Data sources:  https://www.openstreetmap.org https://www.raisg.org/en/"
const isLayerVisible = ref({
    'eu-forest-layer': true,
    'eu-deforestation-2021-layer': true,
    'eu-deforestation-2022-layer': true,
    'eu-deforestation-2023-layer': true,
    'nadar-forest-layer': true,
    'nadar-deforestation-layer': true,
    'protected-areas-layer': false,
    'indigenous-lands-layer': false,
});


function openLegend() {
    isLegendVisible.value = true;
}

function closeLegend() {
    isLegendVisible.value = false;
}

function toggleVisibility(layer) {
    // Toggle the visibility state for the layer
    isLayerVisible.value[layer] = !isLayerVisible.value[layer];

    // Determine the visibility value based on the new state
    const visibility = isLayerVisible.value[layer] ? "visible" : "none";

    // Log for debugging
    console.log(`Layer: ${layer}, Visibility: ${visibility}`);

    // Update the map layer visibility
    batchMap.value.setLayoutProperty(layer, 'visibility', visibility);

    console.log(isLayerVisible.value)
}
</script>



<style scoped>
.color-box {
    width: 16px;
    height: 16px;
    border-radius: 4px;
}

.bg-eu-forest {
    background-color: #029C54;
}

.bg-jrc-red {
    background-color: #DB0505;
}

.bg-nadar-forest {
    background-color: #62FF74;
}

.bg-nadar-deforestation {
    background-color: #FF4400;
}

.border-protected {
    border: 2px solid #7768FF;
    background-color: transparent;
}

.border-indigenous {
    border: 2px solid #FFCE74;
    background-color: transparent;
}

.group-border {
    border: 1px solid #e8e8e8;
    border-radius: 8px;
}
</style>