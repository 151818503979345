<template>
    <Dialog v-model:visible="supplierStore.isEditSupplierDialogShown" modal :style="{ width: '45rem' }">
        <template #header>
            <span class="text-xl font-medium">Edit supplier</span>
        </template>
        <div class="grid">
            <div class="flex flex-column col-6 gap-2">
                <label class="font-medium">Name <span class="text-red-500">*</span></label>
                <InputText v-model="name" />
            </div>
            <div class="flex flex-column col-6 gap-2">
                <label class="font-medium">Country <span class="text-red-500">*</span></label>
                <Dropdown v-model="selectedCountry" :options="countries" filter />
            </div>
            <div class="flex flex-column col-6 gap-2">
                <label class="font-medium">Commodities</label>
                <MultiSelect v-model="selectedCommodities" :options="commodities" :maxSelectedLabels="4" />
            </div>
            <div class="flex flex-column col-6 gap-2">
                <label class="font-medium">Contact name</label>
                <InputText v-model="contact" />
            </div>
            <div class="flex flex-column col-6 gap-2">
                <label class="font-medium">Email</label>
                <InputText v-model="email" />
            </div>
            <div class="flex flex-column col-6 gap-2">
                <label class="font-medium">Phone</label>
                <InputText v-model="phone" />
            </div>
            <div class="flex flex-column col-12 gap-2">
                <label class="font-medium">Address</label>
                <InputText v-model="address" />
            </div>
            <div class="col-12">
                <div class="flex justify-content-end mt-3 mb-3">
                    <Button label="Cancel" severity="secondary" class="mr-2" @click="closeEditSupplierDialog" />
                    <Button label="Save changes" class="light-green-nadar" @click="updateSupplier"
                        :loading="isUpdatingSupplierLoading" />
                </div>
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import { countries, commodities } from '../../utils/constants';
import { useSupplierStore } from '../../stores/supplier';
import { useToast } from 'primevue/usetoast';
import * as Sentry from "@sentry/vue";


const props = defineProps({
    supplier: Object
})

const { patchSupplier } = useAnalyticsAPI();
const toast = useToast();
const supplierStore = useSupplierStore();
const selectedCountry = ref();
const isUpdatingSupplierLoading = ref(false);
const selectedCommodities = ref();
const name = ref();
const address = ref();
const email = ref();
const phone = ref();
const contact = ref();


watch(() => props.supplier, (newVal) => {
    if (newVal) {
        name.value = newVal.name || null;
        email.value = newVal.email || null;
        phone.value = newVal.phone || null;
        contact.value = newVal.contact || null;
        address.value = newVal.address || null;

        if (newVal.country) {
            const matchingCountry = countries.find(country => country === newVal.country);
            selectedCountry.value = matchingCountry;
        }

        if (newVal.commodities) {
            selectedCommodities.value = newVal.commodities.map(commodity => commodity.commodity)
        }
    }
});

function closeEditSupplierDialog() {
    supplierStore.isEditSupplierDialogShown = false;
}

function validateSupplierData() {
    let isSomethingMissing = false;

    if (!name.value || name.value.trim().length === 0) {
        isSomethingMissing = true;
    }
    if (!selectedCountry.value) {
        isSomethingMissing = true;
    }

    // Simple email regex for validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.value && !emailPattern.test(email.value.trim())) {
        toast.add({ severity: 'error', summary: 'Invalid Email', detail: "Please enter a valid email address.", life: 3000 });
        return false;
    }

    if (isSomethingMissing) {
        toast.add({ severity: 'info', summary: 'Missing fields', detail: "Please fill out all required fields.", life: 3000 });
        return false;
    }

    return true;
}

function prepareSupplierData() {
    const data = {
        supplierObjectId: props.supplier._id,
        name: name.value?.trim(),
        contact: contact.value?.trim(),
        email: email.value?.trim().toLowerCase(),
        phone: phone.value?.trim(),
        country: selectedCountry.value,
        address: address.value?.trim(),
        commodities: selectedCommodities.value?.map(commodity => ({ "commodity": commodity })) || []
    };

    return data;
}

async function updateSupplier() {
    if (!validateSupplierData()) {
        return;
    }

    const data = prepareSupplierData();
    isUpdatingSupplierLoading.value = true;

    try {
        const response = await patchSupplier(props.supplier._id, data);
        closeEditSupplierDialog();
        toast.add({ severity: 'success', summary: 'Success', detail: 'Supplier updated.', life: 3000 });
        await supplierStore.fetchAllSuppliers();
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        isUpdatingSupplierLoading.value = false;
        toast.add({ severity: 'error', summary: 'Error', detail: 'Could not update supplier.', life: 3000 });
    } finally {
        isUpdatingSupplierLoading.value = false;
    }
}
</script>