<template>
    <div id="batchMap" class="border-round">
        <img :src="nadarLogo" class="mapbox-nadar-logo" width="60">
        <div class="overlay-satelliteImages">
            <Dropdown v-model="selectedSatelliteDate" :options="groupedSatelliteData" optionLabel="label"
                optionGroupLabel="label" optionGroupChildren="items" display="chip" placeholder="Satellite Images"
                class="w-13rem" filter showClear>
            </Dropdown>
        </div>
        <div class="overlay-legend">
            <PlotsMapLegend />
        </div>
        <div v-if="plotStore.isEditPlotActivated">
            <div class="flex border-round p-2 draw-polygon-icon" @click="drawPolygon">
                <span class="font-medium">Draw Polygon</span>
            </div>
            <div style="background-color:white" class="save-edit-plots-card border-round p-2">
                <span class="text-base font-medium">Save changes?</span>
                <div class="flex justify-content-between pt-2">
                    <Button label="No" class="mr-3 save-button" severity="secondary" @click="resetChanges" />
                    <Button label="Yes" class="save-button" @click="showReplaceGeometryDialog" />
                </div>
            </div>
        </div>

        <MapboxAttributions />
    </div>

    <Dialog v-model:visible="isReplaceGeometryDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column px-5 py-5 gap-4">
                <span class="text-lg font-medium">Replace geometry?</span>
                <span>This will replace the previous geometry and initiate a new risk detection for this plot.</span>
                <div class="flex justify-content-end">
                    <Button label="Cancel" severity="secondary" class="mr-3" @click="closeCallback"></Button>
                    <Button label="Yes" class="light-green-nadar" @click="replaceGeometry"
                        :loading="isReplaceGeometryLoading"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch, inject } from "vue";
import MapboxAttributions from "../../common/MapboxAttributions.vue";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import nadarLogo from "../../../assets/nadarLogoWhite.png"
import { useUsersStore } from "../../../stores/users";
import { useRoute } from "vue-router";
import debounce from 'lodash.debounce';
import PlotsMapLegend from "./PlotsMapLegend.vue";
import { groupedSatelliteData } from "../../../utils/constants";
import { usePlotStore } from '../../../stores/plot';
import { GeometryMode } from "../../../utils/constants";
import { useAnalyticsAPI } from "../../../composables/useAnalyticsAPI";
import { usePlotRiskUtils } from "./composables/usePlotRiskUtils";
import { useToast } from 'primevue/usetoast';
import * as Sentry from "@sentry/vue";


const VITE_EUDR_BACKEND = import.meta.env.VITE_EUDR_BACKEND;
const VITE_ANALYSIS_BACKEND = import.meta.env.VITE_ANALYSIS_BACKEND;
const VITE_MAPBOX2_ACCESS_TOKEN = import.meta.env.VITE_MAPBOX2_ACCESS_TOKEN;
mapboxgl.accessToken = VITE_MAPBOX2_ACCESS_TOKEN;

const props = defineProps({
    selectedPlot: Object,
    isActive: {
        type: Boolean,
        default: false
    }
});


const route = useRoute();
const { getPollStatus, replaceGeometryAndRestartRiskDetection, getPlot } = useAnalyticsAPI();
const { refreshData } = usePlotRiskUtils();
let batchMap = inject("batchMap");
const draw = inject("draw");
const plotStore = usePlotStore();
const usersStore = useUsersStore();
const batchObjectId = route.params.batchObjectId;
let isIdle = ref(true);
const selectedSatelliteDate = ref();
const isReplaceGeometryDialogShown = ref(false);
const isReplaceGeometryLoading = ref(false);
const toast = useToast();
const lastDrawnFeature = ref({});


watch(() => selectedSatelliteDate.value, (selectedSatelliteDate) => {
    if (selectedSatelliteDate) {
        if (selectedSatelliteDate.provider === "planet") {
            updateNicfiLayer(selectedSatelliteDate.code);
        } else {
            updateEsriLayer(selectedSatelliteDate.code)
        }
    } else {
        removeNicfiLayer();
        removeEsriLayer();
    }
})

function removeNicfiLayer() {
    if (batchMap.value.getLayer("deforestation-planetnicfi-layer")) {
        batchMap.value.removeLayer("deforestation-planetnicfi-layer");
        batchMap.value.removeSource("deforestation-planetnicfi");
    }
}

function removeEsriLayer() {
    if (batchMap.value.getLayer("deforestation-esri-layer")) {
        batchMap.value.removeLayer("deforestation-esri-layer");
        batchMap.value.removeSource("deforestation-esri-source");
    }
}

function updateEsriLayer(newDate) {
    if (batchMap.value.getLayer("deforestation-esri-layer")) {
        batchMap.value.removeLayer("deforestation-esri-layer");
        batchMap.value.removeSource("deforestation-esri-source");
    }

    batchMap.value.addSource('deforestation-esri-source', {
        'type': 'raster',
        'tiles': [
            `https://wayback.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/WMTS/1.0.0/default028mm/MapServer/tile/${newDate}/{z}/{y}/{x}` // Adjusted URL for Mapbox
        ],
        'tileSize': 256
    });

    batchMap.value.addLayer({
        'id': 'deforestation-esri-layer',
        'type': 'raster',
        'source': 'deforestation-esri-source',
    });

    batchMap.value.moveLayer('deforestation-esri-layer', 'deforestation-forestCover-layer');
}

function updateNicfiLayer(newDate) {
    if (batchMap.value.getLayer("deforestation-planetnicfi-layer")) {
        batchMap.value.removeLayer("deforestation-planetnicfi-layer");
        batchMap.value.removeSource("deforestation-planetnicfi");
    }

    batchMap.value.addSource("deforestation-planetnicfi", {
        type: "raster",
        tiles: [
            `https://tiles0.planet.com/basemaps/v1/planet-tiles/planet_medres_visual_${newDate}_mosaic/gmap/{z}/{x}/{y}.png?api_key=PLAK107c2fcde4aa4352b285924b08f99a9c`
        ],
        tileSize: 256,
    });

    batchMap.value.addLayer({
        id: "deforestation-planetnicfi-layer",
        type: "raster",
        source: "deforestation-planetnicfi",
        layout: {
            'visibility': 'visible'
        }
    });

    batchMap.value.moveLayer('deforestation-planetnicfi-layer', 'eu-forest-layer');
}

function idleFunction() {
    isIdle.value = true;
}

const setIdle = debounce(idleFunction, 100);

function onMoveStart() {
    isIdle.value = false;
}

// Handler for when the user stops moving the batchMap
function onMoveEnd() {
    setIdle();
}

function handleKeyEvent(event) {
    if (event.key === "Escape") {
        resetChanges();
    }
    if (event.key === 'Backspace') {
        // Check if the current mode is `direct_select`
        if (draw.getMode() === 'direct_select') {
            draw.trash(); // Deletes the selected vertex in `direct_select` mode
        }
    }
}

function editPlot(feature) {
    plotStore.currentGeometryMode = GeometryMode.EDITING;
    plotStore.isEditPlotActivated = true;
    plotStore.originalFeature = feature;
    draw.deleteAll();
    draw.add({
        type: "Feature",
        id: feature.properties.plotObjectId,
        geometry: feature.geometry,
    });

    draw.changeMode('simple_select', { featureIds: [feature.properties.plotObjectId] });
}

function addPopup(properties, lngLat) {
    console.log(properties);

    const parsedVerification = JSON.parse(properties.verification);
    const parsedDeforestation = JSON.parse(properties.deforestation);

    // Extract labels for verification and deforestation
    const verificationText = parsedVerification
        .map(item => item.label)
        .join(', ') || 'Low Risk';

    const deforestationText = parsedDeforestation
        .map(item => item.label)
        .join(', ') || 'No Deforestation Risk';

    const commodity = properties.commodity || 'N/A';
    const producerName = properties.producerName || 'N/A';
    const plotId = properties.plotId || 'N/A';
    const area = properties.area?.toFixed(2);

    const popupContent = document.createElement('div');
    popupContent.innerHTML = `
    <div class="marker-info">
        <div class="tabs">
            <div class="tab-button active" onclick="showTab(event, 'info')">Info</div>
            <div class="tab-button" onclick="showTab(event, 'verification')">Plot Risks</div>
        </div>
        <div id="info" class="tab-content active">
            <table class="metadata">
                <tbody>
                    <tr><td><strong>Plot ID</strong></td><td>${plotId}</td></tr>
                    <tr><td><strong>Area (ha)</strong></td><td>${area}</td></tr>
                    <tr><td><strong>Commodity</strong></td><td>${commodity}</td></tr>
                    <tr><td><strong>Producer Name</strong></td><td>${producerName}</td></tr>
                </tbody>
            </table>
            <div style="text-align: right; margin-top: 10px;">
                <button class="edit-plot-btn" style="background-color: black; color: white; font-weight: 500; padding: 0.5rem 1rem; border: none; border-radius: 4px; cursor: pointer;">
                    Edit Plot
                </button>
            </div>
        </div>
        <div id="verification" class="tab-content">
            <table class="metadata">
                <tbody>
                    <tr>
                        <td><strong>Verification</strong></td>
                        <td>${verificationText}</td>
                    </tr>
                    <tr>
                        <td><strong>Deforestation</strong></td>
                        <td>${deforestationText}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    `;

    // Create and show the Mapbox popup
    const popup = new mapboxgl.Popup({ closeButton: false, offset: [0, -38] })  // Set the offset
        .setLngLat(lngLat)
        .setDOMContent(popupContent)
        .addTo(batchMap.value);

    // Add event listener for the "Edit Plot" button
    popupContent.querySelector('.edit-plot-btn').addEventListener('click', async () => {
        popupContent.querySelector('.edit-plot-btn').innerHTML = "Loading...";
        popupContent.querySelector('.edit-plot-btn').disabled = true;

        try {
            const response = await getPlot(properties.plotObjectId);
            const feature = {
                geometry: response.geometry,
                properties: properties
            };
            editPlot(feature);
            popup.remove(); // Close the popup after editing
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);
            toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch plot details.', life: 3000 });
            // Revert button to original state
            popupContent.querySelector('.edit-plot-btn').innerHTML = "Edit Plot";
            popupContent.querySelector('.edit-plot-btn').disabled = false;
        }
    });

    // Helper function to handle tab switching
    window.showTab = function (event, tabId) {
        const container = event.target.closest('.marker-info');
        const tabs = container.querySelectorAll('.tab-content');
        const buttons = container.querySelectorAll('.tab-button');

        tabs.forEach(tab => tab.classList.remove('active'));
        buttons.forEach(button => button.classList.remove('active'));

        container.querySelector(`#${tabId}`).classList.add('active');
        event.target.classList.add('active');
    };
}


function drawPolygon() {
    plotStore.currentGeometryMode = GeometryMode.DRAWING;
    draw.changeMode('draw_polygon');
}

function resetChanges() {
    plotStore.currentGeometryMode = GeometryMode.NONE;
    plotStore.isEditPlotActivated = false;
    draw.changeMode('simple_select');
    draw.deleteAll();
}

function showReplaceGeometryDialog() {
    isReplaceGeometryDialogShown.value = true;
}

function closeReplaceGeometryDialog() {
    isReplaceGeometryDialogShown.value = false;
}

async function pollJobStatus(jobId) {
    const pollInterval = 3000;

    return new Promise((resolve, reject) => {
        const checkStatus = async () => {
            const response = await getPollStatus(jobId);
            console.log(response)
            if (response.job_status !== "complete" && response.job_status !== "error") {
                console.log("Job still in progress...");
                setTimeout(checkStatus, pollInterval);
            } else if (response.job_status === "complete" || response.job_status === "error") {
                console.log("Job completed:", response.result);
                resolve(response);
            }
        };

        checkStatus();
    });
}

async function replaceGeometryAndStartVerification(payload) {
    try {
        isReplaceGeometryLoading.value = true;
        const response = await replaceGeometryAndRestartRiskDetection(payload);
        const job = await pollJobStatus(response.job_id);
        if (job.result === "success") {
            closeReplaceGeometryDialog();
            plotStore.showRiskDetectionSuccessDialog();
            await refreshData();
            resetChanges();
        } else { // result === FAILURE
            plotStore.showRiskDetectionFailureDialog();
        }
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch plot details.', life: 3000 });
    } finally {
        isReplaceGeometryLoading.value = false;
    }
}

async function replaceGeometry() {
    let dataToSend;

    if (plotStore.currentGeometryMode === GeometryMode.EDITING) {
        const drawnFeatures = draw.getAll(); // Get all features from the drawing tool
        const editedFeature = drawnFeatures.features[0];
        dataToSend = {
            geometry: editedFeature.geometry,
            plotObjectId: editedFeature.id
        }
    } else if (plotStore.currentGeometryMode === GeometryMode.DRAWING) {
        dataToSend = {
            geometry: lastDrawnFeature.value.geometry,
            plotObjectId: plotStore.originalFeature.properties.plotObjectId
        }
    } else {
        throw new Error(`Wrong currentGeometryMode: ${plotStore.currentGeometryMode}`)
    }

    draw.changeMode('simple_select');
    await replaceGeometryAndStartVerification(dataToSend);
}

function mapAddSources() {
    batchMap.value.addSource('plots-source', {
        type: 'vector',
        tiles: [`${VITE_EUDR_BACKEND}/plots/batch/${batchObjectId}/{z}/{x}/{y}.pbf`],
        minzoom: 0,
        maxzoom: 20
    });
    batchMap.value.addSource('eu-forest-source', {
        'type': 'raster',
        'tiles': [
            'https://ies-ows.jrc.ec.europa.eu/iforce/gfc2020/wms.py?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.3.0&request=GetMap&crs=EPSG:3857&transparent=true&width=512&height=512&layers=gfc2020_v1'
        ],
        'tileSize': 512
    });
    batchMap.value.addSource("nadar-deforestation-source", {
        type: "raster",
        tiles: [`${VITE_ANALYSIS_BACKEND}/tiles/${usersStore.currentUser.organization}/deforestation/{z}/{x}/{y}.png`],
        tileSize: 512,
        // minzoom: 0,
        maxzoom: 18,
    });
    batchMap.value.addSource("nadar-forest-source", {
        type: "raster",
        tiles: [`${VITE_ANALYSIS_BACKEND}/tiles/${usersStore.currentUser.organization}/forestCover/{z}/{x}/{y}.png`],
        tileSize: 512,
        // minzoom: 0,
        maxzoom: 18,
    });
    batchMap.value.addSource("eu-deforestation-2021-source", {
        type: "raster",
        tiles: [`${VITE_ANALYSIS_BACKEND}/tiles/global/deforestation/2021/{z}/{x}/{y}.png`],
        tileSize: 512,
        minzoom: 2,
        maxzoom: 16,
    });
    batchMap.value.addSource("eu-deforestation-2022-source", {
        type: "raster",
        tiles: [`${VITE_ANALYSIS_BACKEND}/tiles/global/deforestation/2022/{z}/{x}/{y}.png`],
        tileSize: 512,
        minzoom: 2,
        maxzoom: 16,
    });
    batchMap.value.addSource("eu-deforestation-2023-source", {
        type: "raster",
        tiles: [`${VITE_ANALYSIS_BACKEND}/tiles/global/deforestation/2023/{z}/{x}/{y}.png`],
        tileSize: 512,
        minzoom: 2,
        maxzoom: 16,
    });
}

// The first layer added here is the last one in the batchMap
function mapAddLayers() {
    batchMap.value.addLayer(
        {
            'id': 'eu-forest-layer',
            'type': 'raster',
            'source': 'eu-forest-source',
            'paint': {
                'raster-opacity': 0.5,
                "raster-color": "#029C54"
            },
            layout: {
                'visibility': 'visible'
            }
        }
    );
    batchMap.value.addLayer({
        "id": "eu-deforestation-2021-layer",
        "type": "raster",
        "source": "eu-deforestation-2021-source",
        'paint': {
            'raster-opacity': 0.5,
            "raster-color": "#DB0505"
        },
        layout: {
            'visibility': 'visible'
        }
    });
    batchMap.value.addLayer({
        "id": "eu-deforestation-2022-layer",
        "type": "raster",
        "source": "eu-deforestation-2022-source",
        'paint': {
            'raster-opacity': 0.5,
            "raster-color": "#DB0505"
        },
        "layout": {
            'visibility': 'visible'
        }
    });
    batchMap.value.addLayer({
        "id": "eu-deforestation-2023-layer",
        "type": "raster",
        "source": "eu-deforestation-2023-source",
        'paint': {
            'raster-opacity': 0.5,
            "raster-color": "#DB0505"
        },
        "layout": {
            'visibility': 'visible'
        }
    });
    batchMap.value.addLayer({
        "id": "nadar-forest-layer",
        "type": "raster",
        "source": "nadar-forest-source",
        'paint': {
            'raster-opacity': 0.9,
            "raster-color": "#62FF74"
        },
        "layout": {
            'visibility': 'visible'
        },
    });
    batchMap.value.addLayer({
        "id": "nadar-deforestation-layer",
        "type": "raster",
        "source": "nadar-deforestation-source",
        'paint': {
            'raster-opacity': 0.9,
            "raster-color": "#FF4400"
        },
        "layout": {
            'visibility': 'visible'
        }
    });

    batchMap.value.addLayer({
        'id': 'plots-lines-fill-layer',
        'type': 'fill',
        'source': 'plots-source',
        'source-layer': 'batch-plots',
        'paint': {
            'fill-color': 'rgba(0, 0, 0, 0)'  // completely transparent
        },
        'filter': ['==', '$type', 'Polygon']
    });
    batchMap.value.addLayer({
        'id': 'plots-points-layer',
        'type': 'circle',
        'source': 'plots-source',
        'source-layer': 'batch-plots',
        'filter': ['==', '$type', 'Point'],
        'paint': {
            'circle-radius': 4,
            'circle-color': [
                'match',
                ['get', 'plotColor'],
                'green', '#00FF00',           // Green for passed
                'red', '#FF0000',   // Red for critical_failed
                'orange', '#FFA500',    // Orange for further_failed
                '#000000'                       // Default color if no match
            ]
        },
    });
    batchMap.value.addLayer({
        'id': 'plots-lines-layer',
        'type': 'line',
        'source': 'plots-source',
        'source-layer': 'batch-plots',
        'filter': ['==', '$type', 'Polygon'], // Only include Polygon features
        'paint': {
            'line-color': [
                'match',
                ['get', 'plotColor'],
                'green', '#00FF00',           // Green for passed
                'red', '#FF0000',   // Red for critical_failed
                'orange', '#FFA500',    // Orange for further_failed
                '#000000'                       // Default color if no match
            ],
            'line-width': 3
        }
    });
}

onMounted(() => {
    batchMap.value = new mapboxgl.Map({
        container: 'batchMap',
        style: 'mapbox://styles/mapbox/satellite-streets-v12?optimize=true',
        projection: 'globe',
        zoom: 2,
        center: [-51.5868, -11.9021],
        attributionControl: false,
        transformRequest: (url, resourceType) => {
            if ((resourceType === 'Tile' || resourceType === 'Source') && url.includes(`${VITE_EUDR_BACKEND}/plots/batch`)) {
                try {
                    const parsedUrl = new URL(url);
                    const pathSegments = parsedUrl.pathname.split('/');
                    const zoomLevelIndex = pathSegments[4]; // Index of the zoom level segment
                    const zoomLevel = parseInt(pathSegments[zoomLevelIndex], 10);
                    // Block tile requests only outside zoom levels below 10
                    if (zoomLevel < 10) {
                        return {
                            url: 'data:application/octet-stream;base64,', // Empty data URI to block requests
                            headers: {},
                            credentials: 'include',
                        };
                    }
                } catch (error) {
                    Sentry.captureException(error);
                    console.error('Error parsing URL:', error);
                }
            }
            if (url.startsWith("https://tiles.planet.com")) {
                // If the URL is from Planet, don't include credentials
                return {
                    url: url
                };
            }
            if (url.startsWith(VITE_EUDR_BACKEND) || url.startsWith(VITE_ANALYSIS_BACKEND)) {
                return {
                    url: url,
                    credentials: 'include'  // Include cookies for cross-origin requests
                };
            }
        }
    });

    batchMap.value.addControl(new mapboxgl.FullscreenControl());
    batchMap.value.addControl(new mapboxgl.NavigationControl(), 'top-right');
    batchMap.value.addControl(draw);
    batchMap.value.on('movestart', onMoveStart);
    batchMap.value.on('moveend', onMoveEnd);

    batchMap.value.on("load", () => {
        mapAddSources();
        mapAddLayers();
    })

    batchMap.value.on('mouseenter', 'plots-points-layer', () => {
        batchMap.value.getCanvas().style.cursor = 'pointer';
    });

    batchMap.value.on('mouseleave', 'plots-points-layer', () => {
        batchMap.value.getCanvas().style.cursor = '';
    });

    batchMap.value.on('mouseenter', 'plots-lines-fill-layer', () => {
        batchMap.value.getCanvas().style.cursor = 'pointer';
    });

    batchMap.value.on('mouseleave', 'plots-lines-fill-layer', () => {
        batchMap.value.getCanvas().style.cursor = '';
    });

    batchMap.value.on('draw.update', (event) => {
        console.log("draw.update")
        lastDrawnFeature.value = event.features[0];
    });

    batchMap.value.on("draw.create", async (event) => {
        console.log("draw.create")
        draw.deleteAll();
        lastDrawnFeature.value = event.features[0];
        draw.add(event.features[0]);
    });

    batchMap.value.on('click', 'plots-lines-fill-layer', (e) => {
        const features = batchMap.value.queryRenderedFeatures(e.point);
        const properties = features[0].properties;
        console.log(properties)
        const lngLat = e.lngLat;

        if (!plotStore.isEditPlotActivated) {
            addPopup(properties, lngLat);
        }
    });

    batchMap.value.on('click', 'plots-points-layer', (e) => {
        const features = batchMap.value.queryRenderedFeatures(e.point);
        const properties = features[0].properties;
        const lngLat = e.lngLat;

        // Show popup immediately without waiting for data fetch
        if (!plotStore.isEditPlotActivated) {
            addPopup(properties, lngLat);
        }
    });

    batchMap.value.on('style.load', () => {
        batchMap.value.setFog({
            "range": [0.8, 8],
            "color": "#090e21",
            "horizon-blend": 0,
            "high-color": "#245bde",
            "space-color": "#000000",
            "star-intensity": 0.15
        }); // Set the default atmosphere style
    });
})

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeyEvent);
    batchMap.value.remove();
})
</script>


<style scoped>
#batchMap {
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.overlay-legend {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;
}

.overlay-satelliteImages {
    position: absolute;
    top: 10px;
    right: 50px;
    z-index: 1000;
}

:deep(.mapboxgl-ctrl-logo) {
    position: absolute;
    bottom: 0px;
    left: 80px;
}


.draw-polygon-icon {
    position: absolute;
    top: 150px;
    right: 10px;
    z-index: 1000;
    background-color: white;
    border-style: solid;
    border-color: rgb(219, 219, 219);
    border-width: 1px;
    cursor: pointer;
}

.draw-point-icon {
    position: absolute;
    top: 175px;
    right: 10px;
    z-index: 1000;
    background-color: white;
    border-style: solid;
    border-color: rgb(219, 219, 219);
    border-width: 1px;
    cursor: pointer;
}

.save-edit-plots-card {
    position: absolute;
    top: 190px;
    right: 10px;
    z-index: 1000;
    background-color: white;
    border-style: solid;
    border-color: rgb(219, 219, 219);
    border-width: 1px;
}

.plot-details-card {
    width: 330px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;
}

.save-button {
    height: 25px;
}

:deep(.marker-info) {
    font-family: Arial, sans-serif;
    font-size: 10px;
    width: 200px;
}

:deep(.metadata) {
    border-collapse: collapse;
    width: 100%;
}

:deep(.metadata td) {
    padding: 3px;
    border: 1px solid #ddd;
}

:deep(.metadata tr:nth-child(even)) {
    background-color: #f9f9f9;
}

:deep(.metadata tr:hover) {
    background-color: #f1f1f1;
}

:deep(.tabs) {
    display: flex;
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;
}

:deep(.tab-button) {
    flex: 1;
    padding: 2px 4px;
    font-family: Arial, sans-serif;
    font-size: 12px;
    color: #333;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    border-bottom: none;
    /* Hide bottom border to create connected tab look */
    cursor: pointer;
    text-align: center;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    /* No rounded corners */
    white-space: nowrap;
    /* Prevents text wrapping */
    /* Hides overflow text */
    text-overflow: ellipsis;
    /* Adds ellipsis if the text overflows */
}

:deep(.tab-button.active) {
    background-color: #ffffff;
    /* White for the active tab */
    border-bottom: 1px solid #ffffff;
    /* Seamlessly connect with content */
    font-weight: bold;
}

:deep(.tab-content) {
    display: none;
    padding: 3px;
    border: 1px solid #ddd;
    border-top: none;
    /* Avoid double border with tabs */
}

:deep(.tab-content.active) {
    display: block;
}
</style>
