<template>
  <div class="table-container">
    <div class="flex justify-content-between">
      <div class="flex align-items-center">
        <span class="font-medium text-3xl pr-2">Data Import</span>
        <!--
                <i class="pi pi-info-circle cursor-pointer" v-tooltip.bottom="'In this page you can upload your plots'"></i>
        -->
      </div>
      <div>
        <Button icon="pi pi-sync" class="mr-1" @click="refreshFilesTable"
          :loading="dataImportStore.isTableFilesLoading" />
        <Button label="Import Data" @click="dataImportStore.showDataImportDialog()" />
      </div>
    </div>
    <div class="mt-3">
      <FilesTable />
    </div>
  </div>

  <DataImportStepper />
  <DataImportSuccessDialog />
  <DataImportFailureDialog />
  <InvalidFileDialog />
  <InvalidGeometryDialog />
  <FileProcessingDialog />
  <InvalidAreaCellDialog />
</template>

<script setup>
import { onMounted } from "vue";
import { useToast } from "primevue/usetoast";
import FilesTable from "../components/import/FilesTable.vue";
import DataImportSuccessDialog from "../components/import/DataImportSuccessDialog.vue";
import DataImportStepper from "../components/import/DataImportStepper.vue";
import { useDataImportStore } from "../stores/dataImport";
import { useAnalyticsAPI } from "../composables/useAnalyticsAPI";
import DataImportFailureDialog from "../components/import/DataImportFailureDialog.vue";
import InvalidFileDialog from "../components/import/InvalidFileDialog.vue";
import InvalidGeometryDialog from "../components/import/InvalidGeometryDialog.vue";
import FileProcessingDialog from "../components/import/FileProcessingDialog.vue";
import InvalidAreaCellDialog from "../components/import/InvalidAreaCellDialog.vue";
import * as Sentry from "@sentry/vue";


const { getAllFiles } = useAnalyticsAPI();
const dataImportStore = useDataImportStore();
const toast = useToast();


async function refreshFilesTable() {
  dataImportStore.isTableFilesLoading = true;
  dataImportStore.tableFiles = [];

  try {
    dataImportStore.tableFiles = await getAllFiles();
  } catch (error) {
    Sentry.captureException(error);
    console.log(error)
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to fetch files. Please try again or contact support.',
      life: 3000
    });
  } finally {
    dataImportStore.isTableFilesLoading = false;
  }
}


onMounted(() => {
  dataImportStore.$reset();
})
</script>

<style scoped>
.table-container {
  flex: 1;
  overflow: auto;
  padding: 1rem;
  box-sizing: border-box;
}
</style>