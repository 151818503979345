<template>
    <IconField iconPosition="right" class="location-search-bar">
        <InputIcon class="pi pi-search" @click="handleSearch" style="cursor: pointer; color: black;"> </InputIcon>
        <InputText v-model="searchQuery" placeholder="Search" @keyup.enter="handleSearch" class="custom-height"
            style="width: 290px;" />
    </IconField>
</template>

<script setup>
import { ref } from 'vue';
import { useMappingStore } from '../../stores/mapping';
import * as Sentry from "@sentry/vue";
import { useMappingUtils } from './composables/useMappingUtils';
import { useToast } from 'primevue/usetoast';
import { useDataFormatUtils } from '../../composables/useDataFormatUtils';


const VITE_MAPBOX3_ACCESS_TOKEN = import.meta.env.VITE_MAPBOX3_ACCESS_TOKEN;
const mappingStore = useMappingStore();
const toast = useToast();
const { zoomToLocationAndSetMarker, closeAllCardsAndDeactivateGeometryModes } = useMappingUtils();
const { toDMS } = useDataFormatUtils();
const searchQuery = ref("");


// Function to call the Geocoding API (replace with actual implementation)
async function fetchGeocode(query) {
    // Example URL, replace with actual API endpoint and add your API key
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        query
    )}.json?access_token=${VITE_MAPBOX3_ACCESS_TOKEN}`;
    const response = await fetch(url);
    return await response.json();
}

// Function to call the Reverse Geocoding API
async function fetchReverseGeocode(lat, lng) {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${VITE_MAPBOX3_ACCESS_TOKEN}`;
    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}

async function reverseGeocodeData(lng, lat) {
    if (lat !== undefined && lng !== undefined) {
        // Reverse Geocoding for coordinates input
        try {
            const reverseGeocodingData = await fetchReverseGeocode(lat, lng);
            if (reverseGeocodingData && reverseGeocodingData.features.length > 0) {
                const location = reverseGeocodingData.features[0].place_name;
                return location;
            } else {
                // Handle no results found
                toast.add({ severity: 'info', summary: 'Search Result', detail: 'No results found for the coordinates.', life: 3000 });
                return;
            }
        } catch (error) {
            throw error;
        }
    }
}

function dmsToDecimal(parts) {
    if (!parts) return NaN;

    let [degrees, minutes, seconds, direction] = parts;
    let decimal = Number(degrees) + Number(minutes) / 60 + Number(seconds) / 3600;

    if (direction === "S" || direction === "W") {
        decimal = -decimal;
    }

    return decimal;
}

async function handleSearch() {
    closeAllCardsAndDeactivateGeometryModes();
    if (!searchQuery.value) return;

    let normalizedInput = searchQuery.value.replace(/\s/g, '');
    let lat, lng, location;

    // Check if input is in DMS format
    const dmsCombinedRegex = /((\d+)°(\d+)'((?:\d+)(?:\.\d+)?)")([NSEW])\s*((\d+)°(\d+)'((?:\d+)(?:\.\d+)?)")([EW])/;

    let dmsMatch = normalizedInput.match(dmsCombinedRegex);
    let decimalMatch = normalizedInput.match(/(\d+\.\d+)\s*(\d+\.\d+)/);
    if (dmsMatch) {
        lat = dmsToDecimal(dmsMatch.slice(2, 6));
        lng = dmsToDecimal(dmsMatch.slice(7));
    } else if (decimalMatch) {
        [lat, lng] = decimalMatch.slice(1).map(Number);
    } else {
        // Try to parse as decimal coordinates with comma
        let coords = normalizedInput.split(",").map(Number).filter(n => !isNaN(n));
        if (coords.length === 2) {
            [lat, lng] = coords;
        } else {
            // Handle as a place name search
            try {
                const geocodingData = await fetchGeocode(searchQuery.value);
                if (geocodingData && geocodingData.features.length > 0) {
                    const { center } = geocodingData.features[0];
                    lat = center[1];
                    lng = center[0];
                    location = geocodingData.features[0].place_name;
                } else {
                    // Handle no results found
                    toast.add({ severity: 'info', summary: 'Search Result', detail: 'No results found for the search query.', life: 8000 });
                    return;
                }
            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
                return;
            }
        }
    }

    try {
        const isValidLat = lat >= -90 && lat <= 90;
        const isValidLng = lng >= -180 && lng <= 180;

        if (!isValidLat || !isValidLng) {
            // Notify the user about the invalid input
            toast.add({ severity: 'error', summary: 'Invalid Coordinates', detail: 'The provided coordinates are not valid. Latitude must be between -90 and 90, and longitude must be between -180 and 180.', life: 3000 });
            return; // Stop further processing
        }
        location = await reverseGeocodeData(lng, lat);
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        toast.add({ severity: 'error', summary: 'Invalid Coordinates', detail: 'Could not search location.', life: 3000 });
        return;
    }

    // Update mousePosition state
    mappingStore.mousePosition = {
        latDecimal: lat.toFixed(8),
        lngDecimal: lng.toFixed(8),
        latDMS: toDMS(lat, false),
        lngDMS: toDMS(lng, true),
        location: location
    };

    mappingStore.isSearchResultCardShown = true;

    zoomToLocationAndSetMarker([lng, lat]);
}
</script>

<style scoped>
.search-icon {
    position: absolute;
    top: 10px;
    left: 210px;
    width: 235px;
    z-index: 10000;
}

.custom-height {
    height: 35px;
}

.location-search-bar {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
}
</style>