<template>
    <div class="page-container">
        <span class="font-medium text-3xl">Mapping</span>
        <div class="content-section mt-3">
            <div class="table-container">
                <MappingTable />
            </div>
            <div class="map-container">
                <MappingMap />
            </div>
        </div>
    </div>

    <RiskDetectionSuccessDialog />
    <RiskDetectionFailureDialog />
</template>

<script setup>
import { ref, provide, onUnmounted } from "vue";
import MappingMap from "../components/mapping/MappingMap.vue";
import MappingTable from "../components/mapping/MappingTable.vue";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useMappingStore } from "../stores/mapping";
import RiskDetectionSuccessDialog from "../components/mapping/RiskDetectionSuccessDialog.vue";
import RiskDetectionFailureDialog from "../components/mapping/RiskDetectionFailureDialog.vue";


const mappingStore = useMappingStore();
let map = ref();
const draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
        polygon: false,
        trash: false,
    }
});

provide("draw", draw);
provide("map", map);

onUnmounted(() => {
    mappingStore.$reset()
})
</script>

<style scoped>
.page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding: 1rem;
    /* Full viewport height */
    overflow: hidden;
    /* Prevents scroll outside the containers */
}

.content-section {
    display: flex;
    flex: 1;
    /* Takes up all space after accounting for header */
    overflow: hidden;
    /* Ensures no internal scroll affects layout */
}

.table-container {
    flex: 7;
    /* Adjust this value to change width relative to map-container */
    overflow: auto;
    /* Allows scrolling within the container if content overflows */
    box-sizing: border-box;
    padding-right: 1rem;
}

.map-container {
    flex: 5;
    /* Adjust this value to change width relative to table-container */
    overflow: hidden;
    /* Map typically should not scroll */
    box-sizing: border-box;
}
</style>