<template>
    <div class="privacy-policy">
        <h1>Privacy Policy for Nadar GmbH's Web Application</h1>
        <p>This Privacy Policy describes how Nadar GmbH ("we", "our", "us") collects, uses, shares, and protects user
            information obtained through the web application hosted on www.eudr-solution.com.</p>

        <h2>1. Personal Data We Collect</h2>
        <p>We collect the following personal data from users:</p>
        <ul>
            <li>Identification information: such as your name and email address.</li>
            <li>User-generated content: such as posts, comments, and other content you share.</li>
            <li>Usage data: such as your IP address, browser type, and information about your interactions with our web
                application.</li>
        </ul>

        <h2>2. Why We Process Your Data</h2>
        <p>We process your data for the following purposes:</p>
        <ul>
            <li>To provide and manage your account on our web application.</li>
            <li>To display your user-generated content.</li>
            <li>To improve and analyze the usage of our web application.</li>
            <li>To communicate with you about your account and respond to inquiries.</li>
            <li>To send marketing communications, if you have given your consent.</li>
        </ul>

        <h2>3. Legal Basis for Processing</h2>
        <p>Our legal basis for collecting and using the personal data described above depends on the personal data
            concerned
            and the specific context in which we collect it:</p>
        <ul>
            <li>We process your Identification information and User-generated content for the performance of a contract
                with
                you.</li>
            <li>We process your Usage data for our legitimate interests in understanding how our web application is
                being
                used, to improve it, and to send marketing communications, we rely on your consent.</li>
        </ul>

        <h2>4. Data Storage</h2>
        <p>Your personal data is stored on secure, encrypted servers located within the European Economic Area (EEA). We
            retain your data for as long as your account is active, or as needed to provide you with our services. We
            may
            keep some data for longer if it is required for legal reasons or to resolve disputes.</p>

        <h2>5. Sharing and Disclosure of Your Personal Data</h2>
        <p>We do not share your personal data with any third parties, except for trusted service providers who assist us
            with services such as email delivery or hosting. These providers are vetted for GDPR compliance.</p>

        <h2>6. International Data Transfers</h2>
        <p>We do not regularly transfer your personal data outside of the EEA. If such transfers occur, they are done
            under
            EU-approved standard contractual clauses.</p>

        <h2>7. Your Rights</h2>
        <p>Under GDPR, you have the right to access, correct, update, or request deletion of your personal data. You can
            exercise these rights by logging into your account or by contacting us at info@nadar.earth.</p>

        <h2>8. Data Security</h2>
        <p>We have implemented technical and organizational measures to protect your personal data from unauthorized
            access,
            use, or disclosure. This includes encryption of data at rest and in transit, and regular vulnerability
            scanning.
        </p>

        <h2>9. Data Breaches</h2>
        <p>In the event of a data breach, we will notify the relevant authorities within 72 hours and inform any
            affected
            users.</p>

        <h2>10. Data Protection Officer</h2>
        <p>We have appointed a Data Protection Officer (DPO). If you have any concerns about your privacy, please
            contact
            our DPO at info@nadar.earth.</p>
    </div>
</template>

<script setup>

</script>

<style scoped>
.privacy-policy {
    margin: 0 auto;
    max-width: 800px;
    padding: 2em;
}
</style>