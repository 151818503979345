import { createRouter, createWebHistory } from 'vue-router'
import { useDataAPI } from '../composables/useDataAPI';
import { useDeviceStore } from "../stores/device"
import { useUsersStore } from '../stores/users';
import { getAuth, verifyPasswordResetCode } from 'firebase/auth';
import LoginPage from '../pages/LoginPage.vue';
import AdminLoginPage from '../pages/AdminLoginPage.vue';
import ResetCredentialsPage from '../pages/ResetCredentialsPage.vue';
import DashboardLayout from '../layouts/DashboardLayout.vue';
import UserProfilePage from '../pages/UserProfilePage.vue';
import MappingPage from '../pages/MappingPage.vue';
import RiskAssessmentPage from '../pages/RiskAssessmentPage.vue';
import DueDiligencePage from '../pages/DueDiligencePage.vue';
import DataImportPage from '../pages/DataImportPage.vue';
import MapPage from '../pages/mobile/MapPage.vue';
import PlotsPage from '../pages/mobile/PlotsPage.vue';
import ProfilePage from '../pages/mobile/ProfilePage.vue';
import PrivacyPage from '../pages/PrivacyPage.vue';
import NotFoundPage from '../pages/404.vue';
import UserManagementPage from '../pages/UserManagementPage.vue';
import OrganizationPage from '../pages/OrganizationPage.vue';
import SuppliersPage from '../pages/SuppliersPage.vue';
import BatchesPage from '../pages/BatchesPage.vue';
import BatchPage from '../pages/BatchPage.vue';
import SettingsPage from '../pages/SettingsPage.vue';
import DueDiligenceDetails from '../components/dueDiligenceStatement/DueDiligenceDetails.vue';


function canAccess(permission) {
  return (to, from, next) => {
    const usersStore = useUsersStore();
    if (usersStore.hasPermission(permission)) {
      next(); // Permission is valid, proceed
    } else {
      next('/login'); // Permission is denied, redirect to login
    }
  };
}

function hasValidSessionCookie() {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith('session_eudr_nadar_expires_at='))
    ?.split('=')[1];

  if (cookieValue) {
    // Decode the URL-encoded cookie value
    const decodedValue = decodeURIComponent(cookieValue);
    const expirationTime = new Date(decodedValue).getTime();
    const currentTime = Date.now();
    return currentTime < expirationTime;
  }
  return false;
}

async function verifySessionGuard(to, from, next) {
  const usersStore = useUsersStore();
  const { postVerifyUser } = useDataAPI();

  if (navigator.onLine) {
    try {
      await postVerifyUser(); // Attempt to verify the user's session.
      await usersStore.fetchCurrentUser() // Fetch online and use offline
      // If the request succeeds, the user is considered authenticated.
      if (to.path === '/login') {
        // Authenticated users should not see the login page.
        next('/import');
        return;
      }
      next(); // For any other route, proceed as normal.
    } catch (error) {
      // The user is not authenticated.
      if (to.path !== '/login') {
        // If the target route is not /login, redirect to /login.
        next('/login');
      } else {
        // If the target route is /login, allow the user to proceed.
        next();
      }
    }
  } else {
    // User is offline, check if the session cookie is still valid
    if (hasValidSessionCookie()) {
      next(); // Session cookie is valid, proceed to the requested route
    } else {
      next("/login"); // Session cookie is invalid or not present, redirect to login
    }
  }
}

// Navigation guard for the /reset route
async function verifyResetCodeGuard(to, from, next) {
  const auth = getAuth();
  const oobCode = to.query.oobCode; // Get the oobCode from the URL query parameters
  const mode = to.query.mode;

  // Check if oobCode is present
  if (!oobCode && !mode) {
    next('/login'); // Redirect to login if no oobCode is provided
    return;
  }

  // Verify the oobCode
  try {
    if (mode === "resetPassword") {
      await verifyPasswordResetCode(auth, oobCode);
      next(); // oobCode is valid, proceed to the reset page
    } else if (mode === "recoverEmail") {
      next();
    } else {
      next('/login');
    }
  } catch (error) {
    console.error(error);
    // oobCode is invalid or expired, redirect to login or error page
    next('/login');
  }
}

// https://stackoverflow.com/questions/69300341/typeerror-failed-to-fetch-dynamically-imported-module-on-vue-vite-vanilla-set
const router = createRouter({
  history: createWebHistory(),
  strict: true,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      beforeEnter: [verifySessionGuard],
    },
    {
      path: '/cheesecake',
      name: 'impersonate',
      component: AdminLoginPage,
    },
    {
      path: '/reset',
      name: 'reset',
      component: ResetCredentialsPage,
      beforeEnter: verifyResetCodeGuard,
    },
    {
      path: '/',
      redirect: '/import',
      component: DashboardLayout,
      beforeEnter: [verifySessionGuard],
      children: [
        {
          path: 'suppliers',
          name: 'suppliers',
          component: SuppliersPage,
        },
        {
          path: 'batches',
          children: [
            {
              path: '',
              name: 'batches',
              component: BatchesPage,
            },
            {
              path: ':batchObjectId',
              name: 'batch',
              component: BatchPage,
            },
          ]
        },
        {
          path: 'duediligence',
          children: [
            {
              path: '',
              name: 'duediligence',
              component: DueDiligencePage,
            },
            {
              path: ':ddsIdentifier',
              name: 'duediligence-details',
              component: DueDiligenceDetails,
            },
          ]
        },
        {
          path: 'profile',
          name: 'profile',
          component: UserProfilePage,
        },
        {
          path: 'mapping',
          name: 'mapping',
          component: MappingPage,
        },
        {
          path: 'assessment',
          name: 'assessment',
          component: RiskAssessmentPage,
        },
        {
          path: 'import',
          name: 'import',
          component: DataImportPage,
        },
        {
          path: 'user-management',
          name: 'user-management',
          component: UserManagementPage,
          beforeEnter: [canAccess("view_user_management")],
        },
        {
          path: 'settings',
          name: 'settings',
          component: SettingsPage,
        },
        {
          path: 'organization',
          name: 'organization',
          component: OrganizationPage,
          beforeEnter: [canAccess("view_organization")],
        },
        {
          path: 'onboarding',
          redirect: '/map',
          children: [
            {
              path: 'map',
              name: 'onboarding-map',
              component: MapPage,
            },
            {
              path: 'plots',
              name: 'onboarding-plots',
              component: PlotsPage,
            },
            {
              path: 'profile',
              name: 'onboarding-profile',
              component: ProfilePage,
            },
          ]
        },
      ]
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: PrivacyPage,
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: NotFoundPage,
    },
  ]
});

router.beforeEach(async (to, from, next) => {
  const deviceStore = useDeviceStore();
  deviceStore.detectDevice();
  // Redirect mobile users accessing the root path to /onboarding/map
  if (deviceStore.device === 'mobile' && to.path === '/import') {
    next('/onboarding/map');
    return;
  }

  next();
});

export default router
