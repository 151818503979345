import { defineStore } from 'pinia'
import { FilterMatchMode } from 'primevue/api';
import { GeometryMode } from '../utils/constants';


const filters = {
    plotId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    country: { value: null, matchMode: FilterMatchMode.EQUALS },
    area: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    dataQuality: { value: null, matchMode: FilterMatchMode.IN },
    deforestation: { value: null, matchMode: FilterMatchMode.IN },
    legality: { value: null, matchMode: FilterMatchMode.IN },
    commodity: { value: null, matchMode: FilterMatchMode.EQUALS },
    additionalInfo: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    producerName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    supplier: { value: null, matchMode: FilterMatchMode.IN },
    treeSpecies: { value: null, matchMode: FilterMatchMode.CONTAINS }
}

export const usePlotStore = defineStore('plot', {
    state: () => ({
        plots: [],
        selectedRow: {},
        selectedPlotOnMap: {},
        // Pagination
        selectedCheckboxes: [],
        isCurrentPageSelected: false,
        isEveryPageSelected: false,
        selectedPages: [],
        pageParams: {},
        isTableLoading: false,
        totalRecords: 0,
        filters: { ...filters },
        isRiskDetectionSuccessDialogShown: false,
        isRiskDetectionFailureDialogShown: false,
        isEditPlotActivated: false,
        currentGeometryMode: GeometryMode.NONE,
        originalFeature: {},
    }),
    actions: {
        resetFilters() {
            this.filters = { ...filters }; // Reset filters to the default values
        },
        showRiskDetectionSuccessDialog() {
            this.isRiskDetectionSuccessDialogShown = true
        },
        showRiskDetectionFailureDialog() {
            this.isRiskDetectionFailureDialogShown = true
        },
    }
})