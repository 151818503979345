<template>
    <Card>
        <template #content>
            <DataTable :value="dueDiligenceStore.statements" v-model:selection="selectedStatement"
                selectionMode="single" @rowSelect="selectStatement">
                <template #empty>
                    <div v-if="!dueDiligenceStore.isStatementsLoading"
                        class="flex flex-column align-items-center justify-content-center select-none">
                        <span>No due diligence statements available for display.</span>
                        <small>Please create a new statement.</small>
                    </div>
                    <div v-else>
                        <ProgressBar mode="indeterminate" style="height:2px"></ProgressBar>
                    </div>
                </template>
                <Column field="referenceNumber" header="Reference number" headerStyle="white-space: nowrap;"></Column>
                <Column field="verificationNumber" header="Verification number" headerStyle="white-space: nowrap;">
                </Column>
                <Column field="ddsIdentifier" header="DDS identifier" headerStyle="white-space: nowrap;"></Column>
                <Column field="status" header="Status" headerStyle="white-space: nowrap;">
                    <template #body="{ data }">
                        <Tag :value="data.status" :severity="getSeverity(data.status)" />
                    </template>
                </Column>
                <Column field="createdByUser" header="Created by">
                    <template #body="{ data }">
                        <div class="flex flex-column">
                            <span class="font-medium">{{ data.createdByUser.firstName }} {{ data.createdByUser.lastName
                                }}</span>
                            <span class="text-sm pt-1">{{ data.createdByUser.email }}</span>
                        </div>
                    </template>
                </Column>
                <Column field="createdAt" header="Created at" headerStyle="white-space: nowrap;">
                    <template #body="{ data }">
                        <div class="flex flex-column">
                            <span>{{ formatDate(data.createdAt) }} </span>
                        </div>
                    </template>
                </Column>
                <Column field="" header="Actions" frozen alignFrozen="right">
                    <template #body="{ data }">
                        <Button icon="pi pi-times" outlined severity="danger" @click="showRetractDdsDialog(data)"
                            class="ml-1" />
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>

    <Dialog v-model:visible="dueDiligenceStore.isRetractDueDiligenceStatementShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column px-5 py-5 gap-4">
                <span class="text-lg font-medium">Retract statement?</span>
                <span>This will retract the selected due diligence statement.</span>
                <div class="flex justify-content-end">
                    <Button label="Cancel" severity="secondary" class="mr-3" @click="closeCallback"></Button>
                    <Button label="Retract" severity="danger" @click="handleRetractStatement"
                        :loading="isRetractLoading"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import { useDueDiligenceStore } from '../../stores/duediligence';
import { useDataFormatUtils } from '../../composables/useDataFormatUtils';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import * as Sentry from "@sentry/vue";


const { postRetractDueDiligenceStatement } = useAnalyticsAPI();
const { formatDate } = useDataFormatUtils();
const router = useRouter();
const toast = useToast();
const dueDiligenceStore = useDueDiligenceStore();
const statementToRetract = ref();
const isRetractLoading = ref(false);
const selectedStatement = ref();


async function handleRetractStatement() {
    const body = {
        ddsIdentifier: statementToRetract.value.ddsIdentifier
    }
    isRetractLoading.value = true;
    try {
        const reponse = await postRetractDueDiligenceStatement(body);
        closeRetractDdsDialog();
        dueDiligenceStore.fetchDueDiligenceStatements();
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        toast.add({ severity: 'error', summary: 'Error', detail: "Cannot retract due diligence statement.", life: 3000 });
    } finally {
        isRetractLoading.value = false;
    }
}

function showRetractDdsDialog(data) {
    if (data.status === "CANCELLED" || data.status === "WITHDRAWN") {
        toast.add({
            severity: 'info',
            summary: 'Statement Already Retracted',
            detail: "This due diligence statement has already been retracted. No further action is needed.",
            life: 3000
        });
        return
    }
    statementToRetract.value = data;
    dueDiligenceStore.isRetractDueDiligenceStatementShown = true;
}

function closeRetractDdsDialog() {
    dueDiligenceStore.isRetractDueDiligenceStatementShown = false;
}

function selectStatement(event) {
    const ddsIdentifier = event.data.ddsIdentifier
    router.push({ name: "duediligence-details", params: { ddsIdentifier } });
}

function getSeverity(status) {
    switch (status) {
        case 'SUBMITTED':
            return 'info';
        case 'WITHDRAWN':
            return 'info';
        case 'ARCHIVED':
            return 'info';
        case 'AVAILABLE':
            return 'success';
        case 'REJECTED':
            return 'danger';
        case 'CANCELLED':
            return 'warning';
    }
};

async function fetchStatements() {
    try {
        await dueDiligenceStore.fetchDueDiligenceStatements();
    } catch (error) {
        Sentry.captureException(error);
        console.log(error)
        toast.add({ severity: 'error', summary: 'Error', detail: "Cannot load due diligence statements.", life: 3000 });
    }
}

fetchStatements();
</script>