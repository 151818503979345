import * as turf from "@turf/turf";
import { countriesBoundaries } from '../utils/constants';
import pako from "pako";


export function useDataFormatUtils() {
    function toDMS(coord, isLng) {
        const absolute = Math.abs(coord);
        const degrees = Math.floor(absolute);
        const minutesNotTruncated = (absolute - degrees) * 60;
        const minutes = Math.floor(minutesNotTruncated);
        const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

        let direction = "";

        if (isLng) {
            direction = coord > 0 ? "E" : "W";
        } else {
            direction = coord > 0 ? "N" : "S";
        }

        return `${degrees}°${minutes}'${seconds}"${direction}`;
    }

    function formatFloat(area) {
        const float = Number(area);
        return float === 0 ? 0 : float.toFixed(3);
    }

    function calculateAreaInHectares(polygon) {
        const areaInSquareMeters = turf.area(polygon);
        const areaInHectares = areaInSquareMeters / 10000; // Convert to hectares
        return parseFloat(areaInHectares.toFixed(2)); // Limit to 2 decimal places and convert back to number
    }

    function generateTimestamp() {
        const now = new Date();

        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = String(now.getFullYear()).substr(-2);

        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        const milliseconds = String(now.getMilliseconds()).padStart(6, '0');

        return `${day}${month}${year}-${hours}${minutes}${seconds}-${milliseconds}`;
    }

    function generateRandom10DigitNumber() {
        const min = 1000000000; // Minimum 10-digit number
        const max = 9999999999; // Maximum 10-digit number
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function getCountryFromFeature(feature) {
        const representativePoint = getRepresentativePoint(feature);
        if (!representativePoint) return "Unknown";

        const point = turf.point(representativePoint);
        for (let countryFeature of countriesBoundaries.features) {
            if (turf.booleanPointInPolygon(point, countryFeature)) {
                return countryFeature.properties.NAME;
            }
        }
        return "Unknown";
    }

    function getRepresentativePoint(feature) {
        let point;

        switch (feature.geometry.type) {
            case 'Point':
                point = feature.geometry.coordinates;
                break;
            case 'LineString':
            case 'Polygon':
                point = turf.centroid(feature).geometry.coordinates;
                break;
            case 'MultiPoint':
            case 'MultiLineString':
            case 'MultiPolygon':
                point = turf.pointOnFeature(feature).geometry.coordinates;
                break;
            default:
                console.error("Unsupported geometry type:", feature.geometry.type);
                return null;
        }

        return point;
    }

    function isNumber(potentialNumber) {
        const string = String(potentialNumber); // Convert to string and trim
        const areaRegex = /^(\d+(\.\d+)?)?$/; // Checks for empty strings, positive integers and positive floats
        const areaMatch = areaRegex.test(string);

        console.log(string)
        return areaMatch;
    }

    async function compressFile(file) {
        // Read the file content
        const fileContent = await readFileAsText(file);
        // Compress the content using pako
        const compressedContent = pako.deflate(fileContent);
        // Convert the compressed content to a Blob
        const compressedBlob = new Blob([compressedContent], { type: 'application/octet-stream' });

        return compressedBlob
    }

    function compressJSON(jsonString) {
        return pako.deflate(jsonString, { to: 'string' });
    }

    function readFileAsText(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);
            reader.readAsText(file);
        });
    }

    function formatDate(timestamp) {
        const trimmedTimestamp = timestamp.split('.')[0] + 'Z';  // Ensures valid ISO format by keeping up to milliseconds and appending 'Z' for UTC
        const date = new Date(trimmedTimestamp);  // Assumes timestamp is in UTC
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };
        return new Intl.DateTimeFormat(undefined, options).format(date); // Uses the user's local timezone
    }

    return {
        toDMS,
        formatFloat,
        calculateAreaInHectares,
        generateTimestamp,
        generateRandom10DigitNumber,
        getCountryFromFeature,
        isNumber,
        compressFile,
        compressJSON,
        readFileAsText,
        formatDate
    }
}