import { inject } from 'vue';
import { useMappingStore } from '../../../stores/mapping';
import * as turf from '@turf/turf';
import { GeometryMode } from '../../../utils/constants';
import mapboxgl from "mapbox-gl";
import { usePagination } from './usePagination';
import { useDataAPI } from '../../../composables/useDataAPI';
import * as Sentry from "@sentry/vue";


export function useMappingUtils() {
    const map = inject("map");
    const draw = inject("draw");
    const mappingStore = useMappingStore();
    const { unselectAllCheckboxes } = usePagination();
    const { getMappingPlots } = useDataAPI();
    const VITE_EUDR_BACKEND = import.meta.env.VITE_EUDR_BACKEND;


    function removeMarker() {
        if (mappingStore.searchMarker) {
            mappingStore.searchMarker.remove();
            mappingStore.searchMarker = null;
        }
    }

    function closeAllCardsAndDeactivateGeometryModes() {
        mappingStore.currentGeometryMode = GeometryMode.NONE;
        mappingStore.isSearchResultCardShown = false;
        removeMarker();
        draw.deleteAll();
        draw.changeMode('simple_select');
        map.value.getCanvas().style.cursor = '';
    }

    function setLayerVisibility(layerId, visibility) {
        const visibilityState = visibility ? 'visible' : 'none';
        if (map.value.getLayer(layerId)) {
            map.value.setLayoutProperty(layerId, 'visibility', visibilityState);
        }
    }

    function hideLayersForEditing() {
        setLayerVisibility('polygons-layer-line', false);
        setLayerVisibility('polygons-layer-fill', false);
        setLayerVisibility('points-layer', false);
    }

    function showLayersAfterEditing() {
        setLayerVisibility('polygons-layer-line', true);
        setLayerVisibility('polygons-layer-fill', true);
        setLayerVisibility('points-layer', true);
    }

    function flyToPlotAndHighlightIt(feature) {
        resetPlotStyles();
        const type = feature.geometry.type;

        if (type === 'Point') {
            //changePointColor(feature.properties.featureId);
            map.value.flyTo({
                center: feature.geometry.coordinates,
                zoom: 15,
                essential: true
            });
        }
        else {
            //changePolygonColor(feature.properties.featureId);
            const bbox = turf.bbox(feature.geometry);
            map.value.fitBounds(bbox, {
                padding: { top: 100, bottom: 100, left: 100, right: 100 },
                maxZoom: 17,
                duration: 5000,
                essential: true
            });
        }
    }

    function zoomToLocationAndSetMarker(coords) {
        // If a marker already exists, remove it
        removeMarker();

        // Create a new marker and add it to the map
        mappingStore.searchMarker = new mapboxgl.Marker().setLngLat(coords).addTo(map.value);

        // Zoom to the location
        map.value.flyTo({
            center: coords,
            zoom: 15, // Adjust zoom level as needed
            essential: true,
            duration: 5000
        });
    }

    function resetPlotStyles() {
        // Reset the style of polygons
        const polygonLayerId = 'polygons-layer-line'; // Replace with your actual layer ID for polygons
        map.value.setPaintProperty(polygonLayerId, 'line-color', ' #FF69B4');

        // Reset the style of points
        const pointLayerId = 'points-layer'; // Replace with your actual layer ID for points
        map.value.setPaintProperty(pointLayerId, 'circle-color', ' #FF69B4'); // Default color
    }

    function changePolygonColor(id) {
        // Updates the color of a polygon based on the id
        map.value.setPaintProperty('polygons-layer-line', 'line-color', [
            'match',
            ['get', 'featureId'],
            id, '#27dfdf',  // Change the color of the matched plot
            '#FF69B4'  // Default color
        ]);
    }
    function changePointColor(id) {
        // Updates the color of a polygon based on the id
        map.value.setPaintProperty('points-layer', 'circle-color', [
            'match',
            ['get', 'featureId'],
            id, '#27dfdf',  // Change the color of the matched plot
            '#FF69B4'  // Default color
        ]);
    }

    async function refreshData() {
        try {
            unselectAllCheckboxes();
            mappingStore.currentGeometryMode = GeometryMode.NONE;
            mappingStore.isEditPlotsCardShown = false;
            mappingStore.isTableLoading = true;
            mappingStore.mappingPlots = [];
            mappingStore.lastDrawnFeature = {};
            const response = await getMappingPlots();
            mappingStore.mappingPlots = response;
            draw.deleteAll();
            map.value.getSource('mapping-plots-source').setTiles([`${VITE_EUDR_BACKEND}/plots/mapping/{z}/{x}/{y}.pbf`]);
        } catch (error) {
            Sentry.captureException(error);
            console.log(error);
        } finally {
            mappingStore.isTableLoading = false;
        }
    }

    return {
        hideLayersForEditing,
        showLayersAfterEditing,
        flyToPlotAndHighlightIt,
        zoomToLocationAndSetMarker,
        refreshData,
        closeAllCardsAndDeactivateGeometryModes,
    }
}
