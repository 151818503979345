<template>
    <span class="font-medium text-2xl">Organization</span>

    <Card class="mt-3">
        <template #content>
            <div class="grid">
                <div class="col-12">
                    <div class="flex flex-column gap-2 mt-2">
                        <label class="font-medium">Organization name</label>
                        <InputText v-model="organizationStore.organization.organizationName" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="flex flex-column gap-2 mt-2">
                        <label class="font-medium">Street and number</label>
                        <InputText v-model="organizationStore.organization.street" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="flex flex-column gap-2 mt-2">
                        <label class="font-medium">Postal code</label>
                        <InputText v-model="organizationStore.organization.postalCode" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="flex flex-column gap-2 mt-2">
                        <label class="font-medium">City</label>
                        <InputText v-model="organizationStore.organization.city" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="flex flex-column gap-2 mt-2">
                        <label class="font-medium">Country</label>
                        <Dropdown v-model="organizationStore.organization.country" :options="countries" class="w-full"
                            filter />
                    </div>
                </div>
                <div class="col-12">
                    <div class="flex flex-column gap-2 mt-2">
                        <label class="font-medium">EORI number</label>
                        <InputText v-model="organizationStore.organization.eori" />
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <span class="font-medium text-lg">Representative of the organization</span>
                </div>
                <div class="col-6">
                    <div class="flex flex-column gap-2">
                        <label class="font-medium">First name</label>
                        <InputText v-model="organizationStore.organization.firstName" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="flex flex-column gap-2">
                        <label class="font-medium">Last name</label>
                        <InputText v-model="organizationStore.organization.lastName" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="flex flex-column gap-2">
                        <label class="font-medium">Email</label>
                        <InputText v-model="organizationStore.organization.email" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="flex flex-column gap-2">
                        <label class="font-medium">Phone</label>
                        <InputText v-model="organizationStore.organization.phone" />
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="flex justify-content-end">
                <Button label="Save changes" @click="changeOrganization" :loading="isUpdateProfileLoading" />
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref } from "vue";
import { useOrganizationStore } from '../stores/organization';
import { useToast } from 'primevue/usetoast';
import { countries } from '../utils/constants';
import { useDataAPI } from '../composables/useDataAPI';
import * as Sentry from "@sentry/vue";


const { patchCurrentOrganization } = useDataAPI();
const toast = useToast();
const organizationStore = useOrganizationStore();
const isUpdateProfileLoading = ref(false);



async function fetchCurrentOrganization() {
    await organizationStore.fetchCurrentOrganization();
}

function checkRequiredFields() {
    console.log(organizationStore.organization)
    if (!organizationStore.organization || !organizationStore.organization.organizationName) {
        toast.add({ severity: 'error', summary: 'Missing field', detail: "You have to define an organization name", life: 3000 });
        return false
    }

    return true
}

function sanitizeFields() {
    const organization = organizationStore.organization;

    if (!organization) {
        return false;
    }

    // Trim all string fields
    const trimmedOrganization = {
        firstName: organization.firstName?.trim(),
        lastName: organization.lastName?.trim(),
        country: organization.country?.trim(),
        eori: organization.eori?.trim(),
        organizationName: organization.organizationName?.trim(),
        street: organization.street?.trim(),
        postalCode: organization.postalCode?.trim(),
        city: organization.city?.trim(),
        phone: organization.phone?.trim(),
        email: organization.email?.trim(), // Assuming email is a field in organization
    };

    // Validate email format (if email is present)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (trimmedOrganization.email && !emailRegex.test(trimmedOrganization.email)) {
        toast.add({ severity: 'error', summary: 'Invalid Email', detail: 'Please provide a valid email address.', life: 3000 });
        return false;
    }

    return trimmedOrganization;
}

async function changeOrganization() {
    if (!checkRequiredFields()) {
        return;
    }

    const sanitizedFields = sanitizeFields();
    if (!sanitizedFields) {
        return;
    }

    try {
        isUpdateProfileLoading.value = true;
        const response = await patchCurrentOrganization(sanitizedFields);
        toast.add({ severity: 'success', summary: "Success", detail: "Your information has been successfully updated.", life: 3000 });
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        toast.add({ severity: 'error', summary: 'Error', detail: "Unable to update your information.", life: 3000 });
        return;
    } finally {
        isUpdateProfileLoading.value = false;
    }
}

fetchCurrentOrganization();
</script>

<style scoped>
.page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    /* Full viewport height */
    overflow: auto;
    /* Prevents scroll outside the containers */
    padding: 1rem;
}

.button-width {
    width: 90px;
}
</style>