<template>
    <div class="duediligence-container">
        <div class="align-items-center text-color-secondary">
            <i class="pi pi-arrow-left mr-2 cursor-pointer" @click="goToDueDiligence"></i>
            <span class="cursor-pointer" @click="goToDueDiligence">Back to Due Diligence Statements</span>
        </div>
        <div class="grid">
            <div class="col-12 mt-4">
                <div class="flex justify-content-between">
                    <span class="font-medium text-2xl">Due Diligence Details</span>
                </div>
            </div>

            <!-- Statement details and Place/Type of activity cards -->
            <div class="col-12 md:col-8">
                <Card style="height: 220px;">
                    <template #content>
                        <div class="grid">
                            <div class="col-12">
                                <span class="font-medium text-lg">Statement details</span>
                            </div>
                            <div class="col-6">
                                <span>Reference number</span>
                            </div>
                            <div class="col-6">
                                <span>{{ referenceNumber }}</span>
                            </div>
                            <div class="col-6">
                                <span>Verification number</span>
                            </div>
                            <div class="col-6">
                                <span>{{ verificationNumber }}</span>
                            </div>
                            <div class="col-6">
                                <span>DDS identifier</span>
                            </div>
                            <div class="col-6">
                                <span>{{ ddsIdentifier }}</span>
                            </div>
                            <div class="col-6">
                                <span>Internal reference number</span>
                            </div>
                            <div class="col-6">
                                <span>{{ internalReferenceNumber }}</span>
                            </div>
                        </div>
                    </template>
                </Card>
            </div>

            <!-- Place/Type of activity -->
            <div class="col-12 md:col-4">
                <Card style="height: 220px;">
                    <template #content>
                        <div class="grid">
                            <div class="col-12">
                                <span class="font-medium text-lg">Place/Type of activity</span>
                            </div>
                            <div class="col-6">
                                <span>Type of activity</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="activityType" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Country of activity</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="countryOfActivity" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Country of entry</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="borderCrossCountry" class="w-full" readonly />
                            </div>
                        </div>
                    </template>
                </Card>
            </div>

            <!-- Operator/Trader name and address on the left side -->
            <div class="col-12 md:col-8">
                <Card>
                    <template #content>
                        <div class="grid">
                            <div class="col-12">
                                <span class="font-medium text-lg">Operator/Trader name and address</span>
                            </div>
                            <div class="col-6">
                                <span>Name <span style="color:red">*</span></span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="name" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Country <span style="color:red">*</span></span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="country" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Address <span style="color:red">*</span></span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="address" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Email</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="email" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Phone</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="phone" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Identifier <span style="color:red">*</span></span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="identifier" class="w-full" readonly />
                            </div>
                        </div>
                    </template>
                </Card>
            </div>

            <!-- Additional information and Authorized Representative cards -->
            <div class="col-12 md:col-4">
                <!-- Additional information card -->
                <Card>
                    <template #content>
                        <div class="grid">
                            <div class="col-12">
                                <span class="font-medium text-lg">Additional information</span>
                            </div>
                            <div class="col-12">
                                <Textarea v-model="additionalInformation" class="w-full" readonly />
                            </div>
                        </div>
                    </template>
                </Card>
                <!-- Authorized Representative card below Additional information -->
                <Card class="mt-3">
                    <template #content>
                        <div class="grid">
                            <div class="col-12">
                                <span class="font-medium text-lg">Authorized Representative</span>
                            </div>
                            <div class="col-6">
                                <span>Name</span>
                            </div>
                            <div class="col-6">
                                <span>Nadar GmbH</span>
                            </div>
                            <div class="col-6">
                                <span>Country</span>
                            </div>
                            <div class="col-6">
                                <span>Germany</span>
                            </div>
                            <div class="col-6">
                                <span>ISO Code</span>
                            </div>
                            <div class="col-6">
                                <span>DE</span>
                            </div>
                        </div>
                    </template>
                </Card>
            </div>

            <!-- Batch details card -->
            <div class="col-12 md:col-8">
                <Card>
                    <template #content>
                        <div class="grid">
                            <div class="col-12">
                                <span class="font-medium text-lg">Batch details</span>
                            </div>
                            <div class="col-6">
                                <span>Batch label</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="batch.label" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>HS code</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="hsHeading" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Description of goods</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="descriptionOfGoods" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Net weight</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="netWeight" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Volume</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="volume" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Number of units</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="numberOfUnits" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Universal unit</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="universalUnit" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Scientific name</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="scientificName" class="w-full" readonly />
                            </div>
                            <div class="col-6">
                                <span>Common name</span>
                            </div>
                            <div class="col-6">
                                <InputText v-model="commonName" class="w-full" readonly />
                            </div>
                        </div>
                    </template>
                </Card>
            </div>

        </div>
    </div>
</template>


<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import { useDataAPI } from '../../composables/useDataAPI';
import { useToast } from 'primevue/usetoast';
import { useDueDiligenceStore } from '../../stores/duediligence';
import * as Sentry from "@sentry/vue";


const { getBatchByBatchObjectId, getDueDiligenceStatementByDdsIdentifier } = useAnalyticsAPI();
const { getCurrentOrganization } = useDataAPI();
const dueDiligenceStore = useDueDiligenceStore();
const router = useRouter();
const route = useRoute();
const batch = ref({});
const statement = ref({});
const toast = useToast();
const referenceNumber = ref('');
const internalReferenceNumber = ref('');
const hsHeading = ref('');
const descriptionOfGoods = ref('');
const netWeight = ref('');
const volume = ref('');
const numberOfUnits = ref('');
const universalUnit = ref('');
const scientificName = ref('');
const commonName = ref('');
const ddsIdentifier = ref();
const identifier = ref();
const verificationNumber = ref();
const countryOfActivity = ref();
const additionalInformation = ref();
const borderCrossCountry = ref();
const ddsCreatedAt = ref();
const batchCreatedAt = ref();
const activityType = ref();
const organization = ref({});
const phone = ref();
const email = ref();
const name = ref();
const country = ref();
const address = ref(); // TODO: Postal code city and street and number are split in organization


function goToDueDiligence() {
    router.push({ name: 'duediligence' });  // Replace 'duediligence' with your route name
};

function insertDdsDataIntoFields() {
    referenceNumber.value = statement.value.referenceNumber;
    ddsIdentifier.value = statement.value.ddsIdentifier;
    verificationNumber.value = statement.value.verificationNumber;
    countryOfActivity.value = statement.value.countryOfActivity;
    borderCrossCountry.value = statement.value.borderCrossCountry;
    additionalInformation.value = statement.value.additionalInformation;
    activityType.value = statement.value.activityType;
    ddsCreatedAt.value = statement.value.createdAt;
}

function insertBatchDataIntoFields() {
    internalReferenceNumber.value = batch.value.internalReferenceNumber;
    hsHeading.value = batch.value.hsHeading;
    descriptionOfGoods.value = batch.value.descriptionOfGoods;
    netWeight.value = batch.value.netWeight;
    volume.value = batch.value.volume;
    numberOfUnits.value = batch.value.numberOfUnits;
    universalUnit.value = batch.value.universalUnit;
    scientificName.value = batch.value.scientificName;
    commonName.value = batch.value.commonName;
    batchCreatedAt.value = batch.value.createdAt;
}

function insertOrganizationDataIntoFields() {
    name.value = organization.value.organizationName;
    country.value = organization.value.country;
    address.value = `${organization.value.street}, ${organization.value.postalCode}, ${organization.value.city}`
    phone.value = organization.value.phone;
    email.value = organization.value.email;
    identifier.value = organization.value.eori;
}

async function fetchData() {
    try {
        const ddsIdentifier = route.params.ddsIdentifier;
        organization.value = await getCurrentOrganization();
        statement.value = await getDueDiligenceStatementByDdsIdentifier(ddsIdentifier);
        batch.value = await getBatchByBatchObjectId(statement.value.batch);

        insertDdsDataIntoFields();
        insertBatchDataIntoFields();
        insertOrganizationDataIntoFields();
    } catch (error) {
        Sentry.captureException(error);
        console.log(error)
        toast.add({ severity: 'error', summary: 'Error', detail: "Cannot load due diligence statement.", life: 3000 });
    }
}


fetchData();
</script>


<style scoped>
.duediligence-container {
    flex: 1;
    overflow: auto;
    padding: 2rem;
    box-sizing: border-box;
}
</style>