<template>
    <div class="container">
        <div class="flex align-items-center justify-content-center card-wrapper" style="height: 70vh;">
            <div class="flex justify-content-center card-wrapper">
                <div class="card-outlined border-round-lg p-4 shadow-4">
                    <h1>Login</h1>
                    <span class="p-float-label mt-6">
                        <InputText class="underline-input w-full" v-model="email" />
                        <label for="firstName">Email</label>
                    </span>
                    <span class="p-float-label mt-6">
                        <InputText class="underline-input w-full" type="password" v-model="password" />
                        <label for="firstName">Password</label>
                    </span>
                    <span class="p-float-label mt-6">
                        <InputText class="underline-input w-full" v-model="adminUid" />
                        <label for="firstName">Secret 1</label>
                    </span>
                    <span class="p-float-label mt-6">
                        <InputText class="underline-input w-full" v-model="userUid" />
                        <label for="firstName">Secret 2</label>
                    </span>

                    <Button class="w-full mt-5 p-3" label="Login" @click="login" :loading="isLoading"
                        :disabled="isLoading">
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { signInWithCustomToken, setPersistence, getAuth, getIdToken, inMemoryPersistence, sendPasswordResetEmail } from 'firebase/auth';
import { useRouter } from 'vue-router';
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast';
import { useDataAPI } from '../composables/useDataAPI';
import * as Sentry from "@sentry/vue";


const { postLoginUser, getImpersonateToken } = useDataAPI();
const toast = useToast();
const router = useRouter();
const email = ref("");
const forgottenEmail = ref("")
const password = ref("");
const adminUid = ref("");
const userUid = ref("");
const isPasswordForgotten = ref(false);
const isLoading = ref(false); // New loading state

// Function for login action
async function login() {
    isLoading.value = true; // Start loading
    const auth = getAuth();
    // Setting persistence to inMemory
    await setPersistence(auth, inMemoryPersistence);

    try {
        if (email.value || password.value) {
            return
        }

        const responseImpersonate = await getImpersonateToken(adminUid.value, userUid.value);
        const customToken = responseImpersonate.customToken;

        const impersonateUserCred = await signInWithCustomToken(auth, customToken);
        const idTokenImpersonate = await getIdToken(impersonateUserCred.user);

        const responseLogin = await postLoginUser(idTokenImpersonate);
        //const response = await postLoginUser(idToken);
        router.push({ name: "organization" });
    } catch (error) {
        if (!["auth/invalid-email", "auth/user-disabled", "auth/user-not-found", "auth/wrong-password"].includes(error.code)) {
            Sentry.captureException(error); // Capture unexpected errors
        }

        let alertMessage;
        switch (error.code) {
            case "auth/invalid-email":
                alertMessage = "The email address is not valid.";
                break;
            case "auth/user-disabled":
                alertMessage = "The user account has been disabled.";
                break;
            case "auth/user-not-found":
            case "auth/wrong-password":
                alertMessage = "Incorrect email or password.";
                break;
            case "auth/network-request-failed":
                alertMessage = "Network error - Please try again later.";
                break;
            case "auth/too-many-requests":
                alertMessage = "Too many unsuccessful login attempts. Please try again later.";
                break;
            default:
                alertMessage = "Something went wrong.";
        }
        toast.add({ severity: 'error', summary: 'Error', detail: alertMessage, life: 5000 });
    } finally {
        isLoading.value = false; // Stop loading
    }
};
</script>


<style scoped>
.links {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-bottom: 2vh;
    margin-right: 4vw;
}

.impressum {
    color: rgb(0, 0, 0);
    text-decoration: underline;
    display: inline-block;
    margin-right: 20px;
}

.card-wrapper {
    margin: auto;
    /* Center the card in the available space */
    max-width: 400px;
    /* Your desired maximum width */
    width: 100%;
    /* Set the desired max-width */
}

.card-outlined {
    background-color: #fff;
    z-index: 100;
    text-align: center;
    margin: auto;
    /* Center the card in the available space */
    max-width: 600px;
    /* Your desired maximum width */
    width: 100%;
    /* Set the desired max-width */
}

.logo-above {
    position: relative;
    z-index: 2;
    /* Higher than the z-index of AnimationComponent */
}

.link-button {
    text-decoration: underline;
    display: inline-block;
    /* Aligns the button text to center */
    margin: 0 auto;
    /* Additional centering for the button */
}

.container {
    min-height: 100vh;
    /* Ensure it covers the full viewport height */
    width: 100%;
    /* Ensure it covers the full viewport width */
    background-color: rgb(200, 225, 195);
    /* Adjust the path as needed */
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
}
</style>