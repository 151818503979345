<template>
    <Dialog v-model:visible="dataImportStore.isDataImportSuccessDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column m-4 p-2 gap-2 justify-content-center">
                <div>
                    <span class="text-lg font-medium">File import successful</span>
                </div>
                <span class="block mb-3 mt-3">A new batch has been created. You can view the plots in this batch on the
                    Batches page.</span>
                <div class="flex justify-content-end gap-2">
                    <Button class="light-green-nadar" label="OK" @click="closeCallback"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { useDataImportStore } from '../../stores/dataImport';


const dataImportStore = useDataImportStore();
</script>