<template>
    <Button label="Delete" @click="showDeletePlotsDialog" :loading="mappingStore.isTableLoading" />

    <Dialog v-model:visible="isDeletePlotsDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column px-5 py-5 gap-4">
                <span class="text-lg font-medium">Delete plots?</span>
                <span>This will delete selected plots.</span>
                <div class="flex justify-content-end">
                    <Button label="Cancel" class="mr-3" severity="secondary" @click="closeDeletePlotsDialog"></Button>
                    <Button label="Delete" @click="handleDeletePlots" :loading="isDeletePlotLoading"
                        severity="danger"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useMappingStore } from '../../stores/mapping';
import { useToast } from 'primevue/usetoast';
import { usePagination } from './composables/usePagination';
import { useMappingUtils } from './composables/useMappingUtils';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import * as Sentry from "@sentry/vue";


const { deletePlots } = useAnalyticsAPI();
const { refreshData } = useMappingUtils();
const { unselectAllCheckboxes } = usePagination();
const toast = useToast();
const mappingStore = useMappingStore();
const isDeletePlotsDialogShown = ref(false);
const isDeletePlotLoading = ref(false);


async function handleDeletePlots() {
    isDeletePlotsDialogShown.value = false;
    const plotsToDelete = {
        plotObjectIds: mappingStore.selectedCheckboxes
    }

    try {
        const response = await deletePlots(plotsToDelete);
        toast.add({ severity: 'success', summary: 'Success', detail: 'Plots deleted.', life: 3000 });
        unselectAllCheckboxes();
        await refreshData();
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        isDeletePlotLoading.value = false;
        toast.add({ severity: 'error', summary: 'Error', detail: 'Could not delete plots.', life: 3000 });
        return;
    }
}

function closeDeletePlotsDialog() {
    isDeletePlotsDialogShown.value = false;
}

function showDeletePlotsDialog() {
    if (mappingStore.selectedCheckboxes && !mappingStore.selectedCheckboxes.length) {
        toast.add({ severity: 'info', summary: 'Plot Selection', detail: 'Select at least one plot.', life: 3000 });
        return;
    }

    isDeletePlotsDialogShown.value = true;
}
</script>