<template>
    <div class="grid">
        <div class="col-12">
            <span class="font-medium text-lg">Activity type </span><span style="color:red">*</span>
        </div>
        <div class="col-12">
            <div class="flex flex-column gap-2">
                <Dropdown v-model="dueDiligenceStore.selectedActivityType" :options="activityTypes" disabled />
            </div>
        </div>
        <div class="col-12">
            <span class="font-medium text-lg">Place of activity</span>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Country of activity</label>
                <Dropdown v-model="dueDiligenceStore.countryOfActivity" :options="ALLOWED_COUNTRIES" />
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Border cross country</label>
                <Dropdown v-model="dueDiligenceStore.borderCrossCountry" :options="ALLOWED_COUNTRIES" />
            </div>
        </div>
        <div class="col-12">
            <span class="font-medium text-lg">Additional information</span>
        </div>
        <div class="col-12">
            <div class="flex flex-column gap-2">
                <Textarea v-model="dueDiligenceStore.additionalInformation" rows="3" cols="30" />
            </div>
        </div>
    </div>
    <div class="flex pt-4 justify-content-between">
        <Button label="Back" severity="secondary" @click="prevCallback" />
        <Button label="Next" @click="handleNextStep(nextCallback)" />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { ALLOWED_COUNTRIES } from '../../utils/constants';
import { useDueDiligenceStore } from '../../stores/duediligence';
import { useToast } from 'primevue/usetoast';

const props = defineProps({
    nextCallback: {
        type: Function,
        required: true
    },
    prevCallback: {
        type: Function,
        required: true
    }
});


const dueDiligenceStore = useDueDiligenceStore();
const toast = useToast();
const selectedActivityType = ref("Import");
const activityTypes = ref(["Import", "Export", "Domestic"]);



function checkRequiredFields() {
    if (!selectedActivityType.value) {
        toast.add({ severity: 'info', summary: 'Missing field', detail: "Please select all required fields.", life: 3000 });
        return false
    }

    return true
}

function handleNextStep(nextCallback) {
    if (!checkRequiredFields()) {
        return
    }

    nextCallback();
}
</script>