<template>
    <Dialog v-model:visible="plotStore.isRiskDetectionSuccessDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column m-4 p-2 gap-2 justify-content-center">
                <span class="text-lg">Risk detection completed</span>
                <span class="block mb-3">
                    The risk detection process has completed. Please review the results.
                </span>
                <div class="flex justify-content-end gap-2">
                    <Button class="light-green-nadar" label="OK" @click="closeCallback"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { usePlotStore } from '../../../stores/plot';

const plotStore = usePlotStore();
</script>