<template>
    <div class="suppliers-container">
        <div class="flex justify-content-between">
            <span class="font-medium text-3xl">Suppliers</span>
            <div>
                <Button icon="pi pi-sync" class="mr-1" @click="supplierStore.fetchAllSuppliers"
                    :loading="supplierStore.isLoading" />
                <Button label="Create supplier" @click="openCreateSupplierDialog" />
            </div>
        </div>
        <SuppliersTable />
    </div>

    <CreateSupplierDialog />
</template>

<script setup>
import SuppliersTable from "../components/suppliers/SuppliersTable.vue";
import CreateSupplierDialog from "../components/suppliers/CreateSupplierDialog.vue";
import { useSupplierStore } from "../stores/supplier";


const supplierStore = useSupplierStore();


function openCreateSupplierDialog() {
    supplierStore.isCreateSupplierDialogShown = true
}
</script>

<style scoped>
.suppliers-container {
    flex: 1;
    overflow: auto;
    padding: 1rem;
    box-sizing: border-box;
}
</style>