import { defineStore } from 'pinia'

const Mode = {
    IDLE: "IDLE",
    MARK_CONFIG: "MARK_CONFIG",
    MARK: "MARK",
    WALK_CONFIG: "WALK_CONFIG",
    WALK: "WALK",
    DETAILS: "DETAILS"
}

export const useOnboardingStore = defineStore('onboarding', {
    state: () => ({
        plots: [],
        suppliers: [],
        currentMode: Mode.IDLE,
    }),
    persist: {
        enabled: true,
        strategies: [
            {
                key: "onboarding",
                storage: localStorage,
            },
        ],
    },
})
