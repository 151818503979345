<template>
    <Dialog v-model:visible="dataImportStore.isDataImportDialogShown" modal :style="{ width: '90rem' }">
        <template #header>
            <span class="font-medium text-lg">Import Data</span>
        </template>
        <Stepper :key="stepperKey" linear v-model:activeStep="dataImportStore.stepOfStepper">
            <StepperPanel header="Select File">
                <template #content="{ nextCallback }">
                    <SelectFileStep :nextCallback="nextCallback" :resetStepper="resetStepper" />
                </template>
            </StepperPanel>
            <StepperPanel header="Select Fields">
                <template #content="{ prevCallback, nextCallback }">
                    <SelectFieldsStep :prevCallback="prevCallback" :nextCallback="nextCallback" />
                </template>
            </StepperPanel>
            <StepperPanel header="Specify Supply Chain">
                <template #content="{ prevCallback }">
                    <SpecifySupplyChainStep :prevCallback="prevCallback" />
                </template>
            </StepperPanel>
        </Stepper>
    </Dialog>
</template>

<script setup>
import { onUpdated, ref } from "vue";
import SelectFieldsStep from "./SelectFieldsStep.vue";
import SelectFileStep from "./SelectFileStep.vue";
import SpecifySupplyChainStep from "./SpecifySupplyChainStep.vue";
import { useDataImportStore } from "../../stores/dataImport";


const dataImportStore = useDataImportStore();
const stepperKey = ref(0);


function resetStepper() {
    stepperKey.value += 1;  // Force the Stepper to recreate
    dataImportStore.resetStepper();
}

onUpdated(() => {
    console.log("onUpdated")
    stepperKey.value += 1;
    dataImportStore.resetStepper();
})
</script>