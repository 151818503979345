import { defineStore } from 'pinia'
import { useAnalyticsAPI } from '../composables/useAnalyticsAPI';

export const useSupplierStore = defineStore('supplier', {
    state: () => ({
        allSuppliers: [],
        isCreateSupplierDialogShown: false,
        isLoading: false,
        isEditSupplierDialogShown: false,
        isDeleteSupplierDialogShown: false
    }),
    actions: {
        async fetchAllSuppliers() {
            const { getAllSuppliers } = useAnalyticsAPI();
            this.isLoading = true;
            this.allSuppliers = [];

            try {
                const suppliers = await getAllSuppliers();
                this.allSuppliers = suppliers;
            } catch (error) {
                console.error(error);
                throw error;
            } finally {
                this.isLoading = false;
            }
        }
    }
});
