<template>
    <div id="createplotmap">
        <Button :disabled="onboardingStore.currentMode === Mode.WALK" icon="pi pi-map-marker"
            class="overlay-place-marker white-button" label="Mark point" @click="handlePlaceMarker" />
        <Button :disabled="onboardingStore.currentMode === Mode.WALK" class="overlay-walk white-button"
            label="Start polygon walk" @click="handlePlotWalk" />
        <div class="overlay-gps-accuracy white-button border-round p-1">
            <span v-if="isGpsActive">Accuracy: {{ gpsAccuracy }} meters</span>
            <span v-else=>No GPS</span>
        </div>
    </div>

    <Dialog v-model:visible="isGpsUnavailableDialogShown" modal header="GPS disabled" :style="{ width: '90vw' }">
        <div class="flex align-items-center justify-content-center text-center">
            <span>Allow GPS location by clicking on the GPS button.</span>
        </div>
        <div class="flex align-items-center justify-content-cente text-center mt-4">
            If this is not possible: Refresh your browser and try again or navigate to your device's settings and enable
            GPS for your browser.
        </div>
        <template #footer>
            <Button label="OK" class="w-full" rounded @click="closeIsGpsUnavailableDialogShown" />
        </template>
    </Dialog>
</template>

<script setup>
import { onMounted, onUnmounted, inject } from "vue";
import { ref } from "vue";
import { useOnboardingStore } from "../../stores/onboarding";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";


const VITE_MAPBOX2_ACCESS_TOKEN = import.meta.env.VITE_MAPBOX2_ACCESS_TOKEN;
mapboxgl.accessToken = VITE_MAPBOX2_ACCESS_TOKEN;


let map = inject("map");
let marker = inject("marker");
const savedGeometry = inject("savedGeometry");
const lineCoordinates = inject("lineCoordinates");
const onboardingStore = useOnboardingStore();
const isGpsUnavailableDialogShown = ref(false);
const Mode = {
    IDLE: "IDLE",
    MARK_CONFIG: "MARK_CONFIG",
    MARK: "MARK",
    WALK_CONFIG: "WALK_CONFIG",
    WALK: "WALK",
    DETAILS: "DETAILS"
}

const geolocate = ref(new mapboxgl.GeolocateControl({
    positionOptions: {
        enableHighAccuracy: true
    },
    trackUserLocation: true,
    showUserHeading: true
}))
const longitude = ref();
const latitude = ref();
const lineSource = ref(null);
const gpsAccuracy = ref(null);
// This variable works only semi because mapbox has no resource friendly way but deep true watch to find out if gps is really offline.
// It only is false at the beginning, false when error happens and true when user asks for the first time for GPS. Manual GPS on off is not tracked.
const isGpsActive = ref(false);


function closeIsGpsUnavailableDialogShown() {
    isGpsUnavailableDialogShown.value = false;
}

function handlePlaceMarker() {
    if (marker.value) {
        marker.value.remove();
    }

    // Check if the layer exists before removing it
    if (map.value.getLayer('line-layer')) {
        map.value.removeLayer('line-layer');
    }
    // Check if the source exists before removing it
    if (map.value.getSource('line-source')) {
        map.value.removeSource('line-source');
    }

    // Check if longitude and latitude are undefined and then trigger geolocation service
    if (
        geolocate.value._watchState === "OFF" ||
        !longitude.value ||
        !latitude.value ||
        isNaN(longitude.value) ||
        isNaN(latitude.value)
    ) {
        onboardingStore.currentMode = Mode.MARK_CONFIG;
        geolocate.value.trigger();
        return;
    }

    // Update latitude and longitude with current coordinates
    const coordinates = [longitude.value, latitude.value]
    marker.value = new mapboxgl.Marker()
        .setLngLat(coordinates) // Set the marker's coordinates
        .addTo(map.value); // Add the marker to the map

    map.value.fitBounds([coordinates, coordinates], {
        padding: {
            top: 0,
            bottom: 300,
            left: 0,
            right: 0
        },
        animate: true,
        duration: 2000
    });

    savedGeometry.value = {
        type: 'Point',
        coordinates: coordinates,
    }

    onboardingStore.currentMode = Mode.DETAILS;
}

function handlePlotWalk() {
    if (marker.value) {
        marker.value.remove();
    }

    lineCoordinates.value = [];

    // Check if the layer exists before removing it
    if (map.value.getLayer('line-layer')) {
        map.value.removeLayer('line-layer');
    }
    // Check if the source exists before removing it
    if (map.value.getSource('line-source')) {
        map.value.removeSource('line-source');
    }

    // TODO: Bei Minka wurde ein leeres LineString abgespeichert. Lag es daran dass stopPlotWalk ein leeres lineCoordinates abgespeichert hat?
    savedGeometry.value = {
        type: 'LineString',
        coordinates: []
    }

    if (geolocate.value._watchState === "OFF") {
        geolocate.value.trigger();
        onboardingStore.currentMode = Mode.WALK_CONFIG;
    } else {
        onboardingStore.currentMode = Mode.WALK;
    }
}

onMounted(() => {
    map.value = new mapboxgl.Map({
        container: 'createplotmap',
        style: 'mapbox://styles/mapbox/satellite-v9',
        projection: 'globe', // Display the map as a globe, since satellite-v9 defaults to Mercator
        zoom: 1,
        center: [-50, 20],
        transformRequest: (url, resourceType) => {
            if (url.startsWith("https://tiles.planet.com")) {
                // If the URL is from Planet, don't include credentials
                return {
                    url: url
                };
            }
        }
    });

    map.value.addControl(new mapboxgl.NavigationControl());
    map.value.addControl(geolocate.value);

    geolocate.value.on('error', function (error) {
        console.log("Geolocate error:", error);
        isGpsUnavailableDialogShown.value = true;
        gpsAccuracy.value = null;
        isGpsActive.value = false;
    });

    geolocate.value.on('geolocate', function (event) {
        console.log("geolocate")

        latitude.value = event.coords.latitude;
        longitude.value = event.coords.longitude;
        console.log(typeof (event.coords.accuracy))
        if (typeof event.coords.accuracy === "number") {
            gpsAccuracy.value = Math.trunc(event.coords.accuracy);
        }
        const coordinates = [longitude.value, latitude.value]

        if (onboardingStore.currentMode === Mode.WALK_CONFIG) {
            console.log("WALK_CONFIG")
            onboardingStore.currentMode = Mode.WALK;
        }

        // Push the new coordinate to the lineCoordinates
        if (onboardingStore.currentMode === Mode.WALK) {
            console.log("WALK")
            savedGeometry.value.coordinates.push(coordinates);
            lineCoordinates.value.push(coordinates);
        }

        if (onboardingStore.currentMode === Mode.MARK_CONFIG) {
            console.log("MARK_CONFIG")
            onboardingStore.currentMode = Mode.MARK;
        }

        if (onboardingStore.currentMode === Mode.MARK) {
            console.log("MARK")
            if (marker.value) {
                marker.value.remove();
            }

            marker.value = new mapboxgl.Marker()
                .setLngLat(coordinates)
                .addTo(map.value);

            map.value.fitBounds([coordinates, coordinates], {
                padding: {
                    top: 0,
                    bottom: 300,
                    left: 0,
                    right: 0
                },
                animate: true,
                duration: 3000
            });

            savedGeometry.value = {
                type: 'Point',
                coordinates: coordinates,
            }

            onboardingStore.currentMode = Mode.DETAILS;
        }

        // If the source 'line-source' already exists, update its data
        if (map.value.getSource('line-source')) {
            lineSource.value.setData({
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: lineCoordinates.value
                }
            });
        } else {
            // If the source doesn't exist, create it
            map.value.addSource('line-source', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: lineCoordinates.value
                    }
                }
            });
            lineSource.value = map.value.getSource('line-source');

            // Add a new layer to visualize the line
            map.value.addLayer({
                id: 'line-layer',
                type: 'line',
                source: 'line-source',
                layout: {},
                paint: {
                    'line-color': '#FF5733',
                    'line-width': 4
                }
            });
        }
    });

    geolocate.value.on('trackuserlocationstart', () => {
        isGpsActive.value = true;
    });

    map.value.on('style.load', () => {
        map.value.setFog({
            "range": [0.8, 8],
            "color": "#090e21",
            "horizon-blend": 0,
            "high-color": "#245bde",
            "space-color": "#000000",
            "star-intensity": 0.15

        }); // Set the default atmosphere style
    });
})

onUnmounted(() => {
    map.value.remove();
})
</script>

<style scoped>
#createplotmap {
    top: 0;
    bottom: 0;
    height: calc(100dvh - 50px);
    margin-bottom: 50px;
    width: 100%;
}

.overlay-walk {
    position: absolute;
    right: 50px;
    top: 10px;
    z-index: 1000;
}

.overlay-place-marker {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1000;
}

.overlay-gps-accuracy {
    position: absolute;
    right: 10px;
    top: 150px;
    z-index: 1000;
}

.white-button {
    background-color: #ffffff;
    border-color: #000000;
    color: rgb(0, 0, 0);
    /* Text Color */
}
</style>