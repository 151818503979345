<template>
    <Dialog v-model:visible="supplierStore.isDeleteSupplierDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column px-5 py-5 gap-4">
                <span class="text-lg font-medium">Delete supplier?</span>
                <div>
                    <span>This action cannot be undone.</span>
                </div>
                <div class="flex justify-content-end">
                    <Button label="Cancel" class="mr-3" severity="secondary" @click="closeCallback"></Button>
                    <Button label="Delete" severity="danger" @click="handleDeleteSupplier"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { useSupplierStore } from '../../stores/supplier';
import { useToast } from 'primevue/usetoast';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import * as Sentry from "@sentry/vue";


const props = defineProps({
    supplier: Object
})

const { deleteSupplier } = useAnalyticsAPI();
const supplierStore = useSupplierStore();
const toast = useToast();


function closeDeleteSupplierDialog() {
    supplierStore.isDeleteSupplierDialogShown = false;
}

async function handleDeleteSupplier() {
    closeDeleteSupplierDialog();

    try {
        const response = await deleteSupplier(props.supplier._id);
        supplierStore.allSuppliers = supplierStore.allSuppliers.filter(supplier => supplier._id !== props.supplier._id);
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Could not delete supplier.', life: 3000 });
    }
}
</script>