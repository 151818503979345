<template>
    <Dialog v-model:visible="dataImportStore.isDataImportFailureDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column m-4 p-2 gap-2 justify-content-center">
                <div>
                    <span class="text-lg font-medium">File import failed</span>
                </div>
                <span class="block mb-3 mt-3">There was an issue with the file import. Please try again, or contact
                    support if the issue persists.</span>
                <div class="flex justify-content-end gap-2">
                    <Button label="OK" severity="danger" @click="closeCallback"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { useDataImportStore } from '../../stores/dataImport';

const dataImportStore = useDataImportStore();
</script>