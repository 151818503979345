<template>
    <Card>
        <template #title>
            <span class="font-medium">File history</span>
        </template>
        <template #content>
            <DataTable :value="dataImportStore.tableFiles" size="small" scrollable scrollHeight="calc(100vh - 160px)">
                <Column field="name" header="File name">
                    <template #body="{ data }">
                        <div class="flex align-items-center">
                            <img :src="getFileLogo(data.name)" alt="File logo" class="mr-2" width="24" height="24" />
                            <div class="flex flex-column">
                                <span class="font-medium">{{ data.name }}</span>
                                <span class="text-sm pt-1">{{ formatFileSize(data.size) }}</span>
                            </div>
                        </div>
                    </template>
                </Column>
                <Column field="createdAt" header="Date imported">
                    <template #body="{ data }">
                        <div class="flex flex-column">
                            <span>{{ formatDate(data.createdAt) }} </span>
                        </div>
                    </template>
                </Column>
                <Column header="Imported by">
                    <template #body="{ data }">
                        <div class="flex flex-column">
                            <span class="font-medium">{{ data.createdByUser.firstName }} {{ data.createdByUser.lastName
                                }}</span>
                            <span class="text-sm pt-1">{{ data.createdByUser.email }}</span>
                        </div>
                    </template>
                </Column>
                <Column field="batch._id" header="Batch ID"></Column>
                <Column field="batch.label" header="Batch label"></Column>
                <Column header="Actions">
                    <template #body="{ data }">
                        <Button icon="pi pi-download" outlined size="small" class="mr-1" @click="downloadFile(data)"
                            :loading="isDownloading" />
                    </template>
                </Column>
                <template #empty>
                    <div v-if="!dataImportStore.isTableFilesLoading"
                        class="flex flex-column align-items-center justify-content-center select-none">
                        <img :src="placeholder" alt="No files placeholder" class="mb-2" width="150" />
                        <span>No files available for display.</span>
                        <small>Please import a file to view it here.</small>
                    </div>
                    <div v-else>
                        <ProgressBar mode="indeterminate" style="height:2px"></ProgressBar>
                    </div>
                </template>
            </DataTable>
        </template>
    </Card>

    <Dialog v-model:visible="isDeleteFileDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column px-5 py-5 gap-4">
                <span class="text-lg font-medium">Delete file?</span>
                <div>
                    <span>This action cannot be undone.</span>
                </div>
                <div class="flex justify-content-end">
                    <Button label="Cancel" class="mr-3" severity="secondary" @click="closeCallback"></Button>
                    <Button label="Delete" severity="danger" @click="handleDeleteFile"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useAnalyticsAPI } from '../../composables/useAnalyticsAPI';
import shapeLogo from '../../assets/import_logos/shape_logo.svg';
import geojsonLogo from '../../assets/import_logos/geojson_logo.svg';
import kmlLogo from '../../assets/import_logos/kml_logo.svg';
import kmzLogo from '../../assets/import_logos/kmz_logo.svg';
import excelLogo from '../../assets/import_logos/excel_logo.svg';
import csvLogo from '../../assets/import_logos/csv_logo.svg';
import placeholder from "../../assets/placeholder_dataImport.svg"
import { useToast } from 'primevue/usetoast';
import { useDataImportStore } from '../../stores/dataImport';
import { useDataFormatUtils } from '../../composables/useDataFormatUtils';
import * as Sentry from "@sentry/vue";


const { formatDate } = useDataFormatUtils();
const { getAllFiles, getDownloadFile, deleteFile } = useAnalyticsAPI();
const dataImportStore = useDataImportStore();
const toast = useToast();
const isDeleteFileDialogShown = ref(false);
const selectedFile = ref();
const isDownloading = ref(false);


function closeDeleteFileDialog() {
    isDeleteFileDialogShown.value = false;
}

async function handleDeleteFile() {
    closeDeleteFileDialog();
    try {
        const response = await deleteFile(selectedFile.value._id);
        dataImportStore.tableFiles = dataImportStore.tableFiles.filter(file => file._id !== selectedFile.value._id);
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        toast.add({
            severity: 'error',
            summary: 'Deletion failed',
            detail: 'There was an error while deleting the file. Please try again.',
            life: 3000,
        });
    }
}

async function downloadFile(data) {
    try {
        isDownloading.value = true;
        const response = await getDownloadFile(data._id)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = response.headers['content-disposition']?.split('filename=')[1] || 'downloaded_file';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
    } finally {
        isDownloading.value = false;
    }
}

function formatFileSize(sizeInKb) {
    if (sizeInKb >= 1000) {
        const sizeInMb = (sizeInKb / 1000).toFixed(2); // Convert to MB with 2 decimal places
        return `${sizeInMb} MB`;
    }
    return `${sizeInKb} KB`;
}

function getFileLogo(fileName) {
    const extension = fileName.split('.').pop().toLowerCase(); // Get the file extension

    switch (extension) {
        case 'shp':
            return shapeLogo;
        case 'geojson':
            return geojsonLogo;
        case 'kml':
            return kmlLogo;
        case 'kmz':
            return kmzLogo;
        case 'xlsx':
            return excelLogo;
        case 'csv':
            return csvLogo;
        default:
            return ''; // Return an empty string or a default icon for unsupported types
    }
}

async function fetchFiles() {
    dataImportStore.isTableFilesLoading = true;
    const response = await getAllFiles();
    dataImportStore.tableFiles = response;
    dataImportStore.isTableFilesLoading = false;
}

fetchFiles();
</script>