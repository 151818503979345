<template>
    <div class="flex marker-icon border-round p-2 custom-height mark-point-icon" v-tooltip.left="'Place Marker'"
        @click="togglePlacingMarkerMode">
        <div class="flex align-items-center justify-content-between">
            <i class="pi pi-map-marker" style="font-size: 1.3rem"></i>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import { useMappingStore } from '../../stores/mapping';
import { GeometryMode } from '../../utils/constants';


const mappingStore = useMappingStore();
const draw = inject('draw');


function togglePlacingMarkerMode() {
    if (mappingStore.currentGeometryMode === GeometryMode.NONE) {
        mappingStore.currentGeometryMode = GeometryMode.DRAWING;
        draw.changeMode('draw_point');
    }
    else if (mappingStore.currentGeometryMode === GeometryMode.EDITING) {
        draw.changeMode('draw_point');
    }
    else {
        mappingStore.currentGeometryMode = GeometryMode.NONE;
        draw.changeMode('simple_select');
    }
}
</script>

<style scoped>
.mark-point-icon {
    position: absolute;
    top: 150px;
    right: 10px;
    z-index: 1000;
    background-color: white;
    border-style: solid;
    border-color: rgb(219, 219, 219);
    border-width: 1px;
    cursor: pointer;
}

.mark-point-icon:hover {
    background-color: #e6e6e6;
    border-style: solid;
    border-color: rgb(219, 219, 219);
}
</style>