<template>
    <div class="settings-container">
        <span class="font-medium text-3xl">Settings</span>
        <Divider />
        <div class="flex mt-4">
            <div class="flex flex-column col-2">
                <Button label="Profile" text @click="selectedComponent = 'UserProfilePage'" />
                <Button v-if="usersStore.hasPermission('view_organization')" label="Organization" text
                    @click="selectedComponent = 'OrganizationPage'" />
                <Button v-if="usersStore.hasPermission('view_user_management')" label="User Management" text
                    @click="selectedComponent = 'UserManagementPage'" />
                <Button v-if="usersStore.hasPermission('view_organization')" label="API Token" text
                    @click="selectedComponent = 'ApiTokensPage'" />
            </div>

            <div class="col-10">
                <component :is="currentComponent" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import UserProfilePage from './UserProfilePage.vue';
import OrganizationPage from './OrganizationPage.vue';
import UserManagementPage from './UserManagementPage.vue';
import ApiTokensPage from './ApiTokensPage.vue';
import { useUsersStore } from '../stores/users';


const selectedComponent = ref('UserProfilePage');
const usersStore = useUsersStore();
const components = {
    UserProfilePage,
    OrganizationPage,
    UserManagementPage,
    ApiTokensPage
};

const currentComponent = computed(() => components[selectedComponent.value]);
</script>

<style scoped>
.settings-container {
    flex: 1;
    overflow: auto;
    padding: 1rem;
    box-sizing: border-box;
}
</style>
