import { defineStore } from 'pinia'
import { useAnalyticsAPI } from '../composables/useAnalyticsAPI';


export const useDueDiligenceStore = defineStore('duediligence', {
    state: () => ({
        isCreateStatementDialogShown: false,
        statements: [],
        value: {},
        selectedActivityType: "Import",
        countryOfActivity: null,
        borderCrossCountry: null,
        additionalInformation: "",
        stepOfStepper: 0,
        isStatementsLoading: false,
        isRetractDueDiligenceStatementShown: false
    }),
    actions: {
        async fetchDueDiligenceStatements() {
            const { getAllDueDiligenceStatements } = useAnalyticsAPI();

            try {
                this.isStatementsLoading = true;
                this.statements = [];
                const response = await getAllDueDiligenceStatements();
                console.log(response)
                this.statements = response;
            } catch (error) {
                console.log(error)
                throw error
            } finally {
                this.isStatementsLoading = false;
            }
        },
        resetStepper() {
            this.isStatementsLoading = false;
            this.value = {};
            this.countryOfActivity = null;
            this.borderCrossCountry = null;
            this.additionalInformation = "";
            this.stepOfStepper = 0;
        }
    }
})