<template>
    <Dialog v-model:visible="dataImportStore.isInvalidAreaCellDialogShown" modal :style="{ width: '70rem' }">
        <template #header>
            <span class="font-medium text-lg">Invalid area cells found</span>
        </template>
        <DataTable :value="dataImportStore.invalidAreaCellDialogMessage" scrollable scrollHeight="400px"
            :virtualScrollerOptions="{ itemSize: 46 }">
            <Column field="feature">
                <template #header>
                    <span class="mr-1">Feature number</span>
                    <i class="pi pi-question-circle"
                        v-tooltip.top="'Represents the order of the feature in the GeoJSON file (1st, 2nd, 3rd, etc.). This helps you identify which feature has the problem.'"></i>
                </template>
            </Column>
            <Column field="message">
                <template #header>
                    <span class="mr-1">Validation error</span>
                    <i class="pi pi-question-circle"
                        v-tooltip.top="'Detailed information about the issue found with the feature\'s geometry.'"></i>
                </template>
            </Column>
        </DataTable>
        <div class="flex justify-content-end gap-2 mt-3">
            <Button label="OK" severity="danger" @click="dataImportStore.closeInvalidAreaCellDialog()"></Button>
        </div>
    </Dialog>
</template>

<script setup>
import { useDataImportStore } from '../../stores/dataImport';


const dataImportStore = useDataImportStore();
</script>
