<template>
    <div class="container">
        <div class="flex align-items-center justify-content-center card-wrapper" style="height: 70vh;">
            <div class="flex justify-content-center card-wrapper">
                <div class="card-outlined border-round-lg p-4 shadow-4">
                    <div v-if="isPasswordForgotten">
                        <h1>Forgot Password?</h1>
                        <span class="p-float-label mt-6">
                            <InputText class="underline-input w-full" v-model="forgottenEmail" />
                            <label for="firstName">Email</label>
                        </span>
                        <Button class="w-full mt-5 p-3" label="Reset Password" @click="handleResetPassword"
                            :loading="isLoading" />
                        <Button label="Back" link class="link-button mt-5" @click="isPasswordForgotten = false" />
                    </div>
                    <div v-else>
                        <h1>Login</h1>
                        <span class="p-float-label mt-6">
                            <InputText class="underline-input w-full" v-model="email" />
                            <label for="firstName">Email</label>
                        </span>
                        <span class="p-float-label mt-6">
                            <InputText class="underline-input w-full" type="password" v-model="password" />
                            <label for="firstName">Password</label>
                        </span>

                        <Button class="w-full mt-5 p-3" label="Login" @click="login" :loading="isLoading">
                        </Button>
                        <Button label="Forgot Password?" link class="link-button mt-5"
                            @click="isPasswordForgotten = true" />
                    </div>
                </div>
            </div>
            <div class="links">
                <a href="https://www.nadar.earth/imprint" class="impressum">Imprint</a>
                <a href="#" class="impressum" @click.prevent="goToPrivacyPolicy">Privacy Policy</a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { signInWithEmailAndPassword, setPersistence, getAuth, getIdToken, inMemoryPersistence, sendPasswordResetEmail } from 'firebase/auth';
import { useRouter } from 'vue-router';
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast';
import { useDeviceStore } from '../stores/device';
import { useDataAPI } from '../composables/useDataAPI';
import * as Sentry from "@sentry/vue";


const { postLoginUser } = useDataAPI();
const deviceStore = useDeviceStore();
const toast = useToast();
const router = useRouter();
const email = ref("");
const forgottenEmail = ref("")
const password = ref("");
const isPasswordForgotten = ref(false);
const isLoading = ref(false); // New loading state


// Function for login action
async function login() {
    isLoading.value = true; // Start loading
    const auth = getAuth();
    const deviceStore = useDeviceStore();
    deviceStore.detectDevice();
    // Setting persistence to inMemory
    await setPersistence(auth, inMemoryPersistence);

    try {
        const { user } = await signInWithEmailAndPassword(auth, email.value, password.value);
        console.log(user)
        const idToken = await getIdToken(user);
        const response = await postLoginUser(idToken);
        console.log(deviceStore.device)
        if (deviceStore.device === "desktop") {
            router.push({ name: "import" });
        } else {
            router.push({ name: "onboarding-map" });
        }

    } catch (error) {
        if (!["auth/invalid-email", "auth/user-disabled", "auth/user-not-found", "auth/wrong-password"].includes(error.code)) {
            Sentry.captureException(error); // Capture unexpected errors
        }

        let alertMessage;
        switch (error.code) {
            case "auth/invalid-email":
                alertMessage = "The email address is not valid.";
                break;
            case "auth/user-disabled":
                alertMessage = "The user account has been disabled.";
                break;
            case "auth/user-not-found":
            case "auth/wrong-password":
                alertMessage = "Incorrect email or password.";
                break;
            case "auth/network-request-failed":
                alertMessage = "Network error - Please try again later.";
                break;
            case "auth/too-many-requests":
                alertMessage = "Too many unsuccessful login attempts. Please try again later.";
                break;
            default:
                alertMessage = "Something went wrong.";
        }
        toast.add({ severity: 'error', summary: 'Error', detail: alertMessage, life: 5000 });
    } finally {
        isLoading.value = false; // Stop loading
    }
};

function handleResetPassword() {
    isLoading.value = true;
    const auth = getAuth();
    sendPasswordResetEmail(auth, forgottenEmail.value)
        .then(() => {
            // Email sent
            // You can inform the user that the email has been sent
            toast.add({ severity: 'success', summary: 'Success', detail: "Password reset email sent.", life: 5000 });

        })
        .catch((error) => {
            let alertMessage;
            switch (error.code) {
                case "auth/invalid-value-(email),-starting-an-object-on-a-scalar-field-invalid-value-(password),-starting-an-object-on-a-scalar-field":
                case "auth/invalid-email":
                case "auth/wrong-password":
                case "auth/user-not-found":
                    alertMessage = "Incorrect email or password."; // Generic message
                    break;
                case "auth/user-disabled":
                    alertMessage = "User has been disabled. Contact support.";
                    break;
                default:
                    alertMessage = "Something went wrong.";
            }
            toast.add({ severity: 'error', summary: 'Error', detail: alertMessage, life: 5000 });
        }).finally(() => {
            isLoading.value = false;
        })
}

function goToPrivacyPolicy() {
    router.push({ name: "privacy" });
};
</script>


<style scoped>
.links {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-bottom: 2vh;
    margin-right: 4vw;
}

.impressum {
    color: rgb(0, 0, 0);
    text-decoration: underline;
    display: inline-block;
    margin-right: 20px;
}

.card-wrapper {
    margin: auto;
    /* Center the card in the available space */
    max-width: 400px;
    /* Your desired maximum width */
    width: 100%;
    /* Set the desired max-width */
}

.card-outlined {
    background-color: #fff;
    z-index: 100;
    text-align: center;
    margin: auto;
    /* Center the card in the available space */
    max-width: 600px;
    /* Your desired maximum width */
    width: 100%;
    /* Set the desired max-width */
}

.logo-above {
    position: relative;
    z-index: 2;
    /* Higher than the z-index of AnimationComponent */
}

.link-button {
    text-decoration: underline;
    display: inline-block;
    /* Aligns the button text to center */
    margin: 0 auto;
    /* Additional centering for the button */
}

.container {
    min-height: 100vh;
    /* Ensure it covers the full viewport height */
    width: 100%;
    /* Ensure it covers the full viewport width */
    background-color: #E3FEDA;
    /* Adjust the path as needed */
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
}
</style>