<template>
    <Card>
        <template #title>
            <div class="flex justify-content-between align-items-center">
                <div>
                    <span class="font-medium text-lg">Plots</span>
                </div>
                <div>
                    <Button icon="pi pi-sync" @click="refreshData" :loading="mappingStore.isTableLoading"
                        class="mr-1" />
                    <StartRiskDetectionDialog />
                    <DeletePlotsButton />
                </div>
            </div>
        </template>
        <template #content>
            <DataTable v-model:selection="selectedRow" :value="mappingStore.mappingPlots" dataKey="_id" scrollable
                size="small" selectionMode="single" @rowSelect="onRowSelect" scrollHeight="calc(100vh - 160px)"
                class="my-custom-datatable p-datatable-sm">
                <template #empty>
                    <ProgressBar v-if="mappingStore.isTableLoading" mode="indeterminate" style="height: 2px">
                    </ProgressBar>
                    <div v-else
                        class="flex flex-column align-items-center justify-content-center text-center mt-4 select-none">
                        <img :src="placeholder" width="200px">
                        <div class="text-500">
                            Create plots with the mobile app or by drawing on the map.
                        </div>
                    </div>
                </template>
                <Column field="select" :showFilterMenu="false" frozen>
                    <template #header>
                        <Checkbox v-model="mappingStore.isEveryCheckboxSelected" :binary="true" @change="toggleAll" />
                    </template>
                    <template #body="slotProps">
                        <Checkbox v-model="mappingStore.selectedCheckboxes" :inputId="slotProps.data._id"
                            :value="slotProps.data._id" @change="checkAllCheckboxesStatus(slotProps)" />
                    </template>
                </Column>
                <Column field="plotId" header="Plot ID">
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]" />
                    </template>
                </Column>
                <Column field="batch.label" header="Batch" style="white-space: nowrap">
                    <template #editor="{ data, field }">
                        <Dropdown v-model="data[field]" :options="allBatches" optionLabel="label" showClear
                            style="min-width: 150px">
                        </Dropdown>
                    </template>
                </Column>
                <Column field="commodity" header="Commodity" style="white-space: nowrap">
                    <template #body="slotProps">
                        <div>
                            {{ slotProps.data.commodity }}
                            <span v-if="slotProps.data.commodity === 'Wood' && slotProps.data.treeSpecies">
                                ( {{ slotProps.data.treeSpecies.tree }} - {{ slotProps.data.treeSpecies.scientific }} )
                            </span>
                        </div>
                    </template>
                    <template #editor="{ data, field }">
                        <Dropdown v-model="data[field]" :options="commodities" style="width: 150px">
                        </Dropdown>
                    </template>
                </Column>
                <Column field="producerName" header="Producer name" style="white-space: nowrap">
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]" />
                    </template>
                </Column>
                <Column field="area" header="Area (ha)" style="white-space: nowrap">
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]" :disabled="data.geometry?.type !== 'Point'" />
                    </template>
                </Column>
                <Column field="country" header="Country">
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]" disabled />
                    </template>
                </Column>
                <Column field="additionalInfo" header="Additional information" style="white-space: nowrap">
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]" />
                    </template>
                </Column>
                <Column field="createdByUser" header="Created by" style="white-space: nowrap">
                    <template #body="{ data }">
                        <div class="flex flex-column">
                            <span class="font-medium">{{ data.createdByUser?.firstName }} {{
                                data.createdByUser?.lastName
                                }}</span>
                            <span class="text-sm pt-1">{{ data.createdByUser?.email }}</span>
                        </div>
                    </template>
                </Column>
                <Column field="" header="Actions" frozen alignFrozen="right">
                    <template #body="slotProps">
                        <div class="flex justify-content-center">
                            <Button icon="pi pi-pencil" outlined severity="secondary"
                                @click="showEditPlotDialog(slotProps)" class="ml-1" />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>

    <Dialog v-model:visible="isEditPlotDialogShown" header="Edit plot" modal :style="{ width: '30rem' }">
        <div class="field">
            <label class="font-bold">Plot ID <span style="color: red;">*</span></label>
            <InputText v-model="selectedRow.plotId" class="w-full" />
        </div>
        <div class="field">
            <label class="font-bold">Commodity <span style="color: red;">*</span></label>
            <Dropdown v-model="selectedRow.commodity" :options="commodities" class="w-full">
            </Dropdown>
        </div>
        <div class="field">
            <label class="font-bold">Batch <span style="color: red;">*</span></label>
            <Dropdown v-model="selectedRow.batch" :options="allBatches" optionLabel="label" class="w-full">
            </Dropdown>
        </div>
        <div class="field">
            <label class="font-bold">Producer name</label>
            <InputText v-model="selectedRow.producerName" class="w-full" />
        </div>
        <div class="field" v-if="selectedRow.commodity === 'Wood'">
            <label class="font-bold">Tree species <span style="color: red;">*</span></label>
            <Dropdown :model-value="getSelectedTreeSpecies(selectedRow.treeSpecies)"
                @update:model-value="updateTreeSpecies(selectedRow, $event)" :options="trees" optionLabel="tree"
                class=" w-full" placeholder="Select Tree Species" showClear>
                <template #value="slotProps">
                    <span v-if="slotProps.value && slotProps.value.tree">{{
                        slotProps.value?.tree }} ({{
                            slotProps.value?.scientific }})</span>
                    <span v-else>
                        {{ slotProps.placeholder }}
                    </span>
                </template>
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div>{{ slotProps.option.tree }} ({{ slotProps.option.scientific }})
                        </div>
                    </div>
                </template>
            </Dropdown>
        </div>
        <div class="field">
            <label class="font-bold">Area (ha)</label>
            <InputText v-model="selectedRow.area" class="w-full" :disabled="selectedRow.geometry?.type !== 'Point'" />
        </div>
        <div class="field">
            <label class="font-bold">Country</label>
            <InputText :model-value="selectedRow.country" class="w-full" disabled />
        </div>
        <div class="field">
            <label class="font-bold">Additional information</label>
            <Textarea v-model="selectedRow.additionalInfo" rows="5" cols="30" class="w-full" autoResize />
        </div>
        <div class="flex justify-content-end">
            <Button label="Cancel" class="mr-3" severity="secondary" @click="closeEditPlotDialog"></Button>
            <Button label="Save" @click="savePlotData" class="light-green-nadar"></Button>
        </div>
    </Dialog>
</template>

<script setup>
import { ref } from "vue";
import { useToast } from "primevue/usetoast";
import { commodities, trees } from '../../utils/constants';
import { useMappingStore } from "../../stores/mapping";
import placeholder from "../../assets/placeholder_plotReview.svg";
import { useBatchStore } from "../../stores/batch";
import { useMappingUtils } from "./composables/useMappingUtils";
import { useDataFormatUtils } from "../../composables/useDataFormatUtils";
import { usePagination } from "./composables/usePagination";
import { useDataAPI } from "../../composables/useDataAPI";
import { useAnalyticsAPI } from "../../composables/useAnalyticsAPI";
import StartRiskDetectionDialog from "./StartRiskDetectionDialog.vue";
import DeletePlotsButton from "./DeletePlotsButton.vue";
import * as Sentry from "@sentry/vue";


const { flyToPlotAndHighlightIt, refreshData } = useMappingUtils();
const { isNumber } = useDataFormatUtils();
const { toggleAll, checkAllCheckboxesStatus } = usePagination();
const { updatePlot } = useAnalyticsAPI();
const { getMappingPlots } = useDataAPI();
const batchStore = useBatchStore();
const toast = useToast();
const mappingStore = useMappingStore();
const selectedRow = ref();
const isEditPlotDialogShown = ref(false);
const selectedRowIndex = ref();
const allBatches = ref();


async function initializeTable() {
    mappingStore.isTableLoading = true;

    try {
        const response = await getMappingPlots();
        mappingStore.mappingPlots = response;
        mappingStore.isTableLoading = false;
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
    }

    await batchStore.fetchBatches();
    allBatches.value = batchStore.batches.map(batch => ({ _id: batch._id, label: batch.label }))
}

function onRowSelect(event) {
    if (event.data.geometry) {
        const feature = {
            geometry: event.data.geometry,
            properties: {
                featureId: event.data._id
            }
        }
        flyToPlotAndHighlightIt(feature);
    } else {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Plot has no geometry. You have to record it again.', life: 3000 });
        return
    }
}

async function savePlotData() {
    const isEverythingMandatoryFilledOut = checkMandatoryPlotInformation();
    if (!isEverythingMandatoryFilledOut) {
        return
    }

    isEditPlotDialogShown.value = false;
    const plotObjectId = selectedRow.value._id;
    const patchData = {
        commodity: selectedRow.value.commodity,
        producerName: selectedRow.value.producerName,
        plotId: selectedRow.value.plotId,
        additionalInfo: selectedRow.value.additionalInfo,
        batch: selectedRow.value.batch ? selectedRow.value.batch._id : null,
        treeSpecies: selectedRow.value.commodity === "Wood" ? selectedRow.value.treeSpecies : null
    }

    if (selectedRow.value.geometry.type === "Point") {
        patchData.area = parseFloat(selectedRow.value.area)
    }

    try {
        const response = await updatePlot(plotObjectId, patchData);
        toast.add({ severity: 'success', summary: 'Success', detail: 'Plot updated.', life: 3000 });
        await refreshData();
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Could not save plot.', life: 3000 });
    }
}

function showEditPlotDialog(event) {
    isEditPlotDialogShown.value = true;
    selectedRow.value = JSON.parse(JSON.stringify(event.data));
    selectedRowIndex.value = event.index;
}

function closeEditPlotDialog() {
    isEditPlotDialogShown.value = false;
}

function getSelectedTreeSpecies(selectedTreeSpecies) {
    const customerCommodity = trees.find(tree => tree.tree === selectedTreeSpecies?.tree);
    return customerCommodity
}

function updateTreeSpecies(rowData, newValue) {
    rowData.treeSpecies = newValue ? newValue : null;
}

function checkMandatoryPlotInformation() {
    if (!selectedRow.value.batch || !selectedRow.value.commodity) {
        toast.add({ severity: 'info', summary: 'Missing Fields', detail: "Please fill out all required fields.", life: 3000 });
        return false;
    }

    if (selectedRow.value.commodity === "Wood" && !selectedRow.value.treeSpecies?.tree) {
        toast.add({ severity: 'info', summary: 'Missing Fields', detail: "Please fill out all required fields.", life: 3000 });
        return false;
    }

    if (selectedRow.value.geometry.type === "Point") {
        // Explicitly check if the area is empty
        if (selectedRow.value.area === undefined || selectedRow.value.area === null || selectedRow.value.area === "") {
            toast.add({ severity: 'info', summary: 'Missing Area', detail: "The area field is required.", life: 3000 });
            return false;
        }

        // Check if the area is a numeric value
        if (!isNumber(selectedRow.value.area)) {
            toast.add({ severity: 'info', summary: 'Numeric Value', detail: "The area must be a numeric value.", life: 3000 });
            return false;
        }

        // Check if the area exceeds the limit
        if (selectedRow.value.area > 4) {
            toast.add({ severity: 'info', summary: 'Area Exceeded', detail: "The area must not exceed 4 ha.", life: 3000 });
            return false;
        }
    }

    return true;
}



initializeTable();
</script>