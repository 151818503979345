<template>
    <div class="flex justify-content-between">
        <span class="font-medium text-2xl">API Tokens</span>
        <Button label="Generate new token" @click="generateToken" :loading=isTokenGenerating />
    </div>

    <div class="grid">
        <div class="col-12">
            <Card class="mt-3">
                <template #content>
                    <div class="flex justify-content-end">
                    </div>
                    <div v-for="(token, index) in tokens" :key="token.token">
                        <div class="border-round border-1 p-3 mb-3 mt-3">
                            <div class="flex flex-column">
                                <div class="flex justify-content-between">
                                    <span>{{ formatTimestamp(token.createdAt) }}</span>
                                    <Button label="Delete" outlined severity="danger" class="button-width"
                                        @click="showDeleteTokenDialog(token.token)"></Button>
                                </div>
                                <div>
                                    <div class="flex flex-column gap-2">
                                        <div class="flex justify-content-between pt-3">
                                            <div class="flex-grow-1 mr-2">
                                                <IconField>
                                                    <InputIcon
                                                        :class="token.showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'"
                                                        @click="togglePasswordVisibility(index)" />
                                                    <InputText v-model="token.token"
                                                        :type="token.showPassword ? 'text' : 'password'" class="w-full"
                                                        readonly />
                                                </IconField>
                                            </div>
                                            <Button label="Copy" outlined class="button-width"
                                                @click="copyToClipboard(token.token)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
    </div>

    <Dialog v-model:visible="isDeleteTokenDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column px-5 py-5 gap-4">
                <span class="text-lg font-medium">Delete access token?</span>
                <span>Selected token will be deleted. You can't undo this action.</span>
                <div class="flex justify-content-end">
                    <Button label="Cancel" severity="secondary" class="mr-3" @click="closeCallback"></Button>
                    <Button label="Delete" severity="danger" @click="deleteAccessToken"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useAnalyticsAPI } from "../composables/useAnalyticsAPI";
import * as Sentry from "@sentry/vue";


const { createNewApiToken, deleteToken, getAllTokens } = useAnalyticsAPI();
const isDeleteTokenDialogShown = ref(false);
const selectedTokenToDelete = ref();
const tokens = ref([]);
const isTokenGenerating = ref(false);


function copyToClipboard(token) {
    navigator.clipboard.writeText(token)
    toast.add({ severity: 'info', summary: 'Copied to clipboard', life: 3000 });
}

function togglePasswordVisibility(index) {
    // Toggle the showPassword state for the clicked token
    tokens.value[index].showPassword = !tokens.value[index].showPassword;
}

function formatTimestamp(timestamp, locale = 'en-US') {
    // Ensure the timestamp is treated as UTC by appending 'Z' if necessary
    const utcDate = new Date(timestamp.endsWith('Z') ? timestamp : timestamp + 'Z');

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,  // 24-hour format
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Use the user's local timezone
    };

    // Convert the UTC timestamp to the user's local time
    const localTime = utcDate.toLocaleString(locale, options);
    return localTime;
}

function showDeleteTokenDialog(token) {
    selectedTokenToDelete.value = token;
    isDeleteTokenDialogShown.value = true;
}

async function deleteAccessToken() {
    const tokenToDelete = { token: selectedTokenToDelete.value }
    const response = await deleteToken(tokenToDelete);
    tokens.value = tokens.value.filter(token => token.token !== selectedTokenToDelete.value)
    isDeleteTokenDialogShown.value = false;
}

async function generateToken() {
    try {
        isTokenGenerating.value = true;
        const response = await createNewApiToken();
        tokens.value.unshift(response)
    } catch (error) {
        Sentry.captureException(error);
        console.log(error)
    } finally {
        isTokenGenerating.value = false;
    }
}

async function getTokens() {
    try {
        const response = await getAllTokens();
        tokens.value = response;
    } catch (error) {
        Sentry.captureException(error);
        console.log(error)
    }
}


getTokens();
</script>