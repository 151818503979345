import { defineStore } from 'pinia'
import { ImportMode } from '../utils/constants'

export const useDataImportStore = defineStore('dataImport', {
    state: () => ({
        parsedData: [],
        plotsWithUserDetails: [],
        currentImportMode: ImportMode.IDLE,
        stepOfStepper: 0,
        columnNames: [],
        columnNamesDataTableFormat: [],
        selectedAreaColumn: null,
        selectedPlotIdColumn: null,
        selectedProducerNameColumn: null,
        fileType: null,
        file: null,

        isTableFilesLoading: true, // MUST be true as default value because we want to initialize the table loading with page load
        tableFiles: [],
        isDataImportDialogShown: false,
        isDataImportSuccessDialogShown: false,
        isDataImportFailureDialogShown: false,
        isFileProcessingDialogShown: false,
        isInvalidGeometryDialogShown: false,
        invalidGeometryDialogMessage: [],
        isInvalidFileDialogShown: false,
        invalidFileDialogMessage: "",
        isInvalidAreaCellDialogShown: false,
        invalidAreaCellDialogMessage: []
    }),
    actions: {
        resetStepper() {
            this.parsedData = [];
            this.plotsWithUserDetails = [];
            this.currentImportMode = ImportMode.IDLE;
            this.stepOfStepper = 0;
            this.columnNames = [];
            this.columnNamesDataTableFormat = [];
            this.selectedAreaColumn = null;
            this.selectedPlotIdColumn = null;
            this.selectedProducerNameColumn = null;
            this.fileType = null;
            this.file = null;
        },
        showDataImportDialog() {
            this.isDataImportDialogShown = true;
        },
        closeDataImportDialog() {
            this.isDataImportDialogShown = false;
        },
        showDataImportSuccessDialog() {
            this.isDataImportSuccessDialogShown = true;
        },
        showDataImportFailureDialog() {
            this.isDataImportFailureDialogShown = true;
        },
        showFileProcessingDialog() {
            this.isFileProcessingDialogShown = true;
        },
        closeFileProcessingDialog() {
            this.isFileProcessingDialogShown = false;
        },
        showInvalidGeometryDialog(message) {
            this.invalidGeometryDialogMessage = message;
            this.isInvalidGeometryDialogShown = true;
        },
        closeInvalidGeometryDialog() {
            this.invalidGeometryDialogMessage = [];
            this.isInvalidGeometryDialogShown = false; // Clear the message when dialog is closed
        },
        showInvalidFileDialog(message) {
            this.invalidFileDialogMessage = message;
            this.isInvalidFileDialogShown = true;
        },
        closeInvalidFileDialog() {
            this.invalidFileDialogMessage = ''; // Clear the message when dialog is closed
            this.isInvalidFileDialogShown = false;
        },
        showInvalidAreaCellDialog(message) {
            this.invalidAreaCellDialogMessage = message;
            this.isInvalidAreaCellDialogShown = true;
        },
        closeInvalidAreaCellDialog() {
            this.invalidAreaCellDialogMessage = [];
            this.isInvalidAreaCellDialogShown = false;
        }
    }
})