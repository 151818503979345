<template>
    <Dialog v-model:visible="riskAssessmentStore.isIndigenousQuestionnaireDialogShown" modal
        header="Presence of Indigenous Peoples" :style="{ width: '90rem' }">
        <div class="flex flex-column">
            <Card>
                <template #content>
                    <span>
                        1. Has there been consultation and cooperation in good faith with indigenous peoples regarding
                        the relevant product?
                    </span>
                    <SelectButton class="mt-3" v-model="firstSelectedAnswer" :options="options"
                        @change="clearFirstAnswer" />
                    <div v-if="firstSelectedAnswer == 'Yes'" class="mt-3">
                        <span>Please provide details of the consultation and cooperation that took place with indigenous
                            peoples and/or upload any relevant documents that provide further details.</span>
                        <Textarea v-model="firstAnswer" rows="5" class="mt-3 w-full" />

                        <div class="flex justify-content-between mt-3">
                            <div class="w-full p-3 border-1 border-round border-dashed border-gray-300 dotted-border flex justify-content-center align-items-center cursor-pointer"
                                @click="triggerFileInput" @drop.prevent="handleDrop" @dragover.prevent
                                @dragenter.prevent>
                                <div class="flex flex-column align-items-center">
                                    <span class="font-medium">Drag & drop files or</span>
                                    <Button label="Select files" outlined class="mt-2" />
                                </div>
                            </div>
                            <input type="file" ref="fileInput" multiple class="hidden" @change="handleFileSelect" />
                        </div>

                        <!-- Display selected file names with remove option -->
                        <div v-if="selectedFiles.length > 0" class="mt-3">
                            <div v-for="(file, index) in selectedFiles" :key="index"
                                class="flex justify-content-between align-items-center border-round border-1 p-3 border-gray-300 mt-2">
                                <span>{{ file.name }}</span>
                                <Button label="Remove" icon="pi pi-times" class="p-button-text p-button-danger"
                                    @click="removeFile(index)" />
                            </div>
                        </div>
                    </div>
                </template>
            </Card>

            <div class="mt-3">
                <Card>
                    <template #content>
                        <span>
                            2. Are there any duly reasoned claims made by indigenous peoples related to the relevant
                            product?
                        </span>
                        <SelectButton class="mt-3" v-model="secondSelectedAnswer" :options="options"
                            @change="clearSecondAnswer" />
                        <div v-if="secondSelectedAnswer == 'Yes'" class="mt-3">
                            <span>Please describe the duly reasoned claims made by indigenous peoples and how they have
                                been addressed, and/or upload any relevant documents that provide further
                                details.</span>
                            <Textarea v-model="secondAnswer" rows="5" class="mt-3 w-full" />

                            <div class="flex justify-content-between mt-3">
                                <div class="w-full p-3 border-1 border-round border-dashed border-gray-300 dotted-border flex justify-content-center align-items-center cursor-pointer"
                                    @click="triggerFileInput" @drop.prevent="handleDrop" @dragover.prevent
                                    @dragenter.prevent>
                                    <div class="flex flex-column align-items-center">
                                        <span class="font-medium">Drag & drop files or</span>
                                        <Button label="Select files" outlined class="mt-2" />
                                    </div>
                                </div>
                                <input type="file" ref="fileInput" multiple class="hidden" @change="handleFileSelect" />
                            </div>

                            <!-- Display selected file names with remove option -->
                            <div v-if="selectedFiles.length > 0" class="mt-3">
                                <div v-for="(file, index) in selectedFiles" :key="index"
                                    class="flex justify-content-between align-items-center border-round border-1 p-3 border-gray-300 mt-2">
                                    <span>{{ file.name }}</span>
                                    <Button label="Remove" icon="pi pi-times" class="p-button-text p-button-danger"
                                        @click="removeFile(index)" />
                                </div>
                            </div>
                        </div>
                    </template>
                </Card>
            </div>

            <div class="flex justify-content-end mt-3">
                <Button label="Cancel" severity="secondary" class="mr-3"
                    @click="riskAssessmentStore.closeIndigenousQuestionnaireDialog()" />
                <Button label="Save" @click="riskAssessmentStore.closeIndigenousQuestionnaireDialog()" />
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useRiskAssessmentStore } from '../../stores/riskAssessment';

const riskAssessmentStore = useRiskAssessmentStore();
const options = ref(['No', 'Yes']);
const firstSelectedAnswer = ref('No');
const firstAnswer = ref();
const secondSelectedAnswer = ref("No");
const secondAnswer = ref();
const selectedFiles = ref([]);
const fileInput = ref(null);


function clearFirstAnswer() {
    firstAnswer.value = null;
}

function clearSecondAnswer() {
    secondAnswer.value = null;
}

// Trigger file input on div click
function triggerFileInput() {
    fileInput.value.click();
}

// Handle file selection
function handleFileSelect(event) {
    const files = Array.from(event.target.files);
    selectedFiles.value.push(...files);
}

// Handle file drop
function handleDrop(event) {
    const files = Array.from(event.dataTransfer.files);
    selectedFiles.value.push(...files);
}

// Remove selected file
function removeFile(index) {
    selectedFiles.value.splice(index, 1);
}
</script>
