<template>
    <Dialog v-model:visible="mappingStore.isRiskDetectionSuccessDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column m-4 p-2 gap-2 justify-content-center">
                <span class="text-lg">Risk detection completed</span>
                <span class="block mb-3">
                    The risk detection process has been completed successfully. Please review the results in the batch
                    under the Batch Page where you assigned the plot.
                </span>
                <div class="flex justify-content-end gap-2">
                    <Button class="light-green-nadar" label="OK" @click="closeCallback"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { useMappingStore } from '../../stores/mapping';

const mappingStore = useMappingStore();
</script>