<template>
    <InlineMessage severity="info" class="w-full">Please review the information about your organization, as it will be
        designated as the operator/trader responsible for submitting the due diligence statement.</InlineMessage>

    <div class="grid mt-3">
        <div class="col-12">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Organization name <span style="color:red">*</span></label>
                <InputText v-model="organization.organizationName" readonly />
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Country <span style="color:red">*</span></label>
                <InputText v-model="organization.country" filter readonly />
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">City <span style="color:red">*</span></label>
                <InputText v-model="organization.city" readonly />
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Street and number <span style="color:red">*</span></label>
                <InputText v-model="organization.street" readonly />
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Postal code <span style="color:red">*</span></label>
                <InputText v-model="organization.postalCode" readonly />
            </div>
        </div>
        <div class="col-12">
            <div class="flex flex-column gap-2">
                <label class="font-medium">EORI number <span style="color:red">*</span></label>
                <InputText v-model="organization.eori" readonly />
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Email <span style="color:red">*</span></label>
                <InputText v-model="organization.email" readonly />
            </div>
        </div>
        <div class="col-6">
            <div class="flex flex-column gap-2">
                <label class="font-medium">Phone <span style="color:red">*</span></label>
                <InputText v-model="organization.phone" readonly />
            </div>
        </div>
    </div>
    <div class="flex pt-4 justify-content-between">
        <Button label="Cancel" severity="secondary" @click="cancelStatementPreparation" />
        <Button label="Next" @click="handleNextStep(nextCallback)" />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useDueDiligenceStore } from '../../stores/duediligence';
import { useOrganizationStore } from "../../stores/organization";
import { useToast } from 'primevue/usetoast';

const props = defineProps({
    nextCallback: {
        type: Function,
        required: true
    },
    prevCallback: {
        type: Function,
        required: true
    }
});

const organizationStore = useOrganizationStore();
const dueDiligenceStore = useDueDiligenceStore();
const toast = useToast();
const organization = ref({});

function checkRequiredFields() {
    if (!organization.value.organizationName) {
        toast.add({ severity: 'info', summary: 'Missing field', detail: "Please select all required fields.", life: 3000 });
        return false;
    }

    if (!organization.value.country) {
        toast.add({ severity: 'info', summary: 'Missing field', detail: "Please select all required fields.", life: 3000 });
        return false;
    }

    if (!organization.value.city) {
        toast.add({ severity: 'info', summary: 'Missing field', detail: "Please select all required fields.", life: 3000 });
        return false;
    }

    if (!organization.value.eori) {
        toast.add({ severity: 'info', summary: 'Missing field', detail: "Please select all required fields.", life: 3000 });
        return false;
    }

    if (!organization.value.street) {
        toast.add({ severity: 'info', summary: 'Missing field', detail: "Please select all required fields.", life: 3000 });
        return false;
    }

    if (!organization.value.postalCode) {
        toast.add({ severity: 'info', summary: 'Missing field', detail: "Please select all required fields.", life: 3000 });
        return false;
    }

    if (!organization.value.email) {
        toast.add({ severity: 'info', summary: 'Missing field', detail: "Please select all required fields.", life: 3000 });
        return false;
    }

    if (!organization.value.phone) {
        toast.add({ severity: 'info', summary: 'Missing field', detail: "Please select all required fields.", life: 3000 });
        return false;
    }

    return true;
}

function cancelStatementPreparation() {
    dueDiligenceStore.isCreateStatementDialogShown = false;
    dueDiligenceStore.$reset();
}

function handleNextStep(nextCallback) {
    if (!checkRequiredFields()) {
        return
    }

    nextCallback();
}


async function fetchData() {
    console.log("fetching data")
    await organizationStore.fetchCurrentOrganization();
    organization.value = organizationStore.organization;
}

fetchData();
</script>