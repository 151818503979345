<template>
    <span class="font-medium text-lg pt-4">Supply Chain Details</span>
    <div class="flex flex-column">
        <div class="py-2">
            <label>Commodity *</label>
            <div class="pt-1">
                <Dropdown v-model="selectedCommodity" :options="commodities" placeholder="Select"
                    :invalid="isCommodityFieldMissing" class="w-full" @change="resetTreeSpecies">
                </Dropdown>
                <small>Select a commodity for all plots within the imported file.
                </small>
            </div>
        </div>
        <div v-if="selectedCommodity === 'Wood'">
            <span>Tree Species *</span>
            <div class="pt-1">
                <Dropdown v-model="selectedTreeSpecies" :options="trees" optionLabel="tree" placeholder="Select"
                    class="w-full" showClear>
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex align-items-center">
                            <div>{{ slotProps.value.tree }} ({{ slotProps.value.scientific }})</div>
                        </div>
                        <span v-else>
                            {{ slotProps.placeholder }}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div class="flex align-items-center">
                            <div>{{ slotProps.option.tree }} ({{ slotProps.option.scientific }})</div>
                        </div>
                    </template>
                </Dropdown>
                <small>Select a supplier for all plots within the imported file.
                </small>
            </div>
        </div>
        <span>Supplier</span>
        <div class="pt-1">
            <Dropdown v-model="selectedSupplier" :options="supplierStore.allSuppliers" optionLabel="name"
                placeholder="Select" class="w-full" showClear />
            <small>Select a supplier for all plots within the imported file.
            </small>
        </div>
    </div>
    <div class="flex pt-4 justify-content-between">
        <Button label="Back" severity="secondary" @click="props.prevCallback" />
        <Button label="Import Plots" @click="handleSavePlotSettings" :loading="isImportPlotsLoading" />
    </div>
</template>

<script setup>
import { ref, toRaw, onMounted } from "vue";
import { useToast } from "primevue/usetoast";
import { useDataImportStore } from "../../stores/dataImport";
import { useSupplierStore } from "../../stores/supplier";
import { commodities } from '../../utils/constants';
import { trees } from "../../utils/constants";
import { useAnalyticsAPI } from "../../composables/useAnalyticsAPI";
import * as Comlink from 'comlink';
import * as Sentry from "@sentry/vue";


const props = defineProps({
    prevCallback: {
        type: Function,
        required: true
    }
});

const { createBatchInDataImport, getPollStatus, getAllFiles } = useAnalyticsAPI();
const dataImportStore = useDataImportStore();
const supplierStore = useSupplierStore();
const toast = useToast();
const selectedSupplier = ref();
const isCommodityFieldMissing = ref(false);
const isTreeSpeciesFieldMissing = ref(false);
const selectedCommodity = ref();
const selectedTreeSpecies = ref();
const workerInstance = ref();
const isImportPlotsLoading = ref(false);


function resetTreeSpecies() {
    selectedTreeSpecies.value = null;
}

async function handleSavePlotSettings() {
    isCommodityFieldMissing.value = false;

    if (!selectedCommodity.value) {
        toast.add({ severity: 'info', summary: 'Missing fields', detail: 'You have to select a commodity.', life: 3000 });
        isCommodityFieldMissing.value = true;
        return;
    }
    if (selectedCommodity.value === 'Wood') {
        if (!selectedTreeSpecies.value) {
            toast.add({ severity: 'info', summary: 'Missing fields', detail: 'You have to select a tree species.', life: 3000 });
            isTreeSpeciesFieldMissing.value = true;
            return;
        }
    }

    for (const plot of dataImportStore.plotsWithUserDetails) {
        plot.plotId = plot.properties[dataImportStore.selectedPlotIdColumn];
        plot.supplier = selectedSupplier.value;

        if (dataImportStore.selectedAreaColumn) {
            plot.area = plot.properties[dataImportStore.selectedAreaColumn];
        }
        if (dataImportStore.selectedProducerNameColumn) {
            plot.producerName = plot.properties[dataImportStore.selectedProducerNameColumn];
        }

        plot.commodity = selectedCommodity.value;

        if (selectedCommodity.value === 'Wood') {
            plot.treeSpecies = selectedTreeSpecies.value;
        }
    }

    await importPlots();
}

function initializeWorker() {
    const worker = new Worker(new URL('./importWorker.js', import.meta.url), { type: 'module' })

    worker.onerror = (error) => {
        Sentry.captureException(error);
        console.log(error);
        workerInstance.value = null;  // Reset the instance on error
    };
    workerInstance.value = Comlink.wrap(worker);
}

async function getWorker() {
    if (!workerInstance.value) {
        initializeWorker();
    }

    return workerInstance;
}

async function preparePlotsForUpload() {
    const comlinkWorker = await getWorker();
    const plotsWithUserDetails = toRaw(dataImportStore.plotsWithUserDetails);
    const areaColumn = toRaw(dataImportStore.selectedAreaColumn);
    const serializedData = JSON.stringify({ plotsWithUserDetails, areaColumn });
    const preparedData = await comlinkWorker.value.preparePlots(serializedData);

    return preparedData
}

async function pollMultipleJobs(jobs) {
    const pollInterval = 3000;

    return new Promise((resolve, reject) => {
        const statuses = {};

        const checkStatuses = async () => {
            try {
                const pendingJobs = jobs.filter(job => !statuses[job.id]); // Only poll unfinished jobs

                if (pendingJobs.length === 0) {
                    // All jobs complete
                    console.log("All jobs complete:", statuses);
                    resolve(statuses);
                    return;
                }

                for (const job of pendingJobs) {
                    const response = await getPollStatus(job.id);
                    console.log(`${job.type} job status:`, response);

                    if (response.job_status === "complete") {
                        statuses[job.id] = { status: "complete", result: response.result };
                    } else if (response.job_status === "error") {
                        statuses[job.id] = { status: "error" };
                        reject(new Error(`${job.type} job failed`));
                        return;
                    } else {
                        console.log(`${job.type} job still in progress...`);
                    }
                }

                // Retry after interval
                setTimeout(checkStatuses, pollInterval);
            } catch (error) {
                console.error("Error while polling multiple jobs:", error);
                reject(error);
            }
        };

        checkStatuses();
    });
}

async function pollJobStatus(jobId) {
    const pollInterval = 3000;

    return new Promise((resolve, reject) => {
        const checkStatus = async () => {
            try {
                const response = await getPollStatus(jobId);
                console.log(response);

                // Handle batch task completion
                if (response.job_status === "complete" || response.job_status === "error") {
                    console.log("Batch task completed, polling dependent tasks...");
                    if (response.result?.verification_job_id && response.result?.deforestation_job_id) {
                        const verificationJobId = response.result.verification_job_id;
                        const deforestationJobId = response.result.deforestation_job_id;

                        // Poll verification and deforestation tasks
                        const results = await pollMultipleJobs([
                            { id: verificationJobId, type: "Verification" },
                            { id: deforestationJobId, type: "Deforestation" },
                        ]);

                        resolve({ job_status: "complete", result: "success" });
                    }

                    resolve(response);
                } else if (response.job_status === "queued" || response.job_status === "in_progress" || response.job_status === "deferred") {
                    console.log("Job still in progress...");
                    setTimeout(checkStatus, pollInterval);
                }
            } catch (error) {
                console.error("Error while polling job status:", error);
                reject(error);
            }
        };

        checkStatus();
    });
}

async function importPlots() {
    isImportPlotsLoading.value = true;
    const dataToSend = await preparePlotsForUpload();
    const formData = new FormData();

    formData.append("data", JSON.stringify(dataToSend));
    formData.append("file", dataImportStore.file);

    let job;
    try {
        const response = await createBatchInDataImport(formData);
        job = await pollJobStatus(response.job_id);
        dataImportStore.tableFiles = await getAllFiles();

        if (job.result === "success") {
            dataImportStore.closeDataImportDialog()
            dataImportStore.showDataImportSuccessDialog();
        } else { // result === FAILURE
            dataImportStore.showDataImportFailureDialog();
        }
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        dataImportStore.showDataImportFailureDialog();
    } finally {
        isImportPlotsLoading.value = false;
    }
}

onMounted(async () => {
    try {
        await supplierStore.fetchAllSuppliers();
        console.log(supplierStore.allSuppliers)
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        toast.add({ severity: 'error', summary: 'Error', detail: "Could not fetch suppliers.", life: 3000 });
    }
})
</script>