<template>
    <div class="duediligence-container">
        <div class="flex justify-content-between">
            <span class="font-medium text-3xl">Risk Assessments</span>
        </div>
        <Card class="mt-3">
            <template #content>
                <DataTable v-model:expandedRows="expandedRows" @rowExpand="onRowExpand" :value="dummyItems"
                    tableStyle="min-width: 50rem">
                    <Column expander style="width: 5rem" />
                    <Column field="supplier" header="Supplier"></Column>
                    <Column field="commodity" header="Commodity"></Column>
                    <Column field="country" header="Country"></Column>
                    <Column field="risk" header="Risk"></Column>
                    <Column field="riskIndicators" header="Risk Indicators">
                        <template #body="{ data }">
                            <Tag v-for="indicator in data.riskIndicators" :severity="getSeverity(indicator.status)"
                                class="mr-1">
                                {{ indicator.label }}
                            </Tag>
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="flex flex-column mt-4">
                            <span class="text-xl font-bold">Risk Assessment Supplier: {{ slotProps.data.supplier
                                }}</span>
                            <Card class="mt-4 shadow-none">
                                <template #content>
                                    <div class="flex flex-column">
                                        <div>
                                            <span class="font-bold text-lg pr-2">1. EU Country Risk Benchmarking</span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '1')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label
                                                    ===
                                                    '1')?.status }}
                                            </Tag>
                                        </div>
                                        <span>
                                            This risk indicator follows Article 10 (2) a): the assignment of risk to the
                                            relevant
                                            country of production or parts thereof in accordance with Article 29.
                                        </span>
                                        <span class="mt-4">
                                            A benchmarking system operated by the Commission will classify countries, or
                                            parts
                                            thereof, in three categories (high, standard and low risk) according to the
                                            level of
                                            risk of producing in such countries commodities that are not
                                            deforestation-free.
                                        </span>
                                        <span>
                                            The criteria for the identification of the risk status of countries or parts
                                            thereof
                                            are
                                            defined in Article 29 of the Regulation (EU) 2023/1115.
                                        </span>
                                        <span>
                                            Member States and third countries, or parts thereof, shall be classified
                                            into one of
                                            the following risk categories:
                                        </span>
                                        <span>
                                            (a) ‘high risk’ refers to countries or parts thereof, for which the
                                            assessment
                                            referred
                                            to in paragraph 3 results in the identification of a high risk of producing
                                            in
                                            such
                                            countries or in parts thereof, relevant commodities for which the relevant
                                            products
                                            do
                                            not comply with Article 3, point (a);
                                        </span>
                                        <span>
                                            (b) ‘low risk’ refers to countries or parts thereof, for which the
                                            assessment
                                            referred to
                                            in paragraph 3 concludes that there is sufficient assurance that instances
                                            of
                                            producing
                                            in such countries or in parts thereof, relevant commodities for which the
                                            relevant
                                            products do not comply with Article 3, point (a), are exceptional;
                                        </span>
                                        <span>
                                            (c) ‘standard risk’ refers to countries or parts thereof which do not fall
                                            in either
                                            the
                                            category ‘high risk’ or the category ‘low risk’.
                                        </span>
                                        <span>
                                            More information can be found here:
                                            https://green-business.ec.europa.eu/deforestation-regulation-implementation/benchmarking-partnerships_en
                                        </span>
                                        <span class="mt-4">
                                            Our risk level assignment follows Article 29 (2): On 29 June 2023, all
                                            countries
                                            shall
                                            be assigned a standard level of risk.
                                        </span>
                                    </div>
                                </template>
                            </Card>

                            <Card class="shadow-none mt-4">
                                <template #content>
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center">
                                            <span class="font-bold text-lg pr-2">2.1 Presence of Forests</span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '2.1')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label ===
                                                    '2.1')?.status }}
                                            </Tag>
                                        </div>
                                        <div id="presenceOfForestsMap" class="border-round mt-3">
                                            <img :src="nadarLogo" class="mapbox-nadar-logo" width="60">
                                            <MapboxAttributions />
                                            <div id="presence-of-forests-legend" class="legend">
                                                <div class="flex justify-content-between align-items-center">
                                                    <div class="font-medium mr-1">Presence of Forests</div>
                                                    <i :class="['pi', layerVisible ? 'pi-eye' : 'pi-eye-slash']"
                                                        @click="toggleLayerVisibilityForests"
                                                        style="cursor: pointer;"></i>
                                                </div>
                                                <div class="text-xs mt-2">Percentage of Forest Cover</div>
                                                <div class="text-xs"><span style="background-color: #006400"></span>
                                                    >70%
                                                </div>
                                                <div class="text-xs"><span style="background-color: #228B22"></span> 50%
                                                    - 70%
                                                </div>
                                                <div class="text-xs"><span style="background-color: #32CD32"></span> 30%
                                                    - 50%</div>
                                                <div class="text-xs"><span style="background-color: #7CFC00"></span> 10%
                                                    - 30%</div>
                                                <div class="text-xs"><span style="background-color: #ADFF2F"></span>
                                                    < 10%</div>
                                                </div>
                                            </div>
                                            <span class="mt-3">
                                                This risk indicator follows Article 10 (2) b): the presence of forests
                                                in
                                                the
                                                country
                                                of
                                                production or parts thereof.
                                            </span>
                                            <span class="mt-4">
                                                We apply the dataset “Global forest cover 2020” of the Joint Research
                                                Centre
                                                to
                                                assess
                                                the presence of forests in December 2020. The global map of forest cover
                                                was
                                                created
                                                by
                                                combining available global datasets (wall-to-wall or global in their
                                                scope)
                                                on tree
                                                cover, tree height, land cover and land use into a single harmonized
                                                globally-consistent
                                                representation of where forests existed in 2020.
                                            </span>
                                            <span>
                                                The dataset can be accessed via: https://forobs.jrc.ec.europa.eu/GFC
                                            </span>
                                            <Divider />
                                            <div class="flex align-items-center">
                                                <span class="font-bold text-lg pr-2">2.2 Protected Areas</span>
                                                <Tag
                                                    :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '2.2')?.status)">
                                                    {{ slotProps.data.riskIndicators.find(indicator => indicator.label
                                                        ===
                                                        '2.2')?.status }}
                                                </Tag>
                                            </div>
                                            <div id="protectedAreasMap" class="border-round mt-3">
                                                <img :src="nadarLogo" class="mapbox-nadar-logo" width="60">
                                                <MapboxAttributions />
                                                <div id="protected-areas-legend" class="legend">
                                                    <div class="flex justify-content-between align-items-center">
                                                        <div class="font-medium mr-1">Protected Areas</div>
                                                        <i :class="['pi', layerVisible ? 'pi-eye' : 'pi-eye-slash']"
                                                            @click="toggleLayerVisibilityProtected"
                                                            style="cursor: pointer;"></i>
                                                    </div>
                                                    <div class="text-xs mt-2">Conservation Areas</div>
                                                    <div class="text-xs">
                                                        <span style="background-color: #008080"></span> Protected Area
                                                    </div>
                                                </div>
                                            </div>
                                            <span class="mt-3">
                                                Nadar’s risk assessment methodology applies data from Open Street Map
                                                (OSM)
                                                and
                                                national
                                                databases to assess whether the plots of land are located in protected
                                                or
                                                conserved
                                                areas implemented for biodiversity conservation such as national parks,
                                                wilderness
                                                areas, community conserved areas, and nature reserves.
                                            </span>
                                        </div>
                                </template>
                            </Card>

                            <Card class="shadow-none mt-4">
                                <template #content>
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-between">
                                            <div>
                                                <span class="font-bold text-lg pr-2">3. Presence of Indigenous
                                                    Peoples</span>
                                                <Tag
                                                    :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '3')?.status)">
                                                    {{ slotProps.data.riskIndicators.find(indicator => indicator.label
                                                        ===
                                                        '3')?.status }}
                                                </Tag>
                                            </div>
                                            <Button label="Show Questionnaire" outlined size="small"
                                                @click="riskAssessmentStore.showIndigenousQuestionnaireDialog()">
                                                <img :src="questionnaire_icon" alt="Questionnaire Icon"
                                                    style="width: 20px; height: 20px; background-color: white;" />
                                            </Button>
                                        </div>
                                        <div id="presenceOfIndigenousPeoplesMap" class="border-round mt-3">
                                            <img :src="nadarLogo" class="mapbox-nadar-logo" width="60">
                                            <MapboxAttributions />
                                            <div id="indigenous-legend" class="legend">
                                                <div class="flex justify-content-between align-items-center">
                                                    <div class="font-medium mr-1">Presence of Indigenous Peoples</div>
                                                    <i :class="['pi', layerVisible ? 'pi-eye' : 'pi-eye-slash']"
                                                        @click="toggleLayerVisibilityIndigenous"
                                                        style="cursor: pointer;"></i>
                                                </div>
                                                <div class="text-xs mt-2">Indigenous Territories</div>
                                                <div class="text-xs">
                                                    <span style="background-color: #800080"></span> Indigenous Land
                                                </div>
                                            </div>
                                        </div>
                                        <span class="mt-3">
                                            This risk indicator follows Article 10 (2) c): the presence of indigenous
                                            peoples in
                                            the
                                            country of production or parts thereof.
                                        </span>
                                        <span class="mt-4">
                                            Nadar’s risk assessment methodology applies data from LandMark, RAISG and
                                            national
                                            databases on indigenous lands to assess the presence of indigenous peoples.
                                        </span>
                                        <span class="mt-4">
                                            The LandMark Global Platform of Indigenous and Community Lands is a
                                            consortium of
                                            local,
                                            regional, and international organizations that have a mission to support
                                            Indigenous
                                            Peoples' and local communities' land and resource rights globally. They have
                                            created
                                            the
                                            first online, interactive global platform that is designed to help
                                            Indigenous
                                            Peoples
                                            and local communities protect their land rights and secure tenure over their
                                            lands.
                                        </span>
                                        <span>
                                            The data is accessible via: https://www.landmarkmap.org/map
                                        </span>
                                        <span class="mt-4">
                                            The RAISG (Red Amazónica de Información Socioambiental Georreferenciada)
                                            Amazon
                                            Network
                                            of Georeferenced Socio-Environmental Information is a consortium of civil
                                            society
                                            organizations from the Amazon countries, supported by international
                                            partners,
                                            concerned
                                            with the socio-environmental sustainability of Amazonia. RAIS gathers data
                                            on
                                            indigenous
                                            territories in the Amazon region.
                                        </span>
                                        <span>
                                            The data is accessible via:
                                            https://www3.socioambiental.org/geo/RAISGMapaOnline/
                                        </span>
                                    </div>
                                </template>
                            </Card>

                            <Card class="shadow-none mt-4">
                                <template #content>
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center">
                                            <span class="font-bold pr-2">
                                                4. Consultation and cooperation with indigenous
                                                peoples
                                            </span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '4')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label ===
                                                    '4')?.status }}
                                            </Tag>
                                        </div>
                                    </div>
                                    <span class="mt-3">
                                        This risk indicator follows Article 10 (2) d): the consultation and cooperation
                                        in good
                                        faith with indigenous peoples in the country of production or parts thereof.
                                    </span>
                                </template>
                            </Card>

                            <Card class="shadow-none mt-4">
                                <template #content>
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center">
                                            <span class="font-bold pr-2">5. Existence of duly reasoned claims by
                                                indigenous
                                                peoples</span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '5')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label ===
                                                    '5')?.status }}
                                            </Tag>
                                        </div>
                                    </div>
                                    <span class="mt-3">
                                        This risk indicator follows Article 10 (2) e): the existence of duly reasoned
                                        claims by
                                        indigenous peoples based on objective and verifiable information regarding the
                                        use or
                                        ownership of the area used for the purpose of producing the relevant commodity.
                                    </span>
                                </template>
                            </Card>

                            <Card class="shadow-none mt-4">
                                <template #content>
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center">
                                            <span class="font-bold pr-2">
                                                6. Prevalence of deforestation or forest degradation
                                            </span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '6')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label ===
                                                    '6')?.status }}
                                            </Tag>
                                        </div>
                                        <div id="prevalenceOfDeforestationOrForestDegradationMap"
                                            class="border-round mt-3">
                                            <img :src="nadarLogo" class="mapbox-nadar-logo" width="60">
                                            <MapboxAttributions />
                                            <div id="prevalence-of-deforestation-legend" class="legend">
                                                <div class="flex justify-content-between align-items-center">
                                                    <div class="font-medium mr-1">Prevalence of Deforestation</div>
                                                    <i :class="['pi', layerVisible ? 'pi-eye' : 'pi-eye-slash']"
                                                        @click="toggleLayerVisibilityDeforestation"
                                                        style="cursor: pointer;"></i>
                                                </div>
                                                <div class="text-xs mt-2">Annual Percentage of Deforestation</div>
                                                <div class="text-xs"><span style="background-color: #CC0000"></span> >
                                                    1.5%</div>
                                                <div class="text-xs"><span style="background-color: #FF3333"></span> 1%
                                                    - 1.5%</div>
                                                <div class="text-xs"><span style="background-color: #FF6666"></span>
                                                    0.5% - 1%</div>
                                                <div class="text-xs"><span style="background-color: #FF9999"></span>
                                                    0.1% - 0.5%</div>
                                                <div class="text-xs"><span style="background-color: #FFE6E6"></span>
                                                    < 0.1%</div>
                                                </div>
                                            </div>
                                            <span class="mt-3">
                                                This risk indicator follows Article 10 (2) f): prevalence of
                                                deforestation
                                                or forest
                                                degradation in the country of production or parts thereof. 
                                            </span>
                                            <span class="mt-4">
                                                For tropical forest regions we apply the “Tropical Moist Forests (TMF)
                                                product”
                                                version
                                                ‘TMFv2023' of the Joint Research Centre including the datasets
                                                Deforestation
                                                and
                                                Degradation to assess deforestaion or degradation after December 2020
                                                for
                                                the years
                                                2021, 2022 and 2023.
                                            </span>
                                            <span class="mt-4">
                                                The dataset can be accessed via:
                                                https://forobs.jrc.ec.europa.eu/TMF/data#downloads
                                            </span>
                                        </div>
                                </template>
                            </Card>

                            <Card class="shadow-none mt-4">
                                <template #content>
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center">
                                            <span class="font-bold pr-2">
                                                7. Source, reliability, validity of the information
                                            </span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '7')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label ===
                                                    '7')?.status }}
                                            </Tag>
                                        </div>
                                        <span>
                                            This risk indicator follows Article 10 (2) g): the source, reliability,
                                            validity,
                                            and
                                            links to other available documentation of the information referred to in
                                            Article
                                            9(1).
                                        </span>
                                    </div>
                                </template>
                            </Card>

                            <Card class="shadow-none mt-4">
                                <template #content>
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center">
                                            <span class="font-bold pr-2">
                                                8.1 Concerns in relation to the country of production
                                            </span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '8.1')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label ===
                                                    '8.1')?.status }}
                                            </Tag>
                                        </div>
                                        <span>
                                            This risk indicator follows Article 10 (2) h): concerns in relation to the
                                            country
                                            of
                                            production and origin or parts thereof, such as level of corruption,
                                            prevalence of
                                            document and data falsification, lack of law enforcement, violations of
                                            international
                                            human rights, armed conflict or presence of sanctions imposed by the UN
                                            Security
                                            Council
                                            or the Council of the European Union.
                                        </span>
                                        <Divider />
                                        <div class="flex align-items-center">
                                            <span class="font-bold pr-2">
                                                8.2 Corruption
                                            </span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '8.2')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label ===
                                                    '8.3')?.status }}
                                            </Tag>
                                        </div>
                                        <span>
                                            Nadar’s risk assessment methodology applies data from the Transparency
                                            International
                                            Corruption Perceptions Index (CPI) to assess the level of corruption.
                                        </span>
                                        <span>
                                            The CPI draws upon 13 data sources which capture the assessment of experts
                                            and
                                            business
                                            executives on a number of corrupt behaviors in the public sector, including:
                                            Bribery,
                                            Diversion of public funds, Use of public office for private gain, Nepotism
                                            in the
                                            civil
                                            service, State capture.
                                        </span>
                                        <span>
                                            The CPI ranks 180 countries and territories around the globe by their
                                            perceived
                                            levels
                                            of public sector corruption, scoring on a scale of 0 (highly corrupt) to 100
                                            (very
                                            clean).
                                        </span>
                                        <span>
                                            The index is accessible via: https://www.transparency.org/en/cpi/2023
                                        </span>
                                        <Divider />
                                        <div class="flex align-items-center">
                                            <span class="font-bold pr-2">
                                                8.3 Lack of law enforcement
                                            </span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '8.5')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label ===
                                                    '8.4')?.status }}
                                            </Tag>
                                        </div>
                                        <span>
                                            Nadar’s risk assessment methodology applies data from the The World Justice
                                            Project
                                            Rule
                                            of Law Index to assess the lack of law enforcement. 
                                        </span>
                                        <span>
                                            The 2023 WJP Rule of Law Index evaluates 142 countries and jurisdictions
                                            around the
                                            world. The Index offers original, independent data organized into eight
                                            factors that
                                            encompass the concept of the rule of law: Constraints on Government Powers,
                                            Absence
                                            of
                                            Corruption, Open
                                        </span>
                                        <span>
                                            Government, Fundamental Rights, Order and Security, Regulatory Enforcement,
                                            Civil
                                            Justice, and Criminal Justice. Index factor scores reflect the perspectives
                                            and
                                            experiences of more than 149,000 everyday people and 3,400 legal experts
                                            around the
                                            world, and they are backed by a rigorous process of validation and analysis.
                                            The
                                            score
                                            ranges from 0 to 1, with 1 indicating the strongest adherence to the rule of
                                            law.
                                        </span>
                                        <span>
                                            The index is accessible via:
                                            https://worldjusticeproject.org/rule-of-law-index/
                                        </span>
                                        <Divider />
                                        <div class="flex align-items-center">
                                            <span class="font-bold pr-2">
                                                8.4 Violations of international human rights
                                            </span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '8.6')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label ===
                                                    '8.6')?.status }}
                                            </Tag>
                                        </div>
                                        <span>
                                            Nadar’s risk assessment methodology applies data from the Human Freedom
                                            Index (HFI)
                                            by
                                            the Cato Institute to assess violations of international human rights. 
                                        </span>
                                        <span>
                                            The HFI is the most comprehensive freedom index created for a globally
                                            meaningful
                                            set of
                                            countries and jurisdictions, representing 98.8 percent of the world’s
                                            population. 
                                        </span>
                                        <span>
                                            The 2023 Index uses 86 distinct indicators of personal and economic freedom
                                            in the
                                            following areas: Rule of law, Security and safety, Movement, Religion,
                                            Association,
                                            assembly, and civil society, Expression and information, Relationships, Size
                                            of
                                            government, Legal system and property rights, Sound money, Freedom to trade
                                            internationally, Regulation
                                        </span>
                                        <span>
                                            The HFI scales human freedom on a scale of 0 to 10, where 10 represents more
                                            freedom.
                                        </span>
                                        <span>
                                            The index is accessible via: https://www.cato.org/human-freedom-index/2023
                                        </span>
                                        <Divider />
                                        <div class="flex align-items-center">
                                            <span class="font-bold pr-2">
                                                8.5 Armed conflict
                                            </span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '8.7')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label ===
                                                    '8.7')?.status }}
                                            </Tag>
                                        </div>
                                        <span>
                                            Nadar’s risk assessment methodology applies data from the Global Peace Index
                                            by the
                                            Institute for Economics & Peace (IEP), the Rule of Law in Armed Conflicts
                                            (RULAC)
                                            and the Armed Conflict Location and Event Data (ACLED) Conflict Index by
                                            ACLED to
                                            assess armed conflicts. 
                                        </span>
                                        <span class="mt-4">
                                            The Global Peace Index (GPI) 2024 produced by the Institute for Economics
                                            and Peace
                                            (IEP) covers 163 countries comprising 99.7 percent of the world’s
                                            population, using
                                            23 qualitative and quantitative indicators from highly respected sources,
                                            and
                                            measures the state of peace across three domains: the level of Societal
                                            Safety and
                                            Security; the extent of Ongoing Domestic and International Conflict; and the
                                            degree
                                            of Militarisation. The GPI is weighted on a scale of 1-5. The lower the
                                            score the
                                            more peaceful the country. 
                                        </span>
                                        <span>
                                            The index is accessible via:
                                            https://www.economicsandpeace.org/global-peace-index/
                                        </span>
                                        <span class="mt-4">
                                            The Rule of Law in Armed Conflicts (RULAC) is an initiative of the Geneva
                                            Academy of
                                            International Humanitarian Law and Human Rights. RULAC is an online portal
                                            that
                                            systematically qualifies situations of armed violence using the definition
                                            of armed
                                            conflict under international humanitarian law. RULAC also identifies the
                                            parties to
                                            these conflicts and applicable international law.  It  currently monitors
                                            more than
                                            110 armed conflicts involving at least 55 states and more than 70 armed
                                            non-State
                                            actors.
                                        </span>
                                        <span>
                                            The online portal is accessible via: https://www.rulac.org/
                                        </span>
                                        <span class="mt-4">
                                            The ACLED (Armed Conflict Location and Event Data) The ACLED Conflict Index
                                            assesses
                                            every country and territory in the world according to four indicators –
                                            deadliness,
                                            danger to civilians, geographic diffusion, and armed group fragmentation –
                                            based on
                                            analysis of political violence event data collected for the past year. The
                                            countries
                                            and territories are ranked on a scale of 1-4, experiencing low, turbulent,
                                            high or
                                            extreme, high levels of conflict.
                                        </span>
                                        <span>
                                            ACLED is an independent, impartial, international non-profit organization
                                            collecting
                                            data on violent conflict and protest in all countries and territories in the
                                            world.
                                        </span>
                                        <span>
                                            The index is accessible via: https://acleddata.com/conflict-index/
                                        </span>
                                        <span>
                                            We filtered the ACLED dataset and removed the following events from our risk
                                            assessment scoring: strategic developments, peaceful protest and protest
                                            with
                                            intervention.
                                        </span>
                                        <Divider />
                                        <div class="flex align-items-center">
                                            <span class="font-bold pr-2">
                                                8.6 Sanctions imposed by the UN Security Council or
                                                the Council of the European Union
                                            </span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '8.8')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label ===
                                                    '8.8')?.status }}
                                            </Tag>
                                        </div>
                                        <span>
                                            Nadar’s risk assessment methodology applies data from the UN and EU to
                                            assess the
                                            presence of sanctions imposed by the UN Security Council or the Council of
                                            the
                                            European Union.
                                        </span>
                                    </div>
                                </template>
                            </Card>

                            <Card class="shadow-none mt-4">
                                <template #content>
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-between">
                                            <div>
                                                <span class="font-bold pr-2">
                                                    9. Complexity of the Supply Chain
                                                </span>
                                                <Tag
                                                    :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '9')?.status)">
                                                    {{ slotProps.data.riskIndicators.find(indicator => indicator.label
                                                        ===
                                                        '9')?.status }}
                                                </Tag>
                                            </div>
                                            <Button label="Show Questionnaire" outlined size="small"
                                                @click="riskAssessmentStore.showSupplyChainQuestionnaireDialog()">
                                                <img :src="questionnaire_icon" alt="Questionnaire Icon"
                                                    style="width: 20px; height: 20px; background-color: white;" />
                                            </Button>
                                        </div>
                                        <span class="mt-3">
                                            This risk indicator follows Article 10 (2) i): the complexity of the
                                            relevant
                                            supply chain and the stage of processing of the relevant products, in
                                            particular
                                            difficulties in connecting relevant products to the plot of land where the
                                            relevant
                                            commodities were produced.
                                        </span>
                                    </div>
                                </template>
                            </Card>

                            <Card class="shadow-none mt-4">
                                <template #content>
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-between">
                                            <div>
                                                <span class="font-bold pr-2">
                                                    10. Risk of Circumvention of this Regulation
                                                </span>
                                                <Tag
                                                    :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '10')?.status)">
                                                    {{ slotProps.data.riskIndicators.find(indicator => indicator.label
                                                        ===
                                                        '10')?.status }}
                                                </Tag>
                                            </div>
                                        </div>
                                        <span class="mt-3">
                                            This risk indicator follows Article 10 (2) j): the risk of circumvention of
                                            this
                                            Regulation or of mixing with relevant products of unknown origin or produced
                                            in
                                            areas
                                            where deforestation or forest degradation has occurred or is occurring.
                                        </span>
                                    </div>
                                </template>
                            </Card>

                            <Card class="shadow-none mt-4">
                                <template #content>
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center">
                                            <span class="font-bold pr-2">11. Further Risk of Non-compliance</span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '11')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label ===
                                                    '11')?.status }}
                                            </Tag>
                                        </div>
                                        <span>
                                            This risk indicator follows Article 10 (2) m): any information that would
                                            point to a
                                            risk that the relevant products are non-compliant.
                                        </span>
                                    </div>
                                </template>
                            </Card>

                            <Card class="shadow-none mt-4">
                                <template #content>
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center">
                                            <span class="font-bold pr-2">12. Complementary Information</span>
                                            <Tag
                                                :severity="getSeverity(slotProps.data.riskIndicators.find(indicator => indicator.label === '12')?.status)">
                                                {{ slotProps.data.riskIndicators.find(indicator => indicator.label ===
                                                    '12')?.status }}
                                            </Tag>
                                        </div>
                                        <span>
                                            This risk indicator follows Article 10 (2) m): complementary information on
                                            compliance
                                            with this Regulation, which may include information supplied by
                                            certification or
                                            other
                                            third-party verified schemes, including voluntary schemes recognised by the
                                            Commission
                                            under Article 30(5) of Directive(EU) 2018/2001 of the European Parliament
                                            and of the
                                            Council (21), provided that the information meets the requirements set out
                                            in
                                            Article 9
                                            of this Regulation.
                                        </span>
                                    </div>
                                </template>
                            </Card>
                        </div>
                    </template>
                </DataTable>
            </template>
        </Card>

        <Card class="mt-3">
            <template #title>
                <span>History</span>
            </template>
            <template #content>
                <Card>
                    <template #title>
                        Risk Assessment 2023
                    </template>
                    <template #content>
                        <div class="grid">
                            <div class="col-1">
                                <span>Status</span>
                            </div>
                            <div class="col-11">
                                <Tag value="Low Risk" severity="success"></Tag>
                            </div>
                            <div class="col-1">
                                <span>Date created</span>
                            </div>
                            <div class="col-9">
                                <span>09.05.2023</span>
                            </div>
                            <div class="col-2">
                                <Button icon="pi pi-download" outlined rounded class="mr-3" />
                                <Button label="View Results" />
                            </div>
                        </div>
                    </template>
                </Card>
                <Card class="mt-3">
                    <template #title>
                        Risk Assessment 2024
                    </template>
                    <template #content>
                        <div class="grid">
                            <div class="col-1">
                                <span>Status</span>
                            </div>
                            <div class="col-11">
                                <Tag value="Low Risk" severity="secondary"></Tag>
                            </div>
                            <div class="col-1">
                                <span>Date created</span>
                            </div>
                            <div class="col-9">
                                <span>03.09.2024</span>
                            </div>
                            <div class="col-2">
                                <Button icon="pi pi-download" outlined rounded class="mr-3" />
                                <Button label="View Results" />
                            </div>
                        </div>
                    </template>
                </Card>
                <div class="mt-3">
                    <span class="text-lg font-medium">+ Create new Risk Assessment</span>
                </div>
            </template>
        </Card>

        <Card class="mt-3">
            <template #title>
                <span>Documents</span>
            </template>
            <template #content>
                <DataTable :value="documents" tableStyle="min-width: 50rem">
                    <Column field="name" header="Name"></Column>
                    <Column field="type" header="File Type"></Column>
                    <Column field="supplier" header="Supplier"></Column>
                    <Column field="user" header="User"></Column>
                    <Column field="createdAt" header="Uploaded"></Column>
                </DataTable>
            </template>
        </Card>
    </div>

    <SupplyChainQuestionnaireDialog />
    <IndigenousQuestionnaireDialog />
</template>

<script setup>
import { onMounted, ref, nextTick } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useSupplierStore } from '../stores/supplier';
import MapboxAttributions from '../components/common/MapboxAttributions.vue';
import SupplyChainQuestionnaireDialog from '../components/riskAssessment/SupplyChainQuestionnaireDialog.vue';
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import nadarLogo from "../assets/nadarLogoWhite.png";
import IndigenousQuestionnaireDialog from '../components/riskAssessment/IndigenousQuestionnaireDialog.vue';
import { useRiskAssessmentStore } from '../stores/riskAssessment';
import questionnaire_icon from "../assets/questionnaire_icon.svg"
import axios from 'axios';

const VITE_ANALYSIS_BACKEND = import.meta.env.VITE_ANALYSIS_BACKEND;
const VITE_MAPBOX3_ACCESS_TOKEN = import.meta.env.VITE_MAPBOX3_ACCESS_TOKEN;
// Mapbox Access Token Configuration
mapboxgl.accessToken = VITE_MAPBOX3_ACCESS_TOKEN;

const riskAssessmentStore = useRiskAssessmentStore();
const layerVisible = ref(true);
const events = ref([
    { status: 'Risk Assessment 2023', color: '#9C27B0' },
    { status: 'Processing', color: '#9C27B0' },
    { status: 'Shipped', color: '#9C27B0' },
]);

const documents = [
    {
        name: 'report1.xlsx',
        type: 'Excel',
        supplier: 'Cameroon Cocoa',
        user: 'John Doe',
        createdAt: '09.05.2023'
    },
    {
        name: 'image1.png',
        type: 'PNG',
        supplier: 'Vietnam Coffee',
        user: 'Jane Smith',
        createdAt: '02.04.2024'
    },
    {
        name: 'document1.pdf',
        type: 'PDF',
        supplier: 'Cameroon Cocoa',
        user: 'Alice Johnson',
        createdAt: '13.07.2024'
    },
    {
        name: 'report2.csv',
        type: 'CSV',
        supplier: 'Vietnam Coffee',
        user: 'Bob Brown',
        createdAt: '09.05.2023'
    },
    {
        name: 'presentation.pptx',
        type: 'PowerPoint',
        supplier: 'Cameroon Cocoa',
        user: 'Charlie Davis',
        createdAt: '02.04.2024'
    }
]
const supplierStore = useSupplierStore();
const toast = useToast();
const expandedRows = ref();
let presenceOfForestsMap = ref();
let protectedAreasMap = ref();
let presenceOfIndigenousPeoplesMap = ref();
let prevalenceOfDeforestationOrForestDegradationMap = ref();
const swapCountryLabels = {
    "Bolivia": "bolivia",
    "Paraguay": "paraguay",
    "Colombia": "colombia",
    "Costa Rica": "costarica",
    "Panama": "panama",
    "Peru": "peru",
    "Brazil": "brazil",
    "Venezuela": "venezuela"
};
const dummyItems = [
    {
        "supplier": "Coffe Trader",
        "commodity": "Cocoa",
        "country": "Paraguay",
        "risk": "Standard",
        "riskIndicators": [
            { "label": "1", "status": "Low" },
            { "label": "2.1", "status": "Low" },
            { "label": "2.2", "status": "High" },
            { "label": "3", "status": "No" },
            { "label": "4", "status": "High" },
            { "label": "5", "status": "Low" },
            { "label": "6", "status": "Standard" },
            { "label": "7", "status": "Low" },
            { "label": "8.1", "status": "Low" },
            { "label": "8.2", "status": "Standard" },
            { "label": "8.3", "status": "Low" },
            { "label": "8.4", "status": "Standard" },
            { "label": "8.5", "status": "High" },
            { "label": "8.6", "status": "Standard" },
            { "label": "9", "status": "Low" },
            { "label": "10", "status": "Low" },
            { "label": "11", "status": "Low" },
            { "label": "12", "status": "Low" },
        ]
    },
    {
        "supplier": "Cocoa Peru",
        "commodity": "Cocoa",
        "country": "Peru",
        "risk": "High",
        "riskIndicators": [
            { "label": "1", "status": "Low" },
            { "label": "2.1", "status": "Standard" },
            { "label": "2.2", "status": "Low" },
            { "label": "3", "status": "High" },
            { "label": "4", "status": "Standard" },
            { "label": "5", "status": "Low" },
            { "label": "6", "status": "High" },
            { "label": "7", "status": "Low" },
            { "label": "8.1", "status": "Low" },
            { "label": "8.2", "status": "Standard" },
            { "label": "8.3", "status": "Low" },
            { "label": "8.4", "status": "Standard" },
            { "label": "8.5", "status": "High" },
            { "label": "8.6", "status": "Standard" },
            { "label": "9", "status": "Low" },
            { "label": "10", "status": "Standard" },
            { "label": "11", "status": "Low" },
            { "label": "12", "status": "Standard" },
        ]
    }
]


function toggleLayerVisibilityForests() {
    // Toggle the layer visibility
    if (layerVisible.value) {
        presenceOfForestsMap.value.setLayoutProperty('presence-of-forests-layer', 'visibility', 'none');
        presenceOfForestsMap.value.setLayoutProperty('presence-of-forests-outline-layer', 'visibility', 'none');
    } else {
        presenceOfForestsMap.value.setLayoutProperty('presence-of-forests-layer', 'visibility', 'visible');
        presenceOfForestsMap.value.setLayoutProperty('presence-of-forests-outline-layer', 'visibility', 'visible');
    }

    // Toggle the icon
    layerVisible.value = !layerVisible.value;
}

function toggleLayerVisibilityIndigenous() {
    // Toggle the layer visibility
    if (layerVisible.value) {
        presenceOfIndigenousPeoplesMap.value.setLayoutProperty('indigenous-layer', 'visibility', 'none');
        presenceOfIndigenousPeoplesMap.value.setLayoutProperty('indigenous-outline-layer', 'visibility', 'none');
    } else {
        presenceOfIndigenousPeoplesMap.value.setLayoutProperty('indigenous-layer', 'visibility', 'visible');
        presenceOfIndigenousPeoplesMap.value.setLayoutProperty('indigenous-outline-layer', 'visibility', 'visible');
    }

    // Toggle the icon
    layerVisible.value = !layerVisible.value;
}

function toggleLayerVisibilityProtected() {
    // Toggle the layer visibility
    if (layerVisible.value) {
        protectedAreasMap.value.setLayoutProperty('protected-areas-layer', 'visibility', 'none');
        protectedAreasMap.value.setLayoutProperty('protected-areas-outline-layer', 'visibility', 'none');
    } else {
        protectedAreasMap.value.setLayoutProperty('protected-areas-layer', 'visibility', 'visible');
        protectedAreasMap.value.setLayoutProperty('protected-areas-outline-layer', 'visibility', 'visible');
    }

    // Toggle the icon
    layerVisible.value = !layerVisible.value;
}

function toggleLayerVisibilityDeforestation() {
    // Toggle the layer visibility
    if (layerVisible.value) {
        prevalenceOfDeforestationOrForestDegradationMap.value.setLayoutProperty('prevalence-of-deforestation-layer', 'visibility', 'none');
        prevalenceOfDeforestationOrForestDegradationMap.value.setLayoutProperty('prevalence-of-deforestation-outline-layer', 'visibility', 'none');

    } else {
        prevalenceOfDeforestationOrForestDegradationMap.value.setLayoutProperty('prevalence-of-deforestation-layer', 'visibility', 'visible');
        prevalenceOfDeforestationOrForestDegradationMap.value.setLayoutProperty('prevalence-of-deforestation-outline-layer', 'visibility', 'visible');
    }

    // Toggle the icon
    layerVisible.value = !layerVisible.value;
}

async function onRowExpand(event) {
    await nextTick();
    console.log(event)
    const supplierCountry = swapCountryLabels[event.data.country];
    console.log(supplierCountry)
    presenceOfForestsMap.value = new mapboxgl.Map({
        container: "presenceOfForestsMap",
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        projection: "globe", // Display the map as a globe, since satellite-v9 defaults to Mercator
        zoom: 2,
        center: [-30, 0],
        attributionControl: false,
        transformRequest: (url, resourceType) => {
            if (url.startsWith("https://tiles.planet.com")) {
                // If the URL is from Planet, don't include credentials
                return {
                    url: url,
                };
            }
            if (url.startsWith(VITE_ANALYSIS_BACKEND)) {
                return {
                    url: url,
                    credentials: "include", // Include cookies for cross-origin requests
                };
            }
        },
    });

    protectedAreasMap.value = new mapboxgl.Map({
        container: "protectedAreasMap",
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        projection: "globe", // Display the map as a globe, since satellite-v9 defaults to Mercator
        zoom: 2,
        center: [-30, 0],
        attributionControl: false,
        transformRequest: (url, resourceType) => {
            if (url.startsWith("https://tiles.planet.com")) {
                // If the URL is from Planet, don't include credentials
                return {
                    url: url,
                };
            }
            if (url.startsWith(VITE_ANALYSIS_BACKEND)) {
                return {
                    url: url,
                    credentials: "include", // Include cookies for cross-origin requests
                };
            }
        },
    });

    presenceOfIndigenousPeoplesMap.value = new mapboxgl.Map({
        container: "presenceOfIndigenousPeoplesMap",
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        projection: "globe", // Display the map as a globe, since satellite-v9 defaults to Mercator
        zoom: 2,
        center: [-30, 0],
        attributionControl: false,
        transformRequest: (url, resourceType) => {
            if (url.startsWith("https://tiles.planet.com")) {
                // If the URL is from Planet, don't include credentials
                return {
                    url: url,
                };
            }
            if (url.startsWith(VITE_ANALYSIS_BACKEND)) {
                return {
                    url: url,
                    credentials: "include", // Include cookies for cross-origin requests
                };
            }
        },
    });

    prevalenceOfDeforestationOrForestDegradationMap.value = new mapboxgl.Map({
        container: "prevalenceOfDeforestationOrForestDegradationMap",
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        projection: "globe", // Display the map as a globe, since satellite-v9 defaults to Mercator
        zoom: 2,
        center: [-30, 0],
        attributionControl: false,
        transformRequest: (url, resourceType) => {
            if (url.startsWith("https://tiles.planet.com")) {
                // If the URL is from Planet, don't include credentials
                return {
                    url: url,
                };
            }
            if (url.startsWith(VITE_ANALYSIS_BACKEND)) {
                return {
                    url: url,
                    credentials: "include", // Include cookies for cross-origin requests
                };
            }
        },
    });

    presenceOfForestsMap.value.on("load", () => {
        presenceOfForestsMap.value.addSource("presence-of-forests-source", {
            type: "vector",
            tiles: [`${VITE_ANALYSIS_BACKEND}/tiles/presence_of_forests/${supplierCountry}/{z}/{x}/{y}.pbf`],
        });

        presenceOfForestsMap.value.addLayer({
            id: "presence-of-forests-layer",
            type: "fill",
            source: "presence-of-forests-source",
            "source-layer": "presence_of_forests", // Replace with your actual layer name
            paint: {
                "fill-color": [
                    "step",
                    ["get", "forestperc"],
                    "#ADFF2F",
                    0.1, "#7CFC00",
                    0.3, "#32CD32",
                    0.5, "#228B22",
                    0.7, "#006400"
                ],
            },
        });

        presenceOfForestsMap.value.addLayer({
            id: "presence-of-forests-outline-layer",
            type: "line",
            source: "presence-of-forests-source",
            "source-layer": "presence_of_forests",
            paint: {
                "line-color": "#808080",
                "line-width": 0.5,
            },
        });

        // Create a single popup instance
        let popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        // Show properties with region and city in the popup on mouse move
        presenceOfForestsMap.value.on("mousemove", "presence-of-forests-layer", (e) => {
            presenceOfForestsMap.value.getCanvas().style.cursor = 'pointer';

            // Get the first feature under the mouse
            const feature = e.features[0];
            const coordinates = e.lngLat;

            // Retrieve and format the forest percentage as a percentage
            const percForest = (feature.properties.forestperc * 100).toFixed(2); // Convert to percentage and round to 2 decimal places

            // Combine NAME_1 and NAME_2 for the region name
            const region = feature.properties.NAME_1 || 'Unknown Region'; // Fallback to 'Unknown Region' if NAME_1 is not available
            const mainCity = feature.properties.NAME_2 || 'Unknown City'; // Fallback to 'Unknown City' if NAME_2 is not available

            // Set the popup content with the forest cover percentage, region, and main city
            popup.setLngLat(coordinates)
                .setHTML(`
            <strong>Forest Cover:</strong> ${percForest}% <br/>
            <strong>Region:</strong> ${region} <br/>
            <strong>Main City:</strong> ${mainCity}
        `)
                .addTo(presenceOfForestsMap.value);
        });

        presenceOfForestsMap.value.on("mouseleave", "presence-of-forests-layer", () => {
            presenceOfForestsMap.value.getCanvas().style.cursor = '';
            popup.remove();
        });
    });

    presenceOfForestsMap.value.on("style.load", () => {
        presenceOfForestsMap.value.setFog({
            range: [0.8, 8],
            color: "#090e21",
            "horizon-blend": 0,
            "high-color": "#245bde",
            "space-color": "#000000",
            "star-intensity": 0.15,
        });
    });


    protectedAreasMap.value.on("load", () => {
        protectedAreasMap.value.addSource("protected-areas-source", {
            type: "vector",
            tiles: [`${VITE_ANALYSIS_BACKEND}/tiles/protected_areas/${supplierCountry}/{z}/{x}/{y}.pbf`],
        });

        protectedAreasMap.value.addLayer({
            id: "protected-areas-layer",
            type: "fill", // or "line", "symbol", etc., depending on your data type
            source: "protected-areas-source",
            "source-layer": "protected_areas", // Replace with the actual layer name from your tileset
            paint: {
                "fill-color": "#008080", // Example for fill layers
            },
        });
        protectedAreasMap.value.addLayer({
            id: "protected-areas-outline-layer", // Unique ID for the outline layer
            type: "line",  // Line type for the outline
            source: "protected-areas-source",
            "source-layer": "protected_areas", // Replace with your actual source layer name
            paint: {
                "line-color": "#005050", // Black color for the outline
                "line-width": 0.5,  // Adjust the width as needed
            },
        });
    })

    protectedAreasMap.value.on("style.load", () => {
        protectedAreasMap.value.setFog({
            range: [0.8, 8],
            color: "#090e21",
            "horizon-blend": 0,
            "high-color": "#245bde",
            "space-color": "#000000",
            "star-intensity": 0.15,
        }); // Set the default atmosphere style
    });

    presenceOfIndigenousPeoplesMap.value.on("load", () => {
        presenceOfIndigenousPeoplesMap.value.addSource("indigenous-source", {
            type: "vector",
            tiles: [`${VITE_ANALYSIS_BACKEND}/tiles/indigenous/tiles/${supplierCountry}/{z}/{x}/{y}.pbf`],
        });

        presenceOfIndigenousPeoplesMap.value.addLayer({
            id: "indigenous-layer",
            type: "fill", // or "line", "symbol", etc., depending on your data type
            source: "indigenous-source",
            "source-layer": "indigenous", // Replace with the actual layer name from your tileset
            paint: {
                "fill-color": "#800080", // Example for fill layers
            },
        });
        presenceOfIndigenousPeoplesMap.value.addLayer({
            id: "indigenous-outline-layer", // Unique ID for the outline layer
            type: "line",  // Line type for the outline
            source: "indigenous-source",
            "source-layer": "indigenous", // Replace with your actual source layer name
            paint: {
                "line-color": "#4B0082", // Black color for the outline
                "line-width": 0.5,  // Adjust the width as needed
            },
        });
    })

    presenceOfIndigenousPeoplesMap.value.on("style.load", () => {
        presenceOfIndigenousPeoplesMap.value.setFog({
            range: [0.8, 8],
            color: "#090e21",
            "horizon-blend": 0,
            "high-color": "#245bde",
            "space-color": "#000000",
            "star-intensity": 0.15,
        }); // Set the default atmosphere style
    });

    prevalenceOfDeforestationOrForestDegradationMap.value.on("load", () => {
        prevalenceOfDeforestationOrForestDegradationMap.value.addSource("prevalence-of-deforestation-source", {
            type: "vector",
            tiles: [`${VITE_ANALYSIS_BACKEND}/tiles/prevalence_of_deforestation/${supplierCountry}/{z}/{x}/{y}.pbf`],
        });

        prevalenceOfDeforestationOrForestDegradationMap.value.addLayer({
            id: "prevalence-of-deforestation-layer",
            type: "fill",
            source: "prevalence-of-deforestation-source",
            "source-layer": "prevalence_of_deforestation", // Replace with the actual layer name from your tileset
            paint: {
                "fill-color": [
                    "step",
                    ["get", "def_perc"], // Replace with the actual property name if different
                    "#FFE6E6",   // Light Red for deforestperc < 0.1%
                    0.001, "#FF9999", // Soft Red for deforestperc >= 0.1%
                    0.005, "#FF6666", // Medium Red for deforestperc >= 0.5%
                    0.01, "#FF3333",  // Dark Red for deforestperc >= 1%
                    0.015, "#CC0000"  // Very Dark Red for deforestperc >= 1.5%
                ],
            },
        });

        prevalenceOfDeforestationOrForestDegradationMap.value.addLayer({
            id: "prevalence-of-deforestation-outline-layer", // Unique ID for the outline layer
            type: "line",  // Line type for the outline
            source: "prevalence-of-deforestation-source",
            "source-layer": "prevalence_of_deforestation", // Replace with your actual source layer name
            paint: {
                "line-color": "#808080", // Black color for the outline
                "line-width": 0.5,  // Adjust the width as needed
            },
        });

        // Show properties on hover
        let popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        prevalenceOfDeforestationOrForestDegradationMap.value.on('mousemove', 'prevalence-of-deforestation-layer', (e) => {
            prevalenceOfDeforestationOrForestDegradationMap.value.getCanvas().style.cursor = 'pointer';

            // Get the first feature under the mouse
            const feature = e.features[0];
            const coordinates = e.lngLat;

            // Convert def_perc to a percentage and round to 2 decimal places
            const defPerc = (feature.properties.def_perc * 100).toFixed(2);

            // Combine NAME_1 and NAME_2 for the region name
            const region = feature.properties.NAME_1 || 'Unknown Region'; // Fallback to 'Unknown Region' if NAME_1 is not available
            const mainCity = feature.properties.NAME_2 || 'Unknown City'; // Fallback to 'Unknown City' if NAME_2 is not available

            // Update the popup content with the deforestation percentage, region, and main city
            popup.setLngLat(coordinates)
                .setHTML(`
            <strong>Deforestation:</strong> ${defPerc}% <br/>
            <strong>Region:</strong> ${region} <br/>
            <strong>Main City:</strong> ${mainCity}
        `)
                .addTo(prevalenceOfDeforestationOrForestDegradationMap.value);
        });

        prevalenceOfDeforestationOrForestDegradationMap.value.on('mouseleave', 'prevalence-of-deforestation-layer', () => {
            prevalenceOfDeforestationOrForestDegradationMap.value.getCanvas().style.cursor = '';
            popup.remove();
        });

    })

    prevalenceOfDeforestationOrForestDegradationMap.value.on("style.load", () => {
        prevalenceOfDeforestationOrForestDegradationMap.value.setFog({
            range: [0.8, 8],
            color: "#090e21",
            "horizon-blend": 0,
            "high-color": "#245bde",
            "space-color": "#000000",
            "star-intensity": 0.15,
        }); // Set the default atmosphere style
    });
}

function getSeverity(status) {
    switch (status) {
        case "Low":
            return "success"
        case "Standard":
            return "secondary"
        case "High":
            return "danger"
    }
}

async function fetchData() {
    supplierStore.fetchAllSuppliers();
}

fetchData();
</script>


<style scoped>
.duediligence-container {
    flex: 1;
    /* Adjust this value to change width relative to map-container */
    overflow: auto;
    /* Allows scrolling within the container if content overflows */
    padding: 1rem;
    box-sizing: border-box;
}

#presenceOfForestsMap {
    top: 0;
    bottom: 0;
    height: 400px;
    width: 700px;
}

#protectedAreasMap {
    top: 0;
    bottom: 0;
    height: 400px;
    width: 700px;
}

#presenceOfIndigenousPeoplesMap {
    top: 0;
    bottom: 0;
    height: 400px;
    width: 700px;
}

#prevalenceOfDeforestationOrForestDegradationMap {
    top: 0;
    bottom: 0;
    height: 400px;
    width: 700px;
}

:deep(.mapboxgl-ctrl-logo) {
    position: absolute;
    bottom: 0px;
    left: 80px;
    /* Adjust the value to create space for your logo */
}

.legend {
    background-color: #fff;
    border-radius: 3px;
    bottom: 40px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 10px;
    position: absolute;
    right: 10px;
    z-index: 1;
}

.legend h4 {
    margin: 0 0 10px;
}

.legend div span {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;
}
</style>