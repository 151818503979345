import { defineStore } from 'pinia'

export const useRiskAssessmentStore = defineStore('riskAssessment', {
    state: () => ({
        isSupplyChainQuestionnaireDialogShown: false,
        isIndigenousQuestionnaireDialogShown: false,
        isCircumventionQuestionnaireDialogShown: false,
    }),
    actions: {
        showSupplyChainQuestionnaireDialog() {
            this.isSupplyChainQuestionnaireDialogShown = true;
        },
        closeSupplyChainQuestionnaireDialog() {
            this.isSupplyChainQuestionnaireDialogShown = false;
        },
        showIndigenousQuestionnaireDialog() {
            this.isIndigenousQuestionnaireDialogShown = true;
        },
        closeIndigenousQuestionnaireDialog() {
            this.isIndigenousQuestionnaireDialogShown = false;
        },
        showCircumventionQuestionnaireDialog() {
            this.isCircumventionQuestionnaireDialogShown = true
        },
        closeCircumventionQuestionnaireDialog() {
            this.isCircumventionQuestionnaireDialogShown = false
        },
    }
})
