<template>
    <Button label="Actions" icon="pi pi-chevron-down" iconPos="right" @click="toggle" aria-haspopup="true"
        aria-controls="overlay_menu" class="mr-1" />
    <Menu ref="menu" id="overlay_menu" :model="exportActions" :popup="true" />

    <Dialog v-model:visible="isDeletePlotsDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column px-5 py-5 gap-4">
                <span class="text-lg font-medium">Delete plots?</span>
                <span>Selected plots will be deleted. You can't undo this action.</span>
                <div class="flex justify-content-end">
                    <Button label="Cancel" severity="secondary" class="mr-3" @click="closeCallback"></Button>
                    <Button label="Delete" severity="danger" @click="handleDeletePlots"
                        :loading="isPlotDeleteLoading"></Button>
                </div>
            </div>
        </template>
    </Dialog>

    <Dialog v-model:visible="isRestartRiskDetectionDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column px-5 py-5 gap-4">
                <span class="text-lg font-medium">Restart risk detection?</span>
                <span>This will restart risk detection for selected plots.</span>
                <div class="flex justify-content-end">
                    <Button label="Cancel" severity="secondary" class="mr-3"
                        @click="closeRestartRiskDetectionDialog"></Button>
                    <Button label="Yes" class="light-green-nadar" @click="handleRestartRiskDetection"
                        :loading="isRestartRiskDetectionLoading"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>


<script setup>
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import * as Sentry from "@sentry/vue";
import { useAnalyticsAPI } from '../../../composables/useAnalyticsAPI';
import { usePlotStore } from '../../../stores/plot';
import { usePlotRiskUtils } from './composables/usePlotRiskUtils';

const { exportPlotsExcel, exportPlotsGeojson, restartRiskDetection, deletePaginationPlots, getPollStatus } = useAnalyticsAPI();
const { refreshData } = usePlotRiskUtils();
const route = useRoute();
const plotStore = usePlotStore();
const toast = useToast();
const menu = ref();
const isDeletePlotsDialogShown = ref(false);
const isPlotDeleteLoading = ref(false);
const isRestartRiskDetectionLoading = ref(false);
const isRestartRiskDetectionDialogShown = ref(false);
const exportActions = ref([
    {
        label: 'Export GeoJSON',
        command: () => exportGeoJSON()
    },
    {
        label: 'Export Excel',
        command: () => exportExcel()
    },
    {
        label: 'Delete Plots',
        command: () => onDeletePlots()
    },
    {
        label: 'Restart Risk Detection',
        command: () => onRestartRiskDetection()
    },
]);


function toggle(event) {
    menu.value.toggle(event);
};

function closeDeletePlotsDialog() {
    isDeletePlotsDialogShown.value = false;
}

function showDeletePlotsDialog() {
    isDeletePlotsDialogShown.value = true;
}

function closeRestartRiskDetectionDialog() {
    isRestartRiskDetectionDialogShown.value = false;
}

function showRestartRiskDetectionDialog() {
    isRestartRiskDetectionDialogShown.value = true;
}

// EXPORT PLOTS

async function exportGeoJSON() {
    if (!plotStore.selectedCheckboxes.length) {
        toast.add({ severity: 'info', summary: 'Info', detail: 'Select at least one plot.', life: 3000 });
        return;
    }

    let body;
    if (plotStore.isEveryPageSelected) {
        body = {
            isEveryPageSelected: true,
            filters: JSON.stringify(plotStore.filters),
            batchObjectId: route.params.batchObjectId,
            format: "geojson"
        }
    }
    else {
        body = {
            isEveryPageSelected: false,
            filters: JSON.stringify(plotStore.filters),
            plotObjectIds: plotStore.selectedCheckboxes,
            batchObjectId: route.params.batchObjectId,
            format: "geojson"
        }
    }

    let response;
    try {
        response = await exportPlotsGeojson(body);
    } catch (error) {
        console.log(error)
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to export GeoJSON. Please try again.', life: 5000 });
        return;
    }

    const geojsonStr = JSON.stringify(response, null, 2);
    const blob = new Blob([geojsonStr], { type: 'application/geo+json' });
    const link = document.createElement('a');
    link.download = 'data.geojson';
    link.href = window.URL.createObjectURL(blob);
    link.click();
    window.URL.revokeObjectURL(link.href);
}

// EXPORT EXCEL

async function exportExcel() {
    if (!plotStore.selectedCheckboxes.length) {
        toast.add({ severity: 'info', summary: 'Info', detail: 'Select at least one plot.', life: 3000 });
        return;
    }

    let body;
    if (plotStore.isEveryPageSelected) {
        body = {
            isEveryPageSelected: true,
            filters: JSON.stringify(plotStore.filters),
            batchObjectId: route.params.batchObjectId,
            format: "excel"
        }
    }
    else {
        body = {
            isEveryPageSelected: false,
            filters: JSON.stringify(plotStore.filters),
            plotObjectIds: plotStore.selectedCheckboxes,
            batchObjectId: route.params.batchObjectId,
            format: "excel"
        }
    }

    try {
        const response = await exportPlotsExcel(body);

        // Create a Blob from the response data
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'plots.xlsx'; // Set the desired file name
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the object URL and removing the link element
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
    } catch (error) {
        console.error('Failed to export Excel:', error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to export Excel. Please try again.', life: 5000 });
    }
}

// DELETE PLOTS

async function handleDeletePlots() {
    isPlotDeleteLoading.value = true;
    const payload = {
        batchObjectId: route.params.batchObjectId,
        plotObjectIds: plotStore.selectedCheckboxes,
        isEveryPageSelected: plotStore.isEveryPageSelected,
        filters: JSON.stringify(plotStore.filters)
    }

    try {
        const response = await deletePaginationPlots(payload);
        plotStore.resetFilters();
    } catch (error) {
        console.error(error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Could not delete selected plots.', life: 3000 });
        isPlotDeleteLoading.value = false;
        return;
    }

    refreshData();
    closeDeletePlotsDialog();
    isPlotDeleteLoading.value = false;
    toast.add({ severity: 'success', summary: 'Success', detail: 'Plots deleted.', life: 3000 });
}

function onDeletePlots() {
    if (!plotStore.selectedCheckboxes.length) {
        toast.add({ severity: 'info', summary: 'Info', detail: 'Select at least one plot.', life: 3000 });
        return;
    }
    showDeletePlotsDialog();
}

async function pollJobStatus(jobId) {
    const pollInterval = 3000;

    return new Promise((resolve, reject) => {
        const checkStatus = async () => {
            const response = await getPollStatus(jobId);
            console.log(response)
            if (response.job_status !== "complete" && response.job_status !== "error") {
                console.log("Job still in progress...");
                setTimeout(checkStatus, pollInterval);
            } else if (response.job_status === "complete" || response.job_status === "error") {
                console.log("Job completed:", response.result);
                resolve(response);
            }
        };

        checkStatus();
    });
}

function onRestartRiskDetection() {
    if (!plotStore.selectedCheckboxes.length) {
        toast.add({ severity: 'info', summary: 'Info', detail: 'Select at least one plot.', life: 3000 });
        return;
    }

    showRestartRiskDetectionDialog();
}

async function handleRestartRiskDetection() {
    const body = {
        batchObjectId: route.params.batchObjectId,
        plotObjectIds: plotStore.selectedCheckboxes,
        isEveryPageSelected: plotStore.isEveryPageSelected,
        filters: JSON.stringify(plotStore.filters),
    };

    console.log(body)

    try {
        isRestartRiskDetectionLoading.value = true;
        const response = await restartRiskDetection(body);
        const job = await pollJobStatus(response.job_id);
        processJobResult(job);
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        plotStore.showRiskDetectionFailureDialog();
    } finally {
        isRestartRiskDetectionLoading.value = false;
    }
}

function processJobResult(job) {
    if (job.result === "success") {
        plotStore.showRiskDetectionSuccessDialog();
        closeRestartRiskDetectionDialog();
        refreshData();
    } else {
        plotStore.showRiskDetectionFailureDialog();
    }
}
</script>