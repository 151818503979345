<template>
    <Dialog v-model:visible="dataImportStore.isFileProcessingDialogShown" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column m-4 p-2 gap-2 justify-content-center">
                <span class="text-xl font-medium">Processing file</span>
                <div class="flex align-items-center mt-3">
                    <i class="pi pi-spin pi-spinner mr-2"></i>
                    <span>Please wait. This may take a few moments.</span>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { useDataImportStore } from "../../stores/dataImport";

const dataImportStore = useDataImportStore();
</script>
