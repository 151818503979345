import { useMappingStore } from "../../../stores/mapping";

export function usePagination() {
    const mappingStore = useMappingStore();

    function unselectIsEveryCheckboxSelected() {
        mappingStore.isEveryCheckboxSelected = false;
    }

    function unselectAllCheckboxes() {
        mappingStore.selectedCheckboxes = [];
        mappingStore.isEveryCheckboxSelected = false;
    }

    function selectAllCheckboxes() {
        mappingStore.selectedCheckboxes = mappingStore.mappingPlots.map(plot => plot._id);
        console.log(mappingStore.selectedCheckboxes)
        mappingStore.isEveryCheckboxSelected = true;
    }

    function toggleAll() {
        if (mappingStore.isEveryCheckboxSelected) {
            selectAllCheckboxes();
        } else {
            unselectAllCheckboxes();
        }
    }

    function checkAllCheckboxesStatus() {
        console.log(mappingStore.selectedCheckboxes)
        if (mappingStore.selectedCheckboxes.length === mappingStore.mappingPlots.length) {
            mappingStore.isEveryCheckboxSelected = true;
        } else {
            mappingStore.isEveryCheckboxSelected = false;
        }
    }

    return {
        unselectAllCheckboxes,
        selectAllCheckboxes,
        checkAllCheckboxesStatus,
        toggleAll,
        unselectIsEveryCheckboxSelected
    }
}