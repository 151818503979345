<template>
    <Dialog v-model:visible="dataImportStore.isInvalidFileDialogShown" modal :style="{ width: '40rem' }">
        <template #header>
            <span class="font-medium text-lg">File validation failed</span>
        </template>
        <span class="block mb-3">{{ dataImportStore.invalidFileDialogMessage }}</span>
        <div class="flex justify-content-end gap-2">
            <Button label="OK" severity="danger" @click="dataImportStore.closeInvalidFileDialog()"></Button>
        </div>
    </Dialog>
</template>

<script setup>
import { useDataImportStore } from '../../stores/dataImport';

const dataImportStore = useDataImportStore();
</script>