import { defineStore } from 'pinia'

// In this storage everything is stored longer than session.
export const useInLocalStore = defineStore('inLocal', {
    state: () => ({
        selectedBatch: null,
        selectedCommodity: ""
    }),
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'inLocal',
                storage: localStorage,
            },
        ],
    }
})
