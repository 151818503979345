<template>
    <Card>
        <template #title>
            <div class="flex justify-content-between align-items-center">
                <span class="font-medium text-lg">Plots</span>
                <div class="flex align-items-center">
                    <Button icon="pi pi-sync" @click="refreshData" :loading="plotStore.isTableLoading" class="mr-1" />
                    <PlotSplitButton />
                </div>
            </div>
        </template>
        <template #content>
            <DataTable :value="plotStore.plots" v-model:filters="plotStore.filters" v-model:selection="selectedRow" lazy
                paginator @filter="onFilter" @page="onPage" @sort="onSort" :totalRecords="plotStore.totalRecords"
                selectionMode="single" @rowSelect="handlePlotSelection" size="small" removableSort filterDisplay="row"
                :rows="100" :rowsPerPageOptions="[100, 250, 500]" scrollHeight="calc(100vh - 325px)" scrollable>
                <template #empty>
                    <ProgressBar v-if="plotStore.isTableLoading" mode="indeterminate" style="height: 2px">
                    </ProgressBar>
                    <div v-if="!plotStore.isTableLoading"
                        class="flex flex-column align-items-center justify-content-left text-center mt-4">
                    </div>
                </template>
                <Column field="select" :showFilterMenu="false" frozen>
                    <template #header>
                        <Checkbox v-model="plotStore.isCurrentPageSelected" :binary="true"
                            @change="togglePageCheckboxes()" />
                    </template>
                    <template #body="slotProps">
                        <Checkbox v-model="plotStore.selectedCheckboxes" :inputId="slotProps.data._id"
                            :value="slotProps.data._id" @change="toggleCheckbox(slotProps.data._id, $event)" />
                    </template>
                </Column>
                <Column field="plotId" header="Plot ID" sortable :showFilterMenu="false"
                    style="white-space: nowrap; min-width: 12rem;">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                            placeholder="Search" />
                    </template>
                </Column>
                <Column filterField="dataQuality" sortField="dataQuality" header="Data quality" :showFilterMenu="false"
                    sortable style="min-width: 200px; max-width: 200px;">
                    <template #body="{ data }">
                        <div class="tags-container">
                            <Tag v-for="(check, indexNew) in data.dataQuality" :key="indexNew" :value="check"
                                :class="getTagSeverity(check.color)" class="mr-1 mt-1 font-normal text-base">
                                {{ check.label }}
                            </Tag>
                        </div>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <MultiSelect v-model="filterModel.value" @change="filterCallback()"
                            :options="DATA_QUALITY_CHECKS" placeholder="Select" :maxSelectedLabels="3" />
                    </template>
                </Column>
                <Column filterField="deforestation" sortField="deforestation" header="Deforestation"
                    :showFilterMenu="false" sortable style="white-space: nowrap">
                    <template #body="{ data }">
                        <Tag v-for="(check, indexNew) in data.deforestation" :key="indexNew" :value="check"
                            :class="getTagSeverity(check.color)" class="mr-1 font-normal text-base">
                            {{ check.label }}
                        </Tag>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <MultiSelect v-model="filterModel.value" @change="filterCallback()"
                            :options="DEFORESTATION_CHECKS" placeholder="Select" :maxSelectedLabels="3" />
                    </template>
                </Column>
                <Column field="commodity" header="Commodity" :showFilterMenu="false" sortable
                    style="white-space: nowrap">
                    <template #body="{ data }">
                        {{ data.commodity }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="commodities"
                            placeholder="Select">
                        </Dropdown>
                    </template>
                </Column>
                <Column field="country" header="Country" :showFilterMenu="false" sortable style="white-space: nowrap">
                    <template #body="{ data }">
                        {{ data.country }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown v-model="filterModel.value" @change="filterCallback()" filter :options="countries"
                            placeholder="Select">
                        </Dropdown>
                    </template>
                </Column>
                <Column field="area" header="Area (ha)" dataType="numeric" sortable
                    style="white-space: nowrap; min-width: 12rem;" :showFilterMenu="false">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                            class="p-column-filter" placeholder="Search" />
                    </template>
                    <template #body="{ data }">
                        {{ data.area }}
                    </template>
                </Column>
                <Column filterField="supplier" sortField="supplier" header="Supplier" :showFilterMenu="false" sortable
                    style="white-space: nowrap">
                    <template #body="{ data }">
                        {{ data.supplier?.supplierName }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="suppliers"
                            placeholder="Select" class="p-column-filter" optionLabel="name" showClear>
                        </Dropdown>
                    </template>
                </Column>
                <Column field="producerName" header="Producer name" sortable :showFilterMenu="false"
                    style="white-space: nowrap">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                            class="p-column-filter" placeholder="Search" />
                    </template>
                    <template #body="{ data }">
                        {{ data.producerName }}
                    </template>
                </Column>
                <Column field="additionalInfo" header="Additonal information" sortable :showFilterMenu="false"
                    style="white-space: nowrap">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                            class="p-column-filter" placeholder="Search" />
                    </template>
                    <template #body="{ data }">
                        {{ data.additionalInfo }}
                    </template>
                </Column>
                <Column field="treeSpecies" header="Tree species" :showFilterMenu="false" sortable
                    style="white-space: nowrap">
                    <template #body="{ data }">
                        <span v-if="data.treeSpecies">{{ data.treeSpecies?.tree }} ({{ data.treeSpecies?.scientific
                            }})</span>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" @input="filterCallback()" placeholder="Search" />
                    </template>
                </Column>
                <Column field="" header="Actions">
                    <template #body="slotProps">
                        <Button icon="pi pi-file-edit" outlined size="small" />
                    </template>
                </Column>
                <template #footer>
                    <div class="flex">
                        <div v-if="plotStore.isEveryPageSelected">
                            <div class="flex align-items-center">
                                <span class="text-base font-normal">All {{ plotStore.totalRecords }} plots
                                    selected.
                                </span>
                                <Button label="Unselect all" text style="color: blue;" severity="info"
                                    @click="toggleAll" />
                            </div>
                        </div>
                        <div v-else>
                            <div class="flex align-items-center">
                                <span class="text-base font-normal">Selected {{ plotStore.selectedCheckboxes.length }}
                                    of
                                    {{ plotStore.totalRecords }}
                                    plots.</span>
                                <Button :label="'Select all ' + plotStore.totalRecords + ' plots'" text
                                    style="color: blue;" severity="info" @click="toggleAll" />
                            </div>
                        </div>
                    </div>
                </template>
            </DataTable>
        </template>
    </Card>
</template>

<script setup>
import { ref } from 'vue';
import { useAnalyticsAPI } from '../../../composables/useAnalyticsAPI';
import { useRoute } from 'vue-router';
import { usePlotStore } from "../../../stores/plot";
import { useSupplierStore } from '../../../stores/supplier';
import { commodities, countries, DATA_QUALITY_CHECKS, DEFORESTATION_CHECKS, LEGALITY_CHECKS } from "../../../utils/constants";
import { usePlotRiskUtils } from './composables/usePlotRiskUtils';
import PlotSplitButton from './PlotSplitButton.vue';
import { usePagination } from './composables/usePagination';


const { toggleAll, toggleCheckbox, togglePageCheckboxes } = usePagination();
const { refreshData, flyToPlotAndHighlightIt } = usePlotRiskUtils();
const { getPaginationPlots } = useAnalyticsAPI();
const supplierStore = useSupplierStore();
const plotStore = usePlotStore();
const route = useRoute();
const suppliers = ref([]);
const selectedRow = ref();


function getTagSeverity(color) {
    const statusMap = {
        green: "custom-success",
        red: "custom-danger",
        orange: "custom-warning",
    };
    return statusMap[color] || 'secondary';
}

function handlePlotSelection(event) {
    console.log(plotStore.plots)
    const selectedPlot = plotStore.plots.filter(plot => plot._id === event.data._id)[0];
    plotStore.selectedPlotOnMap = selectedPlot;
    console.log(selectedPlot)
    const properties = {
        featureId: selectedPlot._id
    }
    selectedPlot.properties = properties;// just for adapting to flyToPlotAndHighlightIt for testing
    flyToPlotAndHighlightIt(selectedPlot);
}

function onPage(event) {
    if (!plotStore.selectedPages[event.page]) {
        plotStore.selectedPages[event.page] = {
            selected: false,
            plots: []
        }
    }
    plotStore.isCurrentPageSelected = plotStore.selectedPages[event.page].selected;
    plotStore.pageParams = event;
    loadPageData(event);
}

function onSort(event) {
    console.log(event)
    plotStore.pageParams = event;
    loadPageData(event);
}

function onFilter(event) {
    console.log(event)
    plotStore.selectedPages = [];
    plotStore.pageParams.filters = plotStore.filters;
    loadPageData(event);
};

async function loadPageData(event) {
    plotStore.isTableLoading = true;
    const offset = event.first;
    const limit = plotStore.pageParams.rows;
    const filters = plotStore.pageParams.filters;
    const batchObjectId = route.params.batchObjectId;
    const body = {
        limit: limit,
        offset: offset,
        filters: JSON.stringify(filters),
        sortField: event.sortField,
        sortOrder: event.sortOrder,
        batchObjectId: batchObjectId,
    }

    const response = await getPaginationPlots(body);
    console.log(response)
    plotStore.plots = response.plots;
    plotStore.totalRecords = response.totalRecords;
    plotStore.isTableLoading = false;
}

async function fetchData() {
    plotStore.isTableLoading = true;

    plotStore.pageParams = {
        first: 0,
        rows: 100,  // Default rows per page
        sortField: null,
        sortOrder: null,
        page: 0,
        filters: {}
    };

    await loadPageData(plotStore.pageParams);
    await supplierStore.fetchAllSuppliers();
    suppliers.value = supplierStore.allSuppliers.map(supplier => ({ _id: supplier._id, name: supplier.name }));
}


fetchData();
</script>

<style scoped>
.custom-success {
    background-color: #a7f3a1 !important;
}

.custom-danger {
    background-color: #f8a8a1 !important;
}

.custom-warning {
    background-color: #ffd59a !important;
}


.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    max-width: 100%;
}
</style>