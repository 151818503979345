<template>
    <div id="mappingmap" class="border-round">
        <img :src="nadarLogo" class="mapbox-nadar-logo" width="60">
        <MapboxAttributions />

        <div>

            <SearchLocation />

            <DrawPolygon />

            <PlaceMarker />

            <MappingSaveEditPlotsCard />

            <div v-if="mappingStore.isSearchResultCardShown" class="marker-card border-round">
                <div class="flex align-items-center justify-content-between">
                    <h3 class="ml-3">{{ mappingStore.mousePosition?.location }}</h3>
                    <Button icon="pi pi-times" class="ml-4 mr-3" text
                        @click="closeAllCardsAndDeactivateGeometryModes()" />
                </div>
                <div class="ml-3 mr-3 mb-3">
                    <span class="text-base">Latitude and Longitude: {{ mappingStore.mousePosition?.latDecimal }}, {{
                        mappingStore.mousePosition?.lngDecimal }}</span>
                    <span class="text-base">DMS: {{ mappingStore.mousePosition?.latDMS }} {{
                        mappingStore.mousePosition?.lngDMS
                    }}
                    </span>
                    <Button icon="pi pi-plus" label="Add Plot" class="black-button mt-4"
                        @click="addSearchedPointToDatabase" />
                </div>
            </div>
        </div>
    </div>

    <Dialog v-model:visible="isPlotCreationDialogShown" modal :style="{ width: '25rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column px-5 py-5 gap-4 text-center">
                <span class="text-2xl">Saving Plot...</span>
                <i class="pi pi-spin pi-sync" style="font-size: 2rem"></i>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { onMounted, onUnmounted, ref, inject, onActivated } from "vue";
import { useToast } from 'primevue/usetoast';
import MapboxAttributions from "../common/MapboxAttributions.vue";
import PlaceMarker from "./PlaceMarker.vue";
import MappingSaveEditPlotsCard from "./MappingSaveEditPlotsCard.vue";
import { useMappingUtils } from "./composables/useMappingUtils.js";
import nadarLogo from "../../assets/nadarLogoWhite.png"
import { GeometryMode } from "../../utils/constants.js";
import { useMappingStore } from "../../stores/mapping.js";
import { useAnalyticsAPI } from "../../composables/useAnalyticsAPI.js";
import { useDataAPI } from "../../composables/useDataAPI.js";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import SearchLocation from "./SearchLocation.vue";
import DrawPolygon from "./DrawPolygon.vue";
import * as Sentry from "@sentry/vue";


const VITE_EUDR_BACKEND = import.meta.env.VITE_EUDR_BACKEND;
const VITE_MAPBOX3_ACCESS_TOKEN = import.meta.env.VITE_MAPBOX3_ACCESS_TOKEN;
mapboxgl.accessToken = VITE_MAPBOX3_ACCESS_TOKEN;

const map = inject("map");
const draw = inject("draw");
const { getPlotByPlotObjectId } = useDataAPI();
const { createNewPlotFromDesktop } = useAnalyticsAPI();
const { closeAllCardsAndDeactivateGeometryModes, refreshData } = useMappingUtils();
const mappingStore = useMappingStore();
const toast = useToast();
const isPlotCreationDialogShown = ref(false);


function handleKeyEvent(event) {
    if (event.key === 'Backspace') {
        if (draw.getMode() === 'direct_select') {
            draw.trash();
        }
    }
}

async function addSearchedPointToDatabase() {
    const feature = {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [
                parseFloat(mappingStore.mousePosition.lngDecimal),
                parseFloat(mappingStore.mousePosition.latDecimal)
            ]
        }
    };

    const payload = {
        creationType: "DRAW",
        geometry: feature.geometry,
        status: "MAPPING"
    }

    try {
        isPlotCreationDialogShown.value = true;
        const response = await createNewPlotFromDesktop(payload);
        await refreshData();
        closeAllCardsAndDeactivateGeometryModes();
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Could not create plot.', life: 3000 });
        return;
    } finally {
        isPlotCreationDialogShown.value = false;
    }
}

function showPlotPopup(properties, lngLat) {
    const plotId = properties.plotId || 'N/A';

    const popupContent = document.createElement('div');
    popupContent.innerHTML = `
        <div class="marker-info">
            <table class="metadata">
                <tbody>
                    <tr><td><strong>Plot ID</strong></td><td>${plotId}</td></tr>
                </tbody>
            </table>
            <div style="text-align: right; margin-top: 10px;">
                <button class="edit-plot-btn" style="background-color: black; color: white; font-weight: 500; padding: 0.5rem 1rem; border: none; border-radius: 4px; cursor: pointer;">
                    Edit Plot
                </button>
            </div>
        </div>
    `;

    // Create and show the Mapbox popup
    const popup = new mapboxgl.Popup({ closeButton: false, offset: [0, -38] })
        .setLngLat(lngLat)
        .setDOMContent(popupContent)
        .addTo(map.value);

    // Add event listener for the "Edit Plot" button
    popupContent.querySelector('.edit-plot-btn').addEventListener('click', async () => {
        const response = await getPlotByPlotObjectId(properties.plotObjectId);
        const feature = {
            geometry: response.geometry,
            properties: properties
        };
        editPlot(feature);
        popup.remove();
    });
}

function editPlot(feature) {
    mappingStore.isEditPlotsCardShown = true;
    draw.deleteAll();
    draw.add({
        type: "Feature",
        id: feature.properties.plotObjectId,
        geometry: feature.geometry,
    });
    draw.changeMode('simple_select', { featureIds: [feature.properties.plotObjectId] });
    mappingStore.editingPlotObjectId = feature.properties.plotObjectId
    mappingStore.currentGeometryMode = GeometryMode.EDITING;
}

onMounted(() => {
    window.addEventListener("keydown", handleKeyEvent);

    map.value = new mapboxgl.Map({
        container: "mappingmap",
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        projection: "globe", // Display the map as a globe, since satellite-v9 defaults to Mercator
        zoom: 2,
        center: [-30, 0],
        attributionControl: false,
        transformRequest: (url, resourceType) => {
            if (url.startsWith("https://tiles.planet.com")) {
                // If the URL is from Planet, don't include credentials
                return {
                    url: url,
                };
            }
            if (url.startsWith(VITE_EUDR_BACKEND)) {
                return {
                    url: url,
                    credentials: "include", // Include cookies for cross-origin requests
                };
            }
        },
    });

    map.value.addControl(new mapboxgl.NavigationControl(), "top-right");
    map.value.addControl(draw);

    map.value.on("click", "polygons-layer-fill", (e) => {
        const features = map.value.queryRenderedFeatures(e.point);
        const properties = features[0].properties;
        const lngLat = e.lngLat;

        if (mappingStore.currentGeometryMode != GeometryMode.EDITING) {
            showPlotPopup(properties, lngLat);
        }
    });

    map.value.on("click", "points-layer", (e) => {
        const features = map.value.queryRenderedFeatures(e.point);
        const properties = features[0].properties;
        const lngLat = e.lngLat;

        if (mappingStore.currentGeometryMode != GeometryMode.EDITING) {
            showPlotPopup(properties, lngLat);
        }
    });

    map.value.on('load', () => {
        map.value.addSource('mapping-plots-source', {
            type: 'vector',
            tiles: [`${VITE_EUDR_BACKEND}/plots/mapping/{z}/{x}/{y}.pbf`],
        });

        map.value.addLayer({
            'id': 'polygons-layer-fill',
            'type': 'fill',
            'source': 'mapping-plots-source',
            'source-layer': 'mapping-plots',
            'filter': ['==', '$type', 'Polygon'], // Only include Polygon features
            'paint': {
                // Styling for polygons
                'fill-color': 'rgba(0, 0, 0, 0)',
                'fill-opacity': 0.5
            }
        });

        map.value.addLayer({
            'id': 'polygons-layer-line',
            'type': 'line',
            'source': 'mapping-plots-source',
            'source-layer': 'mapping-plots',
            'filter': ['==', '$type', 'Polygon'], // Only include Polygon features
            'paint': {
                'line-color': "#FF69B4",
                'line-width': 3
            }
        });

        map.value.addLayer({
            'id': 'points-layer',
            'type': 'circle',
            'source': 'mapping-plots-source',
            'source-layer': 'mapping-plots',
            'filter': ['==', '$type', 'Point'], // Only include Point features
            'paint': {
                'circle-radius': 4,
                'circle-color': '#FF69B4'
            }
        });

        // Change the cursor to pointer when hovering over polygons
        map.value.on('mouseenter', 'polygons-layer-fill', () => {
            map.value.getCanvas().style.cursor = 'pointer';
        });

        // Change the cursor to pointer when hovering over points
        map.value.on('mouseenter', 'points-layer', () => {
            map.value.getCanvas().style.cursor = 'pointer';
        });

        // Reset the cursor when it leaves the polygon layer
        map.value.on('mouseleave', 'polygons-layer-fill', () => {
            map.value.getCanvas().style.cursor = '';
        });

        // Reset the cursor when it leaves the point layer
        map.value.on('mouseleave', 'points-layer', () => {
            map.value.getCanvas().style.cursor = '';
        });
    });

    map.value.on("draw.create", async (event) => {
        if (mappingStore.currentGeometryMode === GeometryMode.DRAWING) {
            const drawnFeature = event.features[0];

            const payload = {
                creationType: "DRAW",
                geometry: drawnFeature.geometry,
                status: "MAPPING"
            }

            try {
                isPlotCreationDialogShown.value = true;
                const response = await createNewPlotFromDesktop(payload);
                await refreshData();
            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
                toast.add({ severity: 'error', summary: 'Error', detail: 'Could not create plot.', life: 3000 });
            } finally {
                isPlotCreationDialogShown.value = false;
                mappingStore.currentGeometryMode = GeometryMode.NONE;
                draw.deleteAll();
            }
        } else {
            mappingStore.lastDrawnFeature = event.features[0];
        }
    });

    map.value.on("style.load", () => {
        map.value.setFog({
            range: [0.8, 8],
            color: "#090e21",
            "horizon-blend": 0,
            "high-color": "#245bde",
            "space-color": "#000000",
            "star-intensity": 0.15,
        }); // Set the default atmosphere style
    });
});

onUnmounted(() => {
    window.removeEventListener("keydown", handleKeyEvent);
    map.value.remove();
})

onActivated(() => {
    if (map.value) {
        map.value.resize();
    }
});
</script>

<style scoped>
#mappingmap {
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.marker-card {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
    background-color: #ffffff;
    max-width: 350px;
}

.marker-card span {
    /* Ensure the span takes the full width */
    display: block;
    width: 100%;
    /* Handling long text */
    word-wrap: break-word;
}

.overlay-satelliteImages {
    position: absolute;
    top: 10px;
    left: 50px;
    z-index: 1000;
}

.custom-height {
    height: 35px;
    /* Replace with the actual height of your Dropdown */
}

:deep(.mapboxgl-ctrl-logo) {
    position: absolute;
    bottom: 0px;
    left: 80px;
    /* Adjust the value to create space for your logo */
}

.edit-icon {
    position: absolute;
    top: 240px;
    right: 10px;
    z-index: 1000;
    background-color: white;
    border-style: solid;
    border-color: rgb(219, 219, 219);
    border-width: 1px;
    cursor: pointer;
}

.edit-icon:hover {
    background-color: #e6e6e6;
    border-style: solid;
    border-color: rgb(219, 219, 219);
}

:deep(.marker-info) {
    font-family: Arial, sans-serif;
    font-size: 12px;
}

:deep(.metadata) {
    border-collapse: collapse;
    width: 100%;
}

:deep(.metadata td) {
    padding: 3px;
    border: 1px solid #ddd;
}

:deep(.metadata tr:nth-child(even)) {
    background-color: #f9f9f9;
}

:deep(.metadata tr:hover) {
    background-color: #f1f1f1;
}
</style>