<template>
    <Dialog v-model:visible="riskAssessmentStore.isSupplyChainQuestionnaireDialogShown" modal
        header="Complexity of the Supply Chain" :style="{ width: '90rem' }">
        <div class="flex flex-column">
            <Card>
                <template #content>
                    <div class="flex flex-column">
                        <!-- Heading for the first block -->
                        <h4 class="mb-2">Supply Chain Complexity and Risk Indicators</h4>
                        <p class="mb-3">
                            This risk indicator follows Article 10 (2) i): the complexity of the relevant supply chain
                            and the stage of
                            processing of the relevant products, in particular difficulties in connecting relevant
                            products to the plot
                            of land where the relevant commodities were produced. The complexity of the supply chain
                            increases with the
                            number of processors and intermediaries between the plots of land in the country of
                            production and the
                            operator or trader. Complexity may also increase when more than one relevant product is used
                            to manufacture
                            a new relevant product, or if relevant commodities are sourced from multiple countries of
                            production.
                            On the other hand, the due diligence exercise is likely to be simpler in short supply
                            chains, and a short
                            supply chain may, particularly in the case of simplified due diligence under Article 13, be
                            one factor that
                            helps to demonstrate that there is a negligible risk of circumvention of the Regulation.
                        </p>

                        <!-- Heading for the questions -->
                        <h4 class="mb-2">Supply Chain Complexity Assessment Questions</h4>
                        <p class="mb-3">
                            In order to assess the complexity of the supply chain, operators and traders may use the
                            following (non-exhaustive)
                            list of questions for relevant products to be placed on, or made available on, or exported
                            from the Union market:
                        </p>

                        <!-- List of questions -->
                        <ul class="list-none pl-3 mb-3">
                            <li class="mb-2">
                                - Were there several processors and/or steps in the supply chain before a particular
                                relevant product was placed on,
                                or made available on, or exported from the Union market?
                            </li>
                            <li class="mb-2">
                                - Does the relevant product contain relevant commodities sourced from several plots
                                and/or countries of production?
                            </li>
                            <li class="mb-2">
                                - Is the relevant product a highly processed product (which may itself contain multiple
                                other relevant products)?
                            </li>
                            <li class="mb-2">
                                - For timber, does the relevant product consist of more than one tree species?
                            </li>
                            <li class="mb-2">
                                - Have the timber and/or timber products been traded in more than one country?
                            </li>
                            <li class="mb-2">
                                - Were any relevant processed products processed or manufactured in third countries
                                before they were placed on,
                                or made available on or exported from the Union market?
                            </li>
                        </ul>
                    </div>

                    <!-- User Input Section -->
                    <h4 class="mb-2">Answer the Assessment</h4>

                    <!-- Textarea for first answer -->
                    <Textarea v-model="firstAnswer" rows="5" class="mt-3 w-full"
                        placeholder="Enter your answer here..." />

                    <!-- Conditional block when 'Yes' is selected -->
                    <div class="mt-3">


                        <!-- Drag-and-drop file upload area -->
                        <div class="flex justify-content-between mt-3">
                            <div class="w-full p-3 border-1 border-round border-dashed border-gray-300 flex justify-content-center align-items-center cursor-pointer"
                                @click="triggerFileInput" @drop.prevent="handleDrop" @dragover.prevent
                                @dragenter.prevent>
                                <div class="flex flex-column align-items-center">
                                    <span class="font-medium">Drag & drop files or</span>
                                    <Button label="Select files" outlined class="mt-2" />
                                </div>
                            </div>
                            <input type="file" ref="fileInput" multiple class="hidden" @change="handleFileSelect" />
                        </div>

                        <!-- Display selected file names with remove option -->
                        <div v-if="selectedFiles.length > 0" class="mt-3">
                            <div v-for="(file, index) in selectedFiles" :key="index"
                                class="flex justify-content-between align-items-center border-round border-1 p-3 border-gray-300 mt-2">
                                <span>{{ file.name }}</span>
                                <Button label="Remove" icon="pi pi-times" class="p-button-text p-button-danger"
                                    @click="removeFile(index)" />
                            </div>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
        <div class="flex justify-content-end mt-3">
            <Button label="Cancel" severity="secondary" class="mr-3"
                @click="riskAssessmentStore.closeSupplyChainQuestionnaireDialog()" />
            <Button label="Save" @click="riskAssessmentStore.closeSupplyChainQuestionnaireDialog()" />
        </div>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useRiskAssessmentStore } from '../../stores/riskAssessment';


const riskAssessmentStore = useRiskAssessmentStore();
const firstAnswer = ref();
const selectedFiles = ref([]);
const fileInput = ref(null);


function clearFirstAnswer() {
    firstAnswer.value = null;
}

// Trigger file input on div click
function triggerFileInput() {
    fileInput.value.click();
}

// Handle file selection
function handleFileSelect(event) {
    const files = Array.from(event.target.files);
    selectedFiles.value.push(...files);
}

// Handle file drop
function handleDrop(event) {
    const files = Array.from(event.dataTransfer.files);
    selectedFiles.value.push(...files);
}

// Remove selected file
function removeFile(index) {
    selectedFiles.value.splice(index, 1);
}
</script>
