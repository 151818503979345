<template>
  <CreatePlotMap />

  <Card v-if="onboardingStore.currentMode === Mode.WALK" class="card-overlay">
    <template #title>
      <div class="flex justify-content-center text-center">
        <span>Walk around the borders</span>
      </div>
    </template>
    <template #content>
      <div class="flex justify-content-center text-center">
        <span>Press 'Finish' when you return to the starting point.</span>
      </div>
      <Button class="black-button w-full mt-4" rounded label="Finish" @click="stopPlotWalk" />
    </template>
  </Card>

  <Card v-if="onboardingStore.currentMode === Mode.DETAILS" class="card-overlay">
    <template #title>
      <span>Plot Details</span>
    </template>
    <template #content>
      <div class="flex flex-column">
        <label>Batch <span style="color: red">*</span></label>
        <Dropdown v-model="localStore.selectedBatch" :options="batchStore.batches" optionLabel="label" class="w-full"
          showClear>
        </Dropdown>

        <label class="mt-3">Producer Name</label>
        <InputText v-model="producerName" />

        <label class="mt-3">Additional Information</label>
        <InputText v-model="additionalInfo" />

      </div>
      <Divider />
      <div class="flex justify-content-between mt-4">
        <Button class="white-button fixed-width-button mr-3" rounded label="Cancel"
          @click="showCancelPlotCreationDialog" />
        <Button class="black-button fixed-width-button" rounded label="Save" @click="handleSavePlot" />
      </div>
    </template>
  </Card>

  <Dialog v-model:visible="isCancelPlotCreationDialogShown" modal :style="{ width: '90vw' }">
    <template #container="slotProps">
      <div class="surface-card border-round shadow-2 p-4">
        <div class="text-900 font-medium mb-2 text-xl text-center">Discard changes?</div>
        <p class="mt-0 mb-4 p-0 line-height-3 text-center">The walked plot will not be saved.</p>
        <div class="flex justify-content-between">
          <Button rounded class="w-full white-button mr-3" label="Cancel"
            @click="hideCancelPlotCreationDialog"></Button>
          <Button rounded class="w-full black-button" label="Yes" @click="cancelPlotCreation"></Button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'MapPage'
}
</script>
<script setup>
//Vue import
import { ref, onMounted, provide } from "vue";
import { useToast } from 'primevue/usetoast';
import CreatePlotMap from "../../components/mobile/CreatePlotMap.vue";
import { useOnboardingStore } from "../../stores/onboarding"
import { useInLocalStore } from "../../stores/inLocal";
import { useBatchStore } from "../../stores/batch";
import * as Sentry from "@sentry/vue";


const toast = useToast();
const onboardingStore = useOnboardingStore();
const batchStore = useBatchStore();
const localStore = useInLocalStore();
const isCancelPlotCreationDialogShown = ref(false);
const additionalInfo = ref();
const producerName = ref();
let map = ref();
let marker = ref(null);
const savedGeometry = ref({});
const lineCoordinates = ref([]);
const Mode = {
  IDLE: "IDLE",
  MARK_CONFIG: "MARK_CONFIG",
  MARK: "MARK",
  WALK_CONFIG: "WALK_CONFIG",
  WALK: "WALK",
  DETAILS: "DETAILS"
}

provide("map", map);
provide("marker", marker);
provide("savedGeometry", savedGeometry);
provide("lineCoordinates", lineCoordinates);


async function fetchData() {
  try {
    await batchStore.fetchBatches();
    console.log()
  } catch (error) {
    Sentry.captureException(error);
    console.log(error)
  }
}

function showCancelPlotCreationDialog() {
  isCancelPlotCreationDialogShown.value = true;
}

function hideCancelPlotCreationDialog() {
  isCancelPlotCreationDialogShown.value = false;
}

function stopPlotWalk() {
  /*
  if (onboardingStore.currentMode === Mode.WALK) {
    savedGeometry.value.coordinates = lineCoordinates.value;
  }
  */

  onboardingStore.currentMode = Mode.DETAILS;
}

function cancelPlotCreation() {
  onboardingStore.plots.pop();
  hideCancelPlotCreationDialog();
  onboardingStore.currentMode = Mode.IDLE;

  // Check if the layer exists before removing it
  if (map.value.getLayer('line-layer')) {
    map.value.removeLayer('line-layer');
  }
  // Check if the source exists before removing it
  if (map.value.getSource('line-source')) {
    map.value.removeSource('line-source');
  }

  if (marker.value) {
    marker.value.remove();
  }
}

function handleSavePlot() {
  if (!localStore.selectedBatch || !localStore.selectedBatch._id) {
    toast.add({ severity: 'info', summary: 'Missing field', detail: "You have to select a batch.", life: 3000 });
    return
  }

  if (!savedGeometry.value || savedGeometry.value.type === "LineString" && savedGeometry.value.coordinates.length < 2) {
    toast.add({ severity: 'info', summary: 'Error', detail: "A walked polygon needs at least three GPS coordinates to be saved.", life: 3000 });
    return;
  }

  const plotData = {
    isSubmitted: false,
    type: savedGeometry.value.type,
    coordinates: savedGeometry.value.coordinates,
    producerName: producerName.value,
    additionalInfo: additionalInfo.value,
    batchObjectId: localStore.selectedBatch._id
  }

  onboardingStore.plots.push(plotData)
  onboardingStore.currentMode = Mode.IDLE;

  if (marker.value) {
    marker.value.remove();
  }

  // Check if the layer exists before removing it
  if (map.value.getLayer('line-layer')) {
    map.value.removeLayer('line-layer');
  }
  // Check if the source exists before removing it
  if (map.value.getSource('line-source')) {
    map.value.removeSource('line-source');
  }
}

onMounted(() => {
  onboardingStore.currentMode = Mode.IDLE;
});

fetchData();
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 51px);
}

.map-wrapper {
  flex-grow: 1;
  overflow: hidden;
}

.white-button {
  background-color: #ffffff;
  border-color: #123456;
  color: rgb(0, 0, 0);
  /* Text Color */
}

.black-button {
  background-color: #000000;
  border-color: #000000;
  color: rgb(255, 255, 255);
  /* Text Color */
}

.fixed-width-button {
  width: 40%;
  /* Adjust the width as necessary */
}

.card-overlay {
  position: absolute;
  bottom: 80px;
  /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 95%;
  max-width: 600px;
  /* Optional: Limit the maximum width */
}
</style>