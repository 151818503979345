<template>
    <div class="flex flex-column">
        <div v-if="dataImportStore.fileType === 'csv' || dataImportStore.fileType === 'xlsx'">
            <span class="font-medium text-lg pt-4">Coordinates</span>
            <div class="flex justify-content-start">
                <div class="flex align-items-center my-2 mr-3">
                    <div
                        :class="['border-1 border-round p-3 flex align-items-center', selectedCoordinateOption === 'LongLat' ? 'border-primary' : 'border-gray-300 text-color-secondary']">
                        <RadioButton v-model="selectedCoordinateOption" inputId="longLat" name="latlong" value="LongLat"
                            @change="handleOptionChange" />
                        <label for="longLat" class="ml-2">Longitude & Latitude</label>
                    </div>
                </div>
                <div class="flex align-items-center my-2">
                    <div
                        :class="['border-1 border-round p-3 flex align-items-center', selectedCoordinateOption === 'WKT' ? 'border-primary' : 'border-gray-300 text-color-secondary']">
                        <RadioButton v-model="selectedCoordinateOption" inputId="wkt" name="latlong" value="WKT"
                            @change="handleOptionChange" />
                        <label for="wkt" class="ml-2">Well Known Text (WKT)</label>
                    </div>
                </div>
            </div>

            <div v-if="selectedCoordinateOption === 'LongLat'">
                <div class="flex justify-content-evenly w-full py-2">
                    <div class="flex-1 mr-2">
                        <label>Longitude *</label>
                        <div class="pt-1">
                            <Dropdown v-model="longSelectedColumn" :options="dataImportStore.columnNames"
                                placeholder="Select" class="w-full" :invalid="isLngLatFieldMissing" />
                        </div>
                    </div>
                    <div class="flex-1 ml-2">
                        <label>Latitude *</label>
                        <div class="pt-1">
                            <Dropdown v-model="latSelectedColumn" :options="dataImportStore.columnNames"
                                placeholder="Select" class="w-full" :invalid="isLngLatFieldMissing" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selectedCoordinateOption === 'WKT'" class="py-2">
                <label>WKT *</label>
                <div class="pt-1">
                    <Dropdown v-model="wktSelectedColumn" :options="dataImportStore.columnNames" placeholder="Select"
                        class="w-full" :invalid="isWktFieldMissing" />
                </div>
            </div>
            <span class="font-medium text-lg pt-4">Additional Fields</span>
        </div>
        <div v-else>
            <span class="font-medium text-lg pt-4">Select Fields</span>
        </div>
        <div class="py-2">
            <label>Area (optional)</label>
            <div class="pt-1">
                <Dropdown v-model="dataImportStore.selectedAreaColumn" :options="dataImportStore.columnNames"
                    placeholder="Select" class="w-full" showClear />
                <small>Select if you have a column that specifies the area of the plots.</small>
            </div>
        </div>

        <div class="py-2">
            <label>Producer name (optional)</label>
            <div class="pt-1">
                <Dropdown v-model="dataImportStore.selectedProducerNameColumn" :options="dataImportStore.columnNames"
                    placeholder="Select" class="w-full" showClear />
                <small>Select if you have a column that specifies the producer name of the
                    plots.</small>
            </div>
        </div>

        <div class="py-2">
            <label>Plot ID (optional)</label>
            <div class="pt-1">
                <Dropdown v-model="dataImportStore.selectedPlotIdColumn" showClear
                    :options="dataImportStore.columnNames" placeholder="Select" class="w-full" />
                <small>Select if you have a column that specifies the plot ID. If not a plot ID is
                    generated for every plot.
                </small>
            </div>
        </div>

        <span class="font-medium text-lg pt-4 pb-2">Dataset</span>
        <DataTable :value="dataImportStore.parsedData.data" scrollable scrollHeight="400px" paginator :rows="100"
            :rowsPerPageOptions="[100, 250, 500]"
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" showGridlines stripedRows>
            <Column v-for="col in dataImportStore.columnNamesDataTableFormat" :key="col.field" :field="col.field"
                :header="col.header" />
        </DataTable>
        <div class="flex pt-4 justify-content-between">
            <Button label="Back" severity="secondary" @click="prevCallback" />
            <Button label="Next" @click="processSelectFields(nextCallback)" />
        </div>
    </div>

    <Dialog v-model:visible="isAnyIncorectCoordinateFound" modal :style="{ width: '90rem' }"
        header="Found incorrect data">
        <span>Fix the following errors in your file and import it again</span>
        <DataTable :value="importTabularDataErrors" class="w-full mt-3" scrollable scrollHeight="400px" paginator
            :rows="100" :rowsPerPageOptions="[100, 250, 500]" tableStyle="min-width: 50rem" stripedRows
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}">
            <Column field="index" header="Row"></Column>
            <Column field="message" header="Error"></Column>
        </DataTable>
    </Dialog>
</template>

<script setup>
//Vue import
import { ref } from "vue";
import { useToast } from "primevue/usetoast";
//Local import
import { useDataImportStore } from "../../stores/dataImport";
import { useDataImportUtils } from './composables/useDataImportUtils';
import { parse as parseWKT } from "wkt";
import * as Sentry from "@sentry/vue";


const { loadPlotsFromGeoJSON } = useDataImportUtils();
const dataImportStore = useDataImportStore();
const toast = useToast();
const selectedCoordinateOption = ref('LongLat');
const longSelectedColumn = ref(null);
const latSelectedColumn = ref(null);
const wktSelectedColumn = ref(null);
const importTabularDataErrors = ref([]);
const geojsonData = ref({});
const isLngLatFieldMissing = ref(false);
const isWktFieldMissing = ref(false);
const isAnyIncorectCoordinateFound = ref(false);


const props = defineProps({
    nextCallback: {
        type: Function,
        required: true
    },
    prevCallback: {
        type: Function,
        required: true
    }
});


function handleOptionChange() {
    // Reset selected columns when options change
    longSelectedColumn.value = null;
    latSelectedColumn.value = null;
    wktSelectedColumn.value = null;
}

function processSelectFields(nextCallback) {
    if (dataImportStore.fileType === "csv" || dataImportStore.fileType === "xlsx") {
        processTabluarData(nextCallback);
    } else {
        processGeoData(nextCallback);
    }
}

function processGeoData(nextCallback) {
    importTabularDataErrors.value = [];

    for (const [index, row] of dataImportStore.parsedData.data.entries()) {
        if (dataImportStore.selectedAreaColumn) {
            const area = String(row[dataImportStore.selectedAreaColumn]).replace(/\s/g, '').replace(/,/g, '.');
            const areaRegex = /^(\d+(\.\d+)?)?$/; // Checks for empty strings, positive integers and positive floats
            const areaMatch = areaRegex.test(area);

            if (!areaMatch) {
                importTabularDataErrors.value.push({
                    message: "Invalid area cell detected. Please make sure only numeric values are in the area column. Empty values are fine and will be ignored.",
                    feature: index + 1
                })
            }
        }
    }

    if (importTabularDataErrors.value.length) {
        dataImportStore.showInvalidAreaCellDialog(importTabularDataErrors.value)
    } else {
        nextCallback();
    }
}

function processTabluarData(nextCallback) {
    geojsonData.value = {
        "type": "FeatureCollection",
        "features": []
    }

    isWktFieldMissing.value = false;
    isLngLatFieldMissing.value = false;
    importTabularDataErrors.value = [];

    if (selectedCoordinateOption.value === "LongLat") {
        if (!longSelectedColumn.value || !latSelectedColumn.value) {
            toast.add({ severity: 'info', summary: 'Missing fields', detail: "Select both Latitude and Longitude columns.", life: 3000 });
            isLngLatFieldMissing.value = true;
            return;
        }
        try {
            processLngLatData();
        } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.add({ severity: 'error', summary: 'Error', detail: "Could not parse longitude and latitude data." });
        }
    }
    else {
        if (!wktSelectedColumn.value) {
            toast.add({ severity: 'info', summary: 'Missing fields', detail: "Select WKT column." });
            isWktFieldMissing.value = true;
            return;
        }
        try {
            processWKTData();
        } catch (error) {
            Sentry.captureException(error);
            console.log(error);
            toast.add({ severity: 'error', summary: 'Error', detail: "Could not parse WKT geometry. Please check if every row of the tabular data has correct WKT geometry format." });
        }
    }

    if (importTabularDataErrors.value.length) {
        showIncorectCoordinateFoundDialog();
    } else {
        if (geojsonData.value.features && geojsonData.value.features.length > 0) {
            loadPlotsFromGeoJSON(geojsonData.value);
            nextCallback();
        } else {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Could not find any plots in the file. Correct format?',
                life: 5000
            });
        }
    }
}

async function processWKTData() {
    // Process parsedData
    for (const [index, row] of dataImportStore.parsedData.data.entries()) {
        if (dataImportStore.selectedAreaColumn) {
            const area = String(row[dataImportStore.selectedAreaColumn]);
            const areaRegex = /^(\d+(\.\d+)?)?$/; // Checks for empty strings, positive integers and positive floats
            const areaMatch = areaRegex.test(area);

            if (!areaMatch) {
                importTabularDataErrors.value.push({
                    message: "Invalid area cell detected. Please make sure only numeric values are in the area column. Empty values are fine and will be ignored.",
                    index: index + 2 // Because excel starts at row 1 and row 1 should be the header
                })
            }
        }

        // Check if the wkt_geom is empty
        if (!row[wktSelectedColumn.value]) {
            importTabularDataErrors.value.push({
                message: "Empty row detected. Only one header row and rows representing plots are permitted.",
                index: index + 2
            })
            continue;
        }
        let wktGeom = row[wktSelectedColumn.value];

        wktGeom = wktGeom.toUpperCase();
        wktGeom = wktGeom.replace(/POLYGONZ/g, "POLYGON Z");
        wktGeom = wktGeom.replace(/POINTZ/g, "POINT Z");
        wktGeom = wktGeom.replace(/LINESTRINGZ/g, "LINESTRING Z");
        wktGeom = wktGeom.replace(/MULTILINESTRINGZ/g, "MULTILINESTRING Z");
        wktGeom = wktGeom.replace(/MULTIPOLYGONZ/g, "MULTIPOLYGON Z");
        wktGeom = wktGeom.replace(/MULTILINESTRINGZ/g, "MULTILINESTRING Z");

        const geometry = parseWKT(wktGeom);
        if (!geometry || !geometry.coordinates || !geometry.type) {
            importTabularDataErrors.value.push({
                message: "Failed to parse WKT geometry.",
                index: index + 2
            })
            continue;
        }

        // Create properties object excluding the wkt_geom column
        const properties = {};
        Object.keys(row).forEach(key => {
            if (key !== wktSelectedColumn.value) {
                properties[key] = row[key];
            }
        });

        const feature = {
            "type": "Feature",
            "geometry": geometry,
            "properties": properties
        };
        geojsonData.value.features.push(feature)
    };
}

function dmsToDecimal(dms) {
    // Regex captures degrees, minutes, and seconds, with their fractional part if any, and direction
    const parts = dms.match(/^([0-9]?\d|1[0-7]\d|180)\D([0-5]?\d)\D([0-5]?\d(?:\.\d+)?)\D{1,2}([EWNS])$/);
    if (!parts) return NaN; // Return NaN if the match fails, indicating invalid input format

    let [, degrees, minutes, seconds, direction] = parts;
    // Convert the captured strings to numbers and calculate the decimal degrees
    let decimal = Number(degrees) + Number(minutes) / 60 + Number(seconds) / 3600;

    // Check the direction and negate the result if the direction is South or West
    if (direction === "S" || direction === "W") {
        decimal = -decimal;
    }

    return decimal;
}

function dmToDecimal(dm) {
    // Adjusted regex to capture the degree and minute values along with the direction
    const parts = dm.match(/^([0-9]?\d|1[0-7]\d|180)\D([0-5]?\d(?:\.\d+)?)\D{1,2}([EWNS])$/);
    if (!parts) return NaN;

    let [, degrees, minutes, direction] = parts;
    let decimal = Number(degrees) + Number(minutes) / 60;

    if (direction === "S" || direction === "W") {
        decimal = -decimal;
    }

    return decimal;
}


async function processLngLatData() {
    for (const [index, row] of dataImportStore.parsedData.data.entries()) {
        let isRegexMatching = false;

        if (dataImportStore.selectedAreaColumn) {
            const area = String(row[dataImportStore.selectedAreaColumn]).replace(/\s/g, '').replace(/,/g, '.');
            const areaRegex = /^(\d+(\.\d+)?)?$/; // Checks for empty strings, positive integers and positive floats
            const areaMatch = areaRegex.test(area);

            if (!areaMatch) {
                importTabularDataErrors.value.push({
                    message: "Invalid area cell detected. Please make sure only numeric values are in the area column. Empty values are fine and will be ignored.",
                    index: index + 2 // Because excel starts at row 1 and row 1 should be the header
                })
            }
        }

        if (!row[latSelectedColumn.value] || !row[longSelectedColumn.value]) {
            importTabularDataErrors.value.push({
                message: "Empty latitude and/or longitude cell detected.",
                index: index + 2 // Because excel starts at row 1 and row 1 should be the header
            })
            continue;
        }

        let lng = String(row[longSelectedColumn.value].replace(/\s/g, '').replace(/,/g, '.'));
        let lat = String(row[latSelectedColumn.value].replace(/\s/g, '').replace(/,/g, '.'));

        const longitudeRegex = /^(\+|-)?(?:180(?:\.\d+)?|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:\.\d+)?)$/;
        const latitudeRegex = /^(\+|-)?(?:90(?:\.\d+)?|(?:[0-9]|[1-8][0-9])(?:\.\d+)?)$/;

        const dmsLngRegex = /^([0-9]?\d|1[0-7]\d|180)\D([0-5]?\d)\D([0-5]?\d(?:\.\d+)?)\D{1,2}([EW])$/;
        const dmsLatRegex = /^([0-8]?\d|90)\D([0-5]?\d)\D([0-5]?\d(?:\.\d+)?)\D{1,2}([NS])$/;

        const dmLngRegex = /^([0-9]?\d|1[0-7]\d|180)\D([0-5]?\d(?:\.\d+)?)\D{1,2}([EW])$/;
        const dmLatRegex = /^([0-8]?\d|90)\D([0-5]?\d(?:\.\d+)?)\D{1,2}([NS])$/;

        const lngMatch = longitudeRegex.test(lng)
        const latMatch = latitudeRegex.test(lat)

        const dmsLngMatch = dmsLngRegex.test(lng);
        const dmsLatMatch = dmsLatRegex.test(lat);

        const dmLngMatch = dmLngRegex.test(lng);
        const dmLatMatch = dmLatRegex.test(lat);

        isRegexMatching = lngMatch && latMatch

        if (!isRegexMatching) {
            isRegexMatching = dmsLngMatch && dmsLatMatch
            if (!isRegexMatching) {
                isRegexMatching = dmLngMatch && dmLatMatch
                if (!isRegexMatching) {
                    importTabularDataErrors.value.push({
                        message: "Longitude and/or latitude coordinates have incorrect format.",
                        index: index + 2
                    })
                    continue;
                } else {
                    // Convert DM coordinates to decimal
                    lng = dmToDecimal(lng);
                    lat = dmToDecimal(lat);

                    if (!longitudeRegex.test(lng) || !latitudeRegex.test(lat)) {
                        importTabularDataErrors.value.push({
                            message: "Longitude and/or latitude coordinates have incorrect format.",
                            index: index + 2
                        })
                        continue;
                    }
                }
            } else {
                // Convert DMS coordinates to decimal
                lng = dmsToDecimal(lng);
                lat = dmsToDecimal(lat);

                if (!longitudeRegex.test(lng) || !latitudeRegex.test(lat)) {
                    importTabularDataErrors.value.push({
                        message: "Longitude and/or latitude coordinates have incorrect format.",
                        index: index + 2
                    })
                    continue;
                }
            }
        }

        const properties = {};
        Object.keys(row).forEach(key => {
            if (key !== wktSelectedColumn.value) {
                properties[key] = row[key];
            }
        });

        const feature = {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [lng, lat]
            },
            "properties": properties
        };

        geojsonData.value.features.push(feature);
    }
}

function showIncorectCoordinateFoundDialog() {
    isAnyIncorectCoordinateFound.value = true;
}

</script>