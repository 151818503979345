import { defineStore } from 'pinia'
import { useAnalyticsAPI } from '../composables/useAnalyticsAPI';

export const useBatchStore = defineStore('batch', {
    state: () => ({
        batches: [],
        activeBatch: null,
        isBatchesLoading: true,
        isEditBatchDialogShown: false,
        isDeleteBatchDialogShown: false,
        isCreateBatchDialogShown: false,
    }),
    actions: {
        async fetchBatches() {
            const { getAllBatches } = useAnalyticsAPI();
            this.isBatchesLoading = true;
            this.batches = [];

            try {
                this.batches = await getAllBatches();
            } catch (error) {
                console.log(error)
                throw error
            } finally {
                this.isBatchesLoading = false;
            }
        }
    }
})