<template>
    <div class="m-2">
        <h2>Profile</h2>
        <Button label="Logout" class="w-full" rounded @click="fetchSignOut" />
    </div>
</template>

<script setup>
//Vue import
import { useRouter } from 'vue-router';
//Local import
import { useDataAPI } from '../../composables/useDataAPI';


const { postLogoutUser } = useDataAPI();
const router = useRouter();


async function fetchSignOut() {
    await postLogoutUser();
    router.push({ name: "login" })
}
</script>