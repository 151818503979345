import { defineStore } from 'pinia'
import { useDataAPI } from '../composables/useDataAPI';
import { GeometryMode } from '../utils/constants';
import * as Sentry from "@sentry/vue";

export const useMappingStore = defineStore('mapping', {
    state: () => ({
        mappingPlots: [],
        allPlotsGeojson: {
            "type": "FeatureCollection",
            "name": "mappingSources",
            "features": []
        },
        mousePosition: {},
        lastDrawnFeature: {},
        searchMarker: null,
        isSearchResultCardShown: false,
        currentGeometryMode: GeometryMode.NONE,
        editingPlotObjectId: "",
        isPlacingMarkerCardShown: false,
        isDrawingPolygonCardShown: false,
        isEditPlotsCardShown: false,
        // Pagniation
        selectedPlotOnMap: {},
        selectedCheckboxes: [],
        isEveryCheckboxSelected: false,
        isEveryPageSelected: false,
        isTableLoading: false,
        isRiskDetectionFailureDialogShown: false,
        isRiskDetectionSuccessDialogShown: false
    }),
    actions: {
        showVRiskDetectionFailureDialog() {
            this.isRiskDetectionFailureDialogShown = true;
        },
        showRiskDetectionSuccessDialog() {
            this.isRiskDetectionSuccessDialogShown = true;
        },
    }
})
